import { createSlice } from "@reduxjs/toolkit";

const saveStopEventsSlice = createSlice({
  name: "saveStopEventsService",
  initialState: {
    saved: null,
    savingLoading: false,
    errorMessage: undefined
  },
  reducers: {
    saveStopEvents: (state) => {
      state.savingLoading = true;
      state.errorMessage = null;
      state.saved = null;
    },
    saveStopEventsSuccess: (state, action) => {
      state.savingLoading = false;
      state.saved = action.payload;
    },
    saveStopEventsFailure: (state, action) => {
      state.savingLoading = false;
      state.errorMessage = action.payload
    },
    resetSaveStops: (state) => {
      state.savingLoading = false;
      state.errorMessage = null;
      state.saved = null
    }
  }
});

export const { saveStopEvents, saveStopEventsSuccess, saveStopEventsFailure, resetSaveStops } = saveStopEventsSlice.actions;

export default saveStopEventsSlice.reducer;
