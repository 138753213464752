import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import CustomTextField from '../common/CustomTextField';
import { isJsonParsable } from '../../helpers/index';

const TextFieldWrapperComponent = (props) => {

    const { t } = useTranslation();

    const { type, title, filterKey, fullWidth } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState(() => Object.fromEntries([...searchParams]));
    const [values, setValues] = React.useState("");

    useEffect(() => {
        setSearch(Object.fromEntries([...searchParams]))
    }, [searchParams])

    useEffect(() => {
        const tempValue = isJsonParsable(search[filterKey])?.length === 0 ? "": search[filterKey] 
        setValues(tempValue);
    },[search[filterKey]]);
    
    const handleGetData = (value) => {
        setValues(value);
        setSearchParams(qs.stringify({
            ...search,
            [filterKey]: value
          }))
    }

    return (
        <CustomTextField
            id={`${title}_${type}`}
            placeholder={`${t('enter')} ${t(title)}`}
            onSubmit={handleGetData}
            dataTestId={`${title}_${type}`}
            fullWidth={fullWidth ? true : fullWidth}
            value={values}
        />
    )
}

export default TextFieldWrapperComponent