import moment from "moment-timezone";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { restrictedReasonCodes } from "../../helpers";
import CustomCalendar from "./CustomCalendar";
import CustomDropdown from "./CustomDropdown";
import CustomSnackbar from "./CustomSnackbar";

export default function CustomTextEdit({ elementKey, eventdata, changeEvent, event, type, eventName, setErrors, errorKey, error, errors, arrivalLocked, departureLocked, ...props }) {

    const { t } = useTranslation();
    const [value, setValue] = useState(props.value);
    const [open, setOpen] = useState(false);
    const formattedErrorObject = useSelector(({ edit: { saved } }) => saved?.formattedErrorObject?.[event?.stopNum]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const updateValues = ({ target: { value } }) => {
        setValue(value);
        const combinations = {
            apptDateTime: "apptReasonCode",
            apptReasonCode: "apptDateTime",
            actualArrivalTimestampCarrier: "actualArrivalReasonCodeCarrier",
            actualArrivalReasonCodeCarrier: "actualArrivalTimestampCarrier",
            actualDepartureReasonCodeCarrier: "actualDepartureTimestampCarrier",
            actualDepartureTimestampCarrier: "actualDepartureReasonCodeCarrier"
        }
        if (combinations[elementKey]) {
            const stopUpdatedData = eventdata?.[event?.stopNum] || {};
            changeEvent({
                [elementKey]: value,
                [combinations[elementKey]]: stopUpdatedData?.[combinations[elementKey]] || event[combinations[elementKey]]
            });
            // if (value && !(stopUpdatedData?.[combinations[elementKey]] || event[combinations[elementKey]])) {
            //     setOpen(true);
            //     setErrors({
            //         ...errors,
            //         [combinations[elementKey]]: true
            //     }); 
            // }
            if (stopUpdatedData?.[combinations[elementKey]] === undefined && restrictedReasonCodes.includes(event[combinations[elementKey]]) || restrictedReasonCodes.includes(stopUpdatedData?.[combinations[elementKey]])) {
                setOpen(true);
                setErrors({
                    ...errors,
                    [errorKey]: true
                });
            } else {
                setErrors({
                    ...errors,
                    [errorKey]: false
                });
            }
        } else {
            changeEvent({
                [elementKey]: value
            })
        }
    }

    const changeValue = (value) => {
        updateValues({
            target: {
                value: value
            }
        });
    }

    const changeDate = ({ date, time, timezone }) => {
        // const tz3LetterCode = moment(new Date()).tz(timezone)?.format("z");
        const formattedDate = moment(date).format(`YYYY-MM-DD [${time}]:ss`);
        const datetime = moment.tz(formattedDate, 'YYYY-MM-DD HH:mm:ss', timezone);
        updateValues({
            target: {
                value: datetime
            }
        });
    }

    return (
        <div data-testid="custom_test_edit">
            {
                type === "date" ?
                    <CustomCalendar
                        {...props}
                        changeEvent={changeDate}
                        elementKey={elementKey}
                        date={value}
                        forceError={formattedErrorObject?.[elementKey]}
                        timezone={event?.location?.timezone}
                        arrivalLocked={arrivalLocked}
                        departureLocked={departureLocked}
                    /> :
                    <CustomDropdown
                        {...props}
                        changeEvent={changeValue}
                        elementKey={elementKey}
                        forceError={formattedErrorObject?.[elementKey]}
                        value={value}
                        eventName={eventName}
                        error={error}
                    />
            }
            <CustomSnackbar
                open={open}
                severity="error"
                // autoHideDuration={3000}
                datatestId="invalid-update"
                message={`${t('please_update')} - ${t(errorKey)}`}
                handleClose={() => setOpen(false)}
            />
        </div>
    );
}
