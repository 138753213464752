import { CSVLABEL } from "../constData/csvColumns";
import { CSVLABEL_ON_TIME } from "../constData/csvColumnsOnTime";
import moment from "moment-timezone";
import { defaultFilterPayloadTNT, optionalFilterPayloadTNT } from "../constData/filterRequestPayload";
import { defaultFilterPayloadOTP, optionalFilterPayloadOTP } from "../constData/filterRequestPayloadOnTimePickup";
import { defaultFilterPayloadOTD, optionalFilterPayloadOTD } from "../constData/filterRequestPayloadOnTimeDelivery";
import { defaultFilterPayloadFTC, optionalFilterPayloadFTC } from "../constData/filterRequestPyaloadFirstTender";
import { CSVLABEL_FIRST_TENDER } from "../constData/csvColumnsFirstTender";
import { defaultFilterPayloadLAC } from "../constData/filterRequestPayloadLAC";
import { CSVLABEL_LANE_AWARD } from "../constData/csvColumnLaneAward";
import { CSVLABEL_TRAILER_POOL } from "../constData/csvColumnTrailerPool";
import { defaultFilterPayloadTPC } from "../constData/filterRequestPayloadTPC";

const pageSize = 30;
const commentLimit = 500;
const commentsFeature = "CP_TNT_LOAD_COMMENTS";
const dateFormat = "DD-MM-yyyy";
const dateFormat1 = "yyyy-MM-DD";
const datetimeFormats = {
    startTime: "YYYY-MM-DD [00:00:00.000]",
    endTime: "YYYY-MM-DD [23:59:59.999]"
}
const globalFiltersRequired = [
    "deliveryActualArrivalDatetimeFinalDestTZ",
    "isPickedByPrimaryList",
    "carrierid",
    "tariffServiceCodeList",
    "originCityStateList",
    "tenderedToPrimaryList"
]
const globalFilters = {
    "originCityStateList": "[]",
    "tariffServiceCodeList": "[]",
    "carrierid": "[]",
    "tenderedToPrimaryList": "[]",
    "deliveryActualArrivalDatetimeFinalDestTZ": JSON.stringify({
        startTime: moment().startOf("month").format(dateFormat),
        endTime: moment().format(dateFormat)
    })
};
const renderRowComponentRestriction = [
    "firstTenderCompliance",
    "laneAwardCompliance",
    "trailerPoolCompliance"
]
const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


const absolutePaths = {
    onTimePickup: "/dashboard/performance/:carrierid/onTimePickup",
    default: "/dashboard/performance/:carrierid",
    onTimeDelivery: "/dashboard/performance/:carrierid/onTimeDelivery",
    firstTenderCompliance: "/dashboard/performance/:carrierid/firstTenderCompliance",
    laneAwardCompliance: "/dashboard/performance/:carrierid/laneAwardCompliance",
    trailerPoolCompliance: "/dashboard/performance/:carrierid/trailerPoolCompliance"
}

const restrictedFilterChips = [
    'region',   // when we add the option of multiple regions we need to update this condition
    'searchType',
    'returnOnlyTmsPlannedShipments'
];
const carrierRestrictedFilters = [
    "shipmentNumList",
    "carrierCodeList",
    "carrierDescList",
    "sentToSAP",
    "tmsInSync"
];
const carrierRoles = [
    "ct_carrier_portal_ebam_write_dev",
    "ct_carrier_portal_ebam_write_qa",
    "ct_carrier_portal_ebam_write_prod"
];
const restrictedReasonCodes = [
    "APC_",
    "APH_",
    "AANA",
    "ABNS",
    "ABNA"
];
const lanes = [
    "all_lanes",
    "awarded_lanes",
    "non_awarded_lanes"
];

const restrictedColumnsPerformance = [
    'pending_task',
    'time_left'
];
const eventType = {
    carrier_actual_delivery_reason_code: "Actual Arrive Dest",
    carrier_actual_pickup_reason_code: "Actual Arrive Origin",
    carrier_actual_delivery_depart_reason_code: "Actual Depart Dest",
    carrier_actual_pickup_depart_reason_code: "Actual Depart Origin",
    planned_appt_reason_code: "Planned Arrive Dest",
    pickup_appt_reason_code: "Planned Arrive Origin"
};
const csvColumnMapping = {
    trackNtrace: CSVLABEL,
    onTimePickup: CSVLABEL_ON_TIME,
    onTimeDelivery: CSVLABEL_ON_TIME,
    firstTenderCompliance: CSVLABEL_FIRST_TENDER,
    laneAwardCompliance: CSVLABEL_LANE_AWARD,
    trailerPoolCompliance: CSVLABEL_TRAILER_POOL
}
const responseConstList = ['CUST', 'STO', 'VENDOR_INBOUND'];

const restrictedPages = [
    "carrierEventResponse",
    "fourKitesTracking",
    "billingAccuracy"
]

const stringToArray = (input, filterKey) => {
    let makeArray = [];
    if (input) {
        if (['shipmentNumList', 'carrierCodeList', 'equipmentTypeList', 'tariffServiceCodeList', 'trailerNumList', 'originStateList', 'destStateList'].includes(filterKey)) {
            makeArray = input.split(/,| /).filter(el => el !== '').map(item => item.trim())
        } else {
            makeArray = input.split(',').filter(el => el !== '').map(item => item.trim())
        }
    }
    return makeArray;
}

const getEnumValue = (value, enumObj, filterLabel) => {
    if (filterLabel === "tm_load_status" && value === "In Transit") {
        return "In Transit";
    }
    return enumObj[value];
}

const toHoursAndMinutes = (totalMinutes, onlyHours = false) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (onlyHours) {
        return totalMinutes ? `${hours}` : '0';
    } else {
        return totalMinutes ? `${hours}hrs${minutes > 0 ? ` ${minutes}mins` : ' 00mins'}` : '00hrs 00mins';
    }
}

const roleSpecificColumns = (columns, role) => {
    if (carrierRoles.includes(role)) {
        return columns.filter(item => item.Header !== "sent_to_SAP" && item.Header !== "tms_in_sync")
    }
    return columns;
}

const rejectNoResponseRestricted = [
    'service',
    'trailer_number',
    'live_load',
    'pickup_appt_date_time',
    'delivery_appt_date_time',
    'carrier_id',
    'carrier_desc',
    'service_desc'
]

const isJsonParsable = string => {
    let parsed = null;
    try {
        parsed = JSON.parse(string);
    } catch (e) {
        return false;
    }
    return parsed;
}

const generateGlobalFilters = (filterKeysArray, filters) => {
    let values = {};
    filterKeysArray.map(d => {
        values[d] = isJsonParsable(filters[d]) || filters[d]
        if (d === "deliveryActualArrivalDatetimeFinalDestTZ") {
            values[d] = {
                startTime: moment(values[d]?.startTime, dateFormat).format(datetimeFormats.startTime),
                endTime: moment(values[d]?.endTime, dateFormat).format(datetimeFormats.endTime),
            }
        }
    })
    return values;
}

const generateTableFilters = (defaultFilterPayload, search, filterBody, tableName) => {
    //Search  = Query params URL Values
    //defaultpayload : paylod we have defined 
    //filterbody = Schema of filters
    let values = {};
    let definedValues = {};
    Object.keys(defaultFilterPayload).map(d => {
        values[d] = isJsonParsable(search[d]) || search[d];
        if (filterBody[d]?.stringToArray && search[d] !== "[]") {
            values[d] = stringToArray(search[d], d)
        }
        if (filterBody[d]?.type === "date" && values[d]?.startTime && values[d]?.endTime) {
            values[d] = {
                startTime: values[d].startTime,
                endTime: values[d].endTime
            }
        }
        if (filterBody[d]?.type === "date" && !values[d]) {
            values[d] = null;
        }
        if (tableName === "trackNtrace" && d === "tmsShipmentStatus") {
            if (values[d]?.length === 0) {
                values[d] = [
                    "Tender Accepted",
                    "Confirming",
                    "In Transit",
                    "Completed"
                ]
            }
        }
        if (tableName === 'firstTenderCompliance' && d === 'tenderStatusList') {
            if (values[d]?.length === 0) {
                values[d] = [
                    "TENDER_ACCEPTED",
                    "TENDER_REJECTED",
                    "TENDER_NO_RESPONSE"
                ]
            }
        }
        if (values[d]) {
            definedValues[d] = values[d];
        }
    })
    return { tableFilters: values, definedValues };
}

const optionalFiltersByTable = {
    trackNtrace: optionalFilterPayloadTNT,
    onTimePickup: optionalFilterPayloadOTP,
    onTimeDelivery: optionalFilterPayloadOTD,
    firstTenderCompliance: optionalFilterPayloadFTC
}

const defaultHeaderFiltersByTable = {
    trackNtrace: defaultFilterPayloadTNT,
    onTimePickup: defaultFilterPayloadOTP,
    onTimeDelivery: defaultFilterPayloadOTD,
    firstTenderCompliance: defaultFilterPayloadFTC,
    laneAwardCompliance: defaultFilterPayloadLAC,
    trailerPoolCompliance: defaultFilterPayloadTPC
}

const isSubset = (parentArray, subsetArray) => {
    return subsetArray.every((el) => {
        return parentArray.includes(el)
    })
}

const getFloatValue = (divident, divisor) => {
    let value = parseFloat((divident / divisor) * 100).toFixed(1);
    return value;
}

export {
    pageSize,
    carrierRestrictedFilters,
    dateFormat,
    globalFiltersRequired,
    datetimeFormats,
    carrierRoles,
    absolutePaths,
    restrictedReasonCodes,
    eventType,
    lanes,
    globalFilters,
    restrictedColumnsPerformance,
    commentLimit,
    commentsFeature,
    browserTimezone,
    restrictedFilterChips,
    csvColumnMapping,
    responseConstList,
    stringToArray,
    getEnumValue,
    toHoursAndMinutes,
    roleSpecificColumns,
    isJsonParsable,
    generateGlobalFilters,
    generateTableFilters,
    optionalFiltersByTable,
    defaultHeaderFiltersByTable,
    isSubset,
    rejectNoResponseRestricted,
    renderRowComponentRestriction,
    restrictedPages,
    dateFormat1,
    getFloatValue
}