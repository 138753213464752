import { put, takeLatest, call } from 'redux-saga/effects';
import { setAddCommentSuccess, setAddCommentFailure } from '../reducers/addCommentSlice';
import { addComment } from '../../service/addComment';

/**
 * worker saga upon being called by the watcher saga makes the appropriate API call
 * and set the response data for both success and failuer calls.
 */
export function* workerAddCommentSaga({ payload }) {
    try {
        const response = yield call(addComment, [payload]);
        yield put(setAddCommentSuccess(response));
    } catch (error) {
        yield put(setAddCommentFailure(error.toString()))
    }
}

/**
* watcher saga - looks for the respective action dispatch. 
* First argument in the take/takeLatest/takeEvery is the action type and 
* the second argument is the worker saga function.
*/
function* addCommentSaga() {
    yield takeLatest("addNewComment/addComment", workerAddCommentSaga);
}

export default addCommentSaga;