import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import qs from "query-string";
import IconButton from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import moreIcon from "../../assets/moreIcon.svg";
import './Card.sass';

const CustomCardHeader = (props) => {

    const  { title , pageName } = props;
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='titleBox'>
            <Typography variant='h2' className='titleStyle'>{title}</Typography>
            <IconButton
                disableRipple
                onClick={() => {
                    let queryParams = qs.parse(location?.search);
                    navigate({
                        ...location,
                        search: qs.stringify({
                            ...queryParams,
                            tenderedToPrimaryList: JSON.stringify([])
                        }),
                        pathname: pageName
                    })
                }}
                data-testid={`${pageName}-button`}
                className='moreButton'
            >
                <img src={moreIcon} alt="moreIcon" />
            </IconButton>
        </div>
    )
}

export default CustomCardHeader