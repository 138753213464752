import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import './CustomTextField.sass'
import { useEffect } from 'react'

const CustomTextField = (props) => {
    const {
        id,
        label,
        variant,
        helperText,
        size,
        margin,
        fullWidth,
        onSubmit,
        placeholder,
        dataTestId,
        infoText,
        value
    } = props;

    const { t } = useTranslation();

    const [inputVal, setInputVal] = useState(() => value);

    useEffect(() => {
        setInputVal(value);
    }, [value])

    const handleChange = (e) => {
        setInputVal(e.target.value);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(inputVal);
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <TextField
                    id={id}
                    label={label}
                    variant={variant}
                    helperText={helperText}
                    size={size}
                    margin={margin}
                    fullWidth={fullWidth}
                    value={inputVal}
                    onChange={handleChange}
                    className="textfield-styles"
                    placeholder={placeholder}
                    data-testid={dataTestId ? dataTestId : 'custom-textfield'}
                />
            </form>
            {infoText && <Typography variant='body2' className='info-text'>{t('info_text')}</Typography>}
        </>

    )
}

CustomTextField.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    variant: PropTypes.oneOf([
        'filled',
        'outlined',
        'standard'
    ]),
    helperText: PropTypes.string,
    size: PropTypes.string,
    margin: PropTypes.oneOf([
        'dense',
        'none',
        'normal'
    ]),
    fullWidth: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    infoText: PropTypes.bool
}

CustomTextField.defaultProps = {
    id: 'textfield',
    variant: 'outlined',
    size: 'small',
    margin: 'dense',
    fullWidth: true,
    infoText: true
}


export default CustomTextField