import React, { useEffect, useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LinearProgress from '@mui/material/LinearProgress';
import CustomSnackbar from './common/CustomSnackbar';
import KCLogo from '../assets/KCLogo.svg';
import ProfileMenu from "../components/ProfileMenu";
import FailureDialog from "./FailureDialog";
import { getEnumValue } from "../helpers";
import { errorFieldDrop, errorFieldPickup } from "../constData/editErrorFieldsMapping";
import './Header.sass';

export default function Header({ authState, oktaAuth, user, userID }) {

  const { t } = useTranslation();
  const location = useLocation();
  const [tabIndex, setTabIndex] = React.useState(0);
  const { isLoading } = useSelector(state => state?.loadDetails);
  const { saved, errorMessage, savingLoading } = useSelector(({ edit: { saved, errorMessage, savingLoading } }) => ({ saved, errorMessage, savingLoading }));
  const { retriggered, errMsg, retriggerLoading } = useSelector(({ retrigger: { retriggered, errMsg, retriggerLoading } }) => ({ retriggered, errMsg, retriggerLoading }));
  const { commentsLoading, commentsErrMsg } = useSelector(state => state?.userComments);
  const { addCommentLoading } = useSelector(state => state.addNewComment);
  const { isLoadingCSV } = useSelector(state => state?.csvDetails);
  const [loading, setLoading] = useState(false);
  const { isCarrierServiceMetadataLoading } = useSelector(state => state.carrierMetadata);
  const { isLocMetadataLoading } = useSelector(state => state.locationMetadata);
  const [snack, setSnack] = useState({
    open: false,
    message: null,
    severity: 'success'
  });
  const [openDialog, setOpenDialog] = useState({
    open: false,
    message: [],
    loadId: null,
    failed: false
  });

  useEffect(() => {
    setLoading(isLoading || savingLoading || retriggerLoading || commentsLoading || addCommentLoading);
  }, [isLoading, savingLoading, retriggerLoading, commentsLoading, addCommentLoading]);

  useEffect(() => {
    if (saved?.completelySucessful || errorMessage) {
      setSnack({
        message: saved?.loadId ? `${t('load_id')} ${saved?.loadId} ${t('success')}` : errorMessage,
        severity: saved?.loadId ? 'success' : 'error',
        open: true
      });
    } else {
      let y = [];
      if (saved?.completelySucessful === false) {
        let x = saved?.failedFields?.forEach(item => {
          let field = item.stopType === 'pickup' ? getEnumValue(item.failedField, errorFieldPickup) : getEnumValue(item.failedField, errorFieldDrop)
          y.push(`${t(item.stopType)} ${item.stopType === 'pickup' ? item.stopNum : item.stopNum - 1} ${t('failed')} ${t(field)} ${t('due_to')} ${item.failureReason}`)
        }, [])
        setOpenDialog({
          open: true,
          message: y,
          loadId: saved?.loadId,
          failed: saved?.completelyFailed ? true : false
        })
      }
    }
  }, [saved?.completelySucessful, errorMessage]);

  useEffect(() => {
    if (retriggered?.completelySucessful || errMsg) { // snack for 200 complete suceess and failure (400, 404, 500 etc)
      setSnack({
        message: retriggered?.loadId ? `${t('load_id')} ${retriggered?.loadId} ${t(retriggered?.message)}` : errMsg,
        severity: retriggered?.loadId ? 'success' : 'error',
        open: true
      });
    } else if(retriggered?.status === 'good') { // snack for 204
      setSnack({
        message: `${t('load_id')} ${retriggered?.loadId} ${t(retriggered?.message)}`,
        severity: 'success',
        open: true
      });
    } else { // Dialog 409
      let y = [];
      if (retriggered?.completelySucessful === false) {
        let x = retriggered?.failedFields?.forEach(item => {
          let field = item.stopType === 'pickup' ? getEnumValue(item.failedField, errorFieldPickup) : getEnumValue(item.failedField, errorFieldDrop)
          y.push(`${t(item.stopType)} ${item.stopType === 'pickup' ? item.stopNum : item.stopNum - 1} ${t('failed')} ${t(field)} ${t('due_to')} ${item.failureReason}`)
        }, [])
        setOpenDialog({
          open: true,
          message: y,
          loadId: retriggered?.loadId,
          failed: retriggered?.completelyFailed ? true : false
        })
      }
    }
  }, [retriggered?.loadId, retriggered?.status, errMsg]);

  useEffect(() => {
    if(commentsErrMsg && commentsErrMsg !== 'success') {
      setSnack({
        message: t('search_comments_failure'),
        severity: 'error',
        open: true
      })
    }
  }, [commentsErrMsg]);

  useEffect(() => {
    setLoading(isLoading || savingLoading || isLoadingCSV || isCarrierServiceMetadataLoading || isLocMetadataLoading);
  }, [isLoading, savingLoading, isLoadingCSV, isCarrierServiceMetadataLoading, isLocMetadataLoading]);

  useEffect(() => {
    location?.pathname.includes("tracking") ? setTabIndex(0) : setTabIndex(1);
    // let x = oktaAuth?.setOriginalUri(`${location?.pathname}${location?.search}`);
  }, [location?.pathname])

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <AppBar data-testid="header">
        <Toolbar className="toolbar">
          <img src={KCLogo} alt="Logo" className="logo" width={65} />
          <Typography variant="h1" className="appTitle"> Carrier Web Portal </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Tabs id="tabs" value={tabIndex} onChange={handleTabChange} aria-label="tabs">
              <Tab
                label={<Typography variant={tabIndex === 0 ? "h2" : "h3"} className="tab">{t("track_n_trace")}</Typography>}
                id={0}
                data-testid="tabzero"
                to="/dashboard/tracking"
                component={Link}
              />
              <Tab
                label={<Typography variant={tabIndex === 1 ? "h2" : "h3"} className="tab">{t("carrier_performance")}</Typography>}
                id={1}
                data-testid="tabone"
                to={'/dashboard/performance'}
                component={Link}
              />
            </Tabs>
          </Box>
          {authState?.idToken &&
            <div className="appbarItems">
              <ProfileMenu oktaAuth={oktaAuth} user={user} userID={userID} />
            </div>
          }
        </Toolbar>
        {loading && <LinearProgress className="progress" />}
        <CustomSnackbar
          // autoHideDuration={3000}
          {...snack}
          handleClose={() => setSnack({
            ...snack,
            open: false
          })}
        />
      </AppBar>
      <FailureDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </>
  );

}