import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import tableDataReducer from './reducers/tableDataSlice';
import filterReducer from './reducers/filterSlice';
import csvDataReducer from './reducers/csvDataSlice';
import saveStopEventsReducer from './reducers/saveStopEventsSlice';
import tmsMetaDataReducer from './reducers/tmsMetaDataSlice';
import userInfoReducer from './reducers/userInfoSlice';
import retriggerEventReducer from './reducers/retriggerEventSlice';
import commentsReducer from './reducers/commentsSlice';
import addCommentReducer from './reducers/addCommentSlice';
import locationMetadataReducer from './reducers/locationMetadataSlice';
import carrierServiceMetadataReducer from './reducers/carrierServiceMetadataSlice';
import summaryReducer from './reducers/summarySlice';
import carrierInfoReducer from './reducers/carrierInfoSlice';

import rootSaga from './sagas/rootSaga';

const saga = createSagaMiddleware();

const store = configureStore({
    reducer: {
        loadDetails: tableDataReducer,
        filterSelected: filterReducer,
        csvDetails: csvDataReducer,
        tmsMetaData: tmsMetaDataReducer,
        edit: saveStopEventsReducer,
        user: userInfoReducer,
        retrigger: retriggerEventReducer,
        userComments: commentsReducer,
        addNewComment: addCommentReducer,
        locationMetadata: locationMetadataReducer,
        carrierMetadata: carrierServiceMetadataReducer,
        chartSummary: summaryReducer,
        carrier: carrierInfoReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(saga)
})

saga.run(rootSaga);

export default store;