import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { dateFormat, isJsonParsable } from '../helpers';
import moment from 'moment-timezone';

export const useCustomFetchDateParams = () => {
    const [searchParams] = useSearchParams();
    const [dates, setDates] = useState(() => {
        const urlDates = isJsonParsable(searchParams.get("deliveryActualArrivalDatetimeFinalDestTZ"));
        return {
            startTime: moment(urlDates?.startTime, dateFormat)?.format('DD-MMM-YYYY'),
            endTime: moment(urlDates?.endTime, dateFormat)?.format('DD-MMM-YYYY')
        }
    });

    useEffect(() => {
        const urlDates = isJsonParsable(searchParams.get("deliveryActualArrivalDatetimeFinalDestTZ"));
        setDates({
            startTime: moment(urlDates?.startTime, dateFormat)?.format('DD-MMM-YYYY'),
            endTime: moment(urlDates?.endTime, dateFormat)?.format('DD-MMM-YYYY')
        })
    }, [searchParams.get("deliveryActualArrivalDatetimeFinalDestTZ")]);

    return dates;
}