import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
// import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Fade from "@mui/material/Fade";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import CustomButton from "./CustomButton";
import './CustomCalendar.sass';

export default function CustomCalendar({ elementKey, changeEvent, timezone, type, arrivalLocked, departureLocked, forceError, ...props }) {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    // const [dateformat, setDateFormat] = useState(() => moment(props.date).tz(timezone));
    const [value, setValue] = useState(() => moment(props.date).tz(timezone));
    const [time, setTime] = useState(value);

    useEffect(() => {
        var formatteddate = moment(props.date).tz(timezone);
        // setDateFormat(formatteddate);
        setTime(formatteddate?.format("HH:mm"));
        setValue(new Date(formatteddate?.format("DD MMMM YYYY")))
    }, [props.date])

    const handleClick = (event) => {
        setOpen(!open);
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Button
                onClick={handleClick}
                data-testid="open_calendar"
                size={"small"}
                className={forceError
                    ? "popper-open-button-red-cal"
                    : open
                        ? "popper-open-button-indigo-cal"
                        : "popper-open-button-white-cal"
                }
                variant="outlined"
                endIcon={<CalendarMonthIcon />}
            >
                {(props.date === "-" || !props.date) ? t("select_date_time") :
                    moment(props.date).tz(timezone)?.format("MMM DD YYYY HH:mm z")
                }
            </Button>
            {open && <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={"right-start"}
                    transition
                    sx={{
                        zIndex: 1000
                    }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper className={"popper-container"}>
                                <Card
                                    className={"popper-body"}
                                    component="nav"
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <StaticDatePicker
                                            displayStaticWrapperAs="desktop"
                                            value={value}
                                            onChange={(newValue) => {
                                                setValue(newValue);
                                            }}
                                            // renderInput={(params) => (
                                            //     <TextField sx={{ width: "100%" }} {...params}  data-testid="date-text"/>
                                            // )}
                                            maxDate={elementKey === 'actualArrivalTimestampCarrier' || elementKey === 'actualDepartureTimestampCarrier' ? new Date() : null}
                                        />
                                    </LocalizationProvider>
                                    <Paper
                                        component="form"
                                    >
                                        <div className="calender-styles">
                                            <OutlinedInput
                                                size="small"
                                                inputProps={{ 'data-testid': `${elementKey}-time` }}
                                                endAdornment={
                                                    <InputAdornment position="end">{moment(moment.isDate(props.date) ? value : new Date())?.tz(timezone)?.format("z")}</InputAdornment>
                                                }
                                                onChange={(e) => {
                                                    setTime(e.target.value)
                                                }}
                                                value={time}
                                                type="time"
                                                className="calendar-input"
                                            />
                                            <CustomButton
                                                size={"small"}
                                                datatestid="save-button-cal"
                                                className={"popper-save-button"}
                                                btnType={"secondary"}
                                                onClick={() => {
                                                    changeEvent({
                                                        date: value?.$d || value,
                                                        time,
                                                        elementKey,
                                                        timezone
                                                    });
                                                    setOpen(!open);
                                                }}
                                            >
                                                {t("save")}
                                            </CustomButton>
                                        </div>
                                        {(elementKey === 'actualArrivalTimestampKC' || elementKey === 'actualDepartureTimestampKC') && !arrivalLocked && !departureLocked && props.date !== undefined && <div className="delete-button">
                                            <CustomButton
                                                datatestid="delete-button"
                                                btnType="quaternary"
                                                onClick={() => {
                                                    changeEvent({
                                                        date: undefined,
                                                        time: undefined,
                                                        elementKey,
                                                        timezone
                                                    });
                                                    setValue(undefined)
                                                    setOpen(!open);
                                                }}
                                            >
                                                {t("delete")}
                                            </CustomButton>
                                        </div>}
                                    </Paper>
                                </Card>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </ClickAwayListener>}

        </>
    );
}
