import axios from '../helpers/axioxInstance';
import URLS from '../constData/urls';

/**
 * API handler gets called by a worker saga
 */
export const fetchComments = async ([reqBody]) => {
    const response = await axios.post(`${URLS.FETCH_COMMENTS }`, reqBody);
    if(response.status >= 400) {
        throw new Error(response.message);
    }
    return {
        ...response.data,
        shipmentNum: reqBody.shipmentNum
    }
}