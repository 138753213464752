export const filterRequestPayload = {
    "searchType": "CARRIER_PORTAL_LOAD_DETAILS",
    "region": "NA",
    "excludeEquipmentTypeList": ["PKG"], // send always
    "returnOnlyTmsPlannedShipments" : true, // send always
    "shipmentNumList": [], // Load # search 
    "carrierDescList": [], // Carrier Desc
    // "carrierIdList": [],         // Carrier ID
    "carrierCodeList": [],         // Carrier Code
    "tariffServiceCodeList": [],        // Service 
    "equipmentTypeList": [],       // Equip Type 
    "trailerNumList": [],   // Trailer Number
    "tmsShipmentStatus": [
        "Tender Accepted", 
        "Confirming", 
        "In Transit",
        "Completed" 
    ], // TM Load Status
    "orderExecutionBucket": [
        // "TRANS_PLAN_TENDER_ACCEPTED",  // Transportation Carrier committed
        // "SHIPMENT_CREATED", // Shipment created
        // "CHECK_IN",  // Checked in by DC
        // "LOADING_STARTED",  // Loading started
        // "TRANS_EXEC_READY_PICK_UP",  // Ready for Pickup
        // "TRANS_EXEC_IN_TRANSIT",  // In transit
        // "TRANS_EXEC_DELIVERY_CONFIRMED"  // Delivery confirmed
    ],

    "pickupApptDateTimeOriginTZ": null,
    "pickupOnTime": [],
    "deliveryApptDateTimeDestTZ": null,
    "deliveryOnTime": [],
    "originLocationNameList": [], // Origin Location name
    "originCityList": [], // Origin city 
    "originStateList": [], // Origin State
    "originCountryList": [], // Origin Country
    "destLocationNameList": [], // Dest Location name
    "destCityList": [], // Dest city 
    "destStateList": [], // Dest State
    "destCountryList": [], // Dest Country
    "carrierUpdateLifecycleHealthList": [],
    "shipmentAttributes": [],  // Pending Task
    "sentToSAP" : [],  // Sent To SAP
    "tmsInSync" : [],  // TMS in Sync
    "liveLoadIndList": []  // Live Load
}

export const optionalFilterPayloadTNT = {
    "shipmentNumList": [], // Load # search 
    "carrierDescList": [], // Carrier Desc
    // "carrierIdList": [],         // Carrier ID
    "carrierCodeList": [],         // Carrier Code
    "tariffServiceCodeList": [],        // Service 
    "equipmentTypeList": [],       // Equip Type 
    "trailerNumList": [],   // Trailer Number
    "tmsShipmentStatus": [
        // "Tender Accepted", 
        // "Confirming", 
        // "In Transit",
        // "Completed" 
    ], // TM Load Status
    "orderExecutionBucket": [
        // "TRANS_PLAN_TENDER_ACCEPTED",  // Transportation Carrier committed
        // "SHIPMENT_CREATED", // Shipment created
        // "CHECK_IN",  // Checked in by DC
        // "LOADING_STARTED",  // Loading started
        // "TRANS_EXEC_READY_PICK_UP",  // Ready for Pickup
        // "TRANS_EXEC_IN_TRANSIT",  // In transit
        // "TRANS_EXEC_DELIVERY_CONFIRMED"  // Delivery confirmed
    ],

    "pickupApptDateTimeOriginTZ": null,
    "pickupOnTime": [],
    "deliveryApptDateTimeDestTZ": null,
    "deliveryOnTime": [],
    "originLocationNameList": [], // Origin Location name
    "originCityList": [], // Origin city 
    "originStateList": [], // Origin State
    "originCountryList": [], // Origin Country
    "destLocationNameList": [], // Dest Location name
    "destCityList": [], // Dest city 
    "destStateList": [], // Dest State
    "destCountryList": [], // Dest Country
    "carrierUpdateLifecycleHealthList": [],
    "shipmentAttributes": [],  // Pending Task
    "sentToSAP" : [],  // Sent To SAP
    "tmsInSync" : [],  // TMS in Sync
    "liveLoadIndList": []  // Live Load
}

export const defaultFilterPayloadTNT = {
    "searchType": "CARRIER_PORTAL_LOAD_DETAILS",
    "region": "NA",
    "excludeEquipmentTypeList": ["PKG"], // send always
    "returnOnlyTmsPlannedShipments" : true, // send always
}