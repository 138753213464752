import * as React from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import qs from "query-string";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { globalFiltersRequired, restrictedPages } from "../helpers";
import { palette } from '../theme/ColorPalette';

const LOADING_COLOR = palette.lightSpaceGey

const CustomSlider = styled(Slider)({
  height: 12,
  cursor: 'pointer',
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    display: 'none'
  },
});

const colors = {
  primary: palette.skyBlue,
  secondary: palette.peachPink,
  tertiary: palette.grey85
}

export default function ProgressChart({
  value1 = 0,
  value2 = 0,
  value1Title = null,
  value2Title = null,
  noRail = false,
  railColor = colors.secondary,
  loading = true,
  gotoPath,
  ...rest
}) {
  const { carrierid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  let color = colors[rest.color];
  let value = (value1 / (value1 + value2)) * 100;
  value = isNaN(value) ? 0: value;

  if (value === 0 && value2 > 0) {
    value = 100;
    color = colors.secondary;
  }

  const goToPage = (e) => {
      const isTrack = e.target.className?.includes('track');

      const queries = qs.parse(location.search);
      let filtersRequired = {};
      globalFiltersRequired.map(d => {
          filtersRequired[d] = queries[d];
      });
      if(gotoPath === 'laneAwardCompliance') {
        navigate({
          ...location,
          search: qs.stringify({
              ...filtersRequired,
              awardCompliant: 'N',
              carrierCodeList: JSON.stringify([carrierid]),
          }),
          pathname: `/dashboard/performance/${carrierid}/${gotoPath}`,
      }, { replace: true })
      } else if(gotoPath === 'trailerPoolCompliance') {
        navigate({
          ...location,
          search: qs.stringify({
              ...filtersRequired,
              trailerPoolCompliant: 'N',
              carrierCodeList: JSON.stringify([carrierid]),
          }),
          pathname: `/dashboard/performance/${carrierid}/${gotoPath}`,
      }, { replace: true })
      } else {
        const ontimeKey = gotoPath === "onTimePickup" ? "pickupOnTime": "deliveryOnTime";
      
        navigate({
            ...location,
            search: qs.stringify({
                ...filtersRequired,
                isPickedByPrimaryList: JSON.stringify([]),
                carrierCodeList: JSON.stringify([carrierid]),
                [ontimeKey]: JSON.stringify([isTrack ? "Y": "N"]),
            }),
            pathname: `/dashboard/performance/${carrierid}/${gotoPath}`,
        }, { replace: true })
      }      
  }
  
  return (<Box className='mb1'>
      <div className='subSubTitle'>
        <Typography variant='body1'>{loading ? "-": value1Title}</Typography>
        <Typography variant='body1'>{loading ? "-": value2Title}</Typography>
      </div>
      {loading || value ? <CustomSlider
          loading={loading}
          onClick={goToPage}
          disabled={restrictedPages.includes(gotoPath)}
          data-testid="progress-chart"
          sx={{
            color: loading ? LOADING_COLOR : color,
            '& .MuiSlider-rail': {
              opacity: 1,
              backgroundColor: loading ? LOADING_COLOR :
                noRail ? colors.tertiary : railColor,
            }
          }}
          value={value}
        />
        : null}
    </Box>
  );
}