import { put, takeLatest, call } from 'redux-saga/effects';
import moment from "moment-timezone";
import { setTableDataFailure, setTableDataSuccess } from '../reducers/tableDataSlice';
import { fetchTableData } from '../../service/fetchTableData';
import { ENUM_DATA } from '../../constData/enum';
import { getEnumValue, toHoursAndMinutes } from '../../helpers';

/**
 * worker saga upon being called by the watcher saga makes the appropriate API call
 * and set the response data for both success and failuer calls.
 */
export function* workerGetTableDataFetch({ payload }) {
    try {
        const response = yield call(fetchTableData, [payload.pageNum, payload.reqBody, payload.pageSize, payload.tableName, payload.controller]);
        const data = response.data;
        let parsedShipmentValues = [];
        if (payload.tableName === 'laneAwardCompliance') {
            let x = data?.laneAwardComplianceResponse?.length && data?.laneAwardComplianceResponse.forEach(item => {
                let startDate = item?.startDate ? moment(item.startDate).format('DD-MMM-YYYY') : '-';
                let endDate = item?.endDate ? moment(item.endDate).format('DD-MMM-YYYY') : '-';

                let obj = {
                    ...item,
                    week: `${startDate} - ${endDate}`,
                    lane: item?.lane ? item.lane : '-',
                    tariffServiceCode: item?.tariffServiceCode ? item.tariffServiceCode : '-',
                    awardedLoads: item?.awardedLoads || item?.awardedLoads === 0 ? item.awardedLoads : '-',
                    tenderedLoads: item?.tenderedLoads || item?.tenderedLoads === 0 ? item.tenderedLoads : '-',
                    tenderAcceptedInContract: item?.tenderAcceptedInContract || item?.tenderAcceptedInContract === 0 ? item.tenderAcceptedInContract : '-',
                    compliancePercentage: item?.compliancePercentage || item?.compliancePercentage === 0 ? item.compliancePercentage : '-',
                    tenderAcceptedOverCompliancePercentage: item.tenderAcceptedOverCompliancePercentage || item.tenderAcceptedOverCompliancePercentage === 0 ? item.tenderAcceptedOverCompliancePercentage : '-',
                    tenderAcceptedInSpot: item?.tenderAcceptedInSpot || item?.tenderAcceptedInSpot === 0 ? item.tenderAcceptedInSpot : '-',
                    complianceTargetPercentage: data?.complianceTargetPercentage ? data.complianceTargetPercentage : 0,

                }
                parsedShipmentValues.push(obj);
            })
        } else if (payload.tableName === 'trailerPoolCompliance') {
            let x = data?.trailerPoolComplianceResponse?.length && data?.trailerPoolComplianceResponse.forEach(item => {
                let obj = {
                    ...item,
                    date: item?.trailerPoolComplianceDate ? moment(item.trailerPoolComplianceDate).format('DD-MMM-YYYY') : '-',
                    tariffServiceCode: item?.tariffServiceCode ? item.tariffServiceCode : '-',
                    carrierCode: item?.carrierCode ? item.carrierCode : '-',
                    siteNum: item?.origin?.id ? item.origin.id : '-',
                    originLocation: item?.origin?.cityState ? item.origin.cityState : '-',
                    trailerPoolTarget: item?.trailerPoolTarget || item?.trailerPoolTarget === 0 ? item.trailerPoolTarget : '-',
                    trailerPoolActual: item?.trailerPoolActual || item?.trailerPoolActual === 0 ? item.trailerPoolActual : '-',
                    targetDelta: item?.targetDelta || item?.targetDelta === 0 ? item.targetDelta : '-',
                    trailerPoolCompliancePercentage: item?.trailerPoolCompliancePercentage || item?.trailerPoolCompliancePercentage === 0 ? item.trailerPoolCompliancePercentage : '-',
                    outOfServiceTrailers: item?.outOfServiceTrailers || item?.outOfServiceTrailers === 0 ? item.outOfServiceTrailers : '-',
                    complianceTargetPercentage: data?.trailerPoolComplianceTargetPercentage ? data.trailerPoolComplianceTargetPercentage : '-'
                }
                parsedShipmentValues.push(obj);
            })

        } else {
            let x = data?.shipments.forEach(item => {

                let parsedStopDetails = [];
                let parsedPendingTask = []
                let y = item?.stops.forEach(d => {
                    let parsedApptHistory = [];
                    let z = d?.apptHistory?.forEach(history => {
                        let his = {
                            apptDateTimeFormatted: history?.apptDateTime && d?.location?.timezone ? moment(history?.apptDateTime).tz(d?.location?.timezone).format(`MMM DD YYYY HH:mm z`) : '-',
                            apptReasonCode: history?.apptReasonCode ? history?.apptReasonCode : '-',
                            apptReasonCodeDesc: history?.apptReasonCodeDesc ? history?.apptReasonCodeDesc : '-',
                            updatedAtDateTime: history?.updatedAtDateTime && d?.location?.timezone ? moment(history?.updatedAtDateTime).tz("US/Central").format(`MM/DD/YY HH:mm z`) : '-',
                            updatedByUserId: history?.updatedByUserId ? history?.updatedByUserId : '-',
                            isBaseLineApptDateTime: history?.isBaseLineApptDateTime ? history.isBaseLineApptDateTime : '-'
                        }
                        parsedApptHistory.push(his);
                    })
                    let latestApptHistory = parsedApptHistory.length ? parsedApptHistory.shift() : {};
                    let val = {
                        ...d,
                        stopNum: d?.stopNum ? d?.stopNum : '-',
                        stopType: d?.stopType ? d?.stopType : '-',
                        location: {
                            id: d?.location?.id ? d?.location?.id : '-',
                            timezone: d?.location?.timezone ? d?.location?.timezone : 'UTC',
                            city: d?.location?.city ? d?.location?.city : '-',
                            state: d?.location?.state ? d?.location?.state : '-'
                        },
                        apptDateTimeFormatted: d?.apptDateTime ? moment(d?.apptDateTime).tz(d?.location?.timezone || 'UTC').format(`MMM DD YYYY HH:mm z`) : '-',
                        apptReasonCode: d?.apptReasonCode ? d?.apptReasonCode : '-',
                        apptReasonCodeDesc: d?.apptReasonCodeDesc ? d?.apptReasonCodeDesc : '-',
                        apptHistory: d?.apptHistory ? d?.apptHistory : [],
                        latestApptHistory: latestApptHistory,
                        oldApptHistory: parsedApptHistory,
                        actualArrivalTimestampCarrierFormatted: d?.actualArrivalTimestampCarrier ? moment(d?.actualArrivalTimestampCarrier).tz(d?.location?.timezone || 'UTC').format(`MMM DD YYYY HH:mm z`) : '-',
                        actualArrivalReasonCodeCarrier: d?.actualArrivalReasonCodeCarrier ? d?.actualArrivalReasonCodeCarrier : '-',
                        actualArrivalReasonCodeDescCarrier: d?.actualArrivalReasonCodeDescCarrier ? d?.actualArrivalReasonCodeDescCarrier : '-',
                        actualArrivalTimestampKCFormatted: d?.actualArrivalTimestampKC ? moment(d?.actualArrivalTimestampKC).tz(d?.location?.timezone || 'UTC').format(`MMM DD YYYY HH:mm z`) : '-',
                        actualArrivalReasonCodeFinal: d?.actualArrivalReasonCodeFinal ? d?.actualArrivalReasonCodeFinal : '-',
                        actualArrivalReasonCodeDescFinal: d?.actualArrivalReasonCodeDescFinal ? d?.actualArrivalReasonCodeDescFinal : '-',
                        arrivalOnTime: d?.arrivalOnTime ? d?.arrivalOnTime : '-',
                        isArrivalEventLocked: d?.isArrivalEventLocked ? d?.isArrivalEventLocked : false,
                        carrierUpdateLifecycleHealthArrival: d?.carrierUpdateLifecycleHealthArrival ? d?.carrierUpdateLifecycleHealthArrival : '-',
                        actualDepartureTimestampCarrierFormatted: d?.actualDepartureTimestampCarrier ? moment(d?.actualDepartureTimestampCarrier).tz(d?.location?.timezone || 'UTC').format(`MMM DD YYYY HH:mm z`) : '-',
                        actualDepartureTimestampKCFormatted: d?.actualDepartureTimestampKC ? moment(d?.actualDepartureTimestampKC).tz(d?.location?.timezone || 'UTC').format(`MMM DD YYYY HH:mm z`) : '-',
                        isDepartureEventLocked: d?.isDepartureEventLocked ? d?.isDepartureEventLocked : false,
                        carrierUpdateLifecycleHealthDeparture: d?.carrierUpdateLifecycleHealthDeparture ? d?.carrierUpdateLifecycleHealthDeparture : '-',
                        actualDepartureReasonCodeCarrier: d?.actualDepartureReasonCodeCarrier ? d?.actualDepartureReasonCodeCarrier : '-',
                        actualDepartureReasonCodeDescCarrier: d?.actualDepartureReasonCodeDescCarrier ? d?.actualDepartureReasonCodeDescCarrier : '-'
                    }
                    parsedStopDetails.push(val);
                })
                let p = item?.shipmentAttributes?.forEach(pt => {
                    let val = getEnumValue(pt, ENUM_DATA);
                    parsedPendingTask.push(val);
                    return parsedPendingTask;
                })

                let obj = {
                    ...item,
                    shipmentNum: item?.shipmentNum ? item?.shipmentNum : '-',
                    carrierId: item?.carrierId ? item?.carrierId : '-',
                    carrierCode: item?.carrierCode ? item?.carrierCode : '-',
                    carrierDesc: item?.carrierDesc ? item?.carrierDesc : '-',
                    tariffServiceCode: item?.tariffServiceCode ? item?.tariffServiceCode : '-',
                    tariffServiceCodeDesc: item?.tariffServiceCodeDesc ? item?.tariffServiceCodeDesc : '-',
                    equipmentType: item?.equipmentType ? item?.equipmentType : '-',
                    trailerNum: item?.trailerNum ? item?.trailerNum : '-',
                    tmsShipmentStatus: item?.tmsShipmentStatus ? item?.tmsShipmentStatus : '-',
                    orderExecutionBucket: item?.orderExecutionBucket ? item?.orderExecutionBucket : '-',
                    orderExecutionBucketDesc: item?.orderExecutionBucketDesc ? item?.orderExecutionBucketDesc : '-',
                    pickupApptDateTimeFormatted: item?.pickupApptDateTime && item?.origin?.timezone ? moment(item?.pickupApptDateTime).tz(item?.origin?.timezone).format(`MMM DD YYYY HH:mm z`) : '-',
                    pickupOnTime: item?.pickupOnTime ? item?.pickupOnTime : '-',
                    finalDeliveryApptDateTimeFormatted: item?.finalDeliveryApptDateTime && item?.finalDestination?.timezone ? moment(item?.finalDeliveryApptDateTime).tz(item?.finalDestination?.timezone).format(`MMM DD YYYY HH:mm z`) : '-',
                    finalDeliveryOnTime: item?.finalDeliveryOnTime ? item?.finalDeliveryOnTime : '-',
                    originName: item?.origin?.name ? item?.origin?.name : '-',
                    originStreet: item?.origin?.street ? item?.origin?.street : '-',
                    originCity: item?.origin?.city ? item?.origin?.city : '-',
                    originState: item?.origin?.state ? item?.origin?.state : '-',
                    originCountry: item?.origin?.country ? item?.origin?.country : '-',
                    finalDestinationName: item?.finalDestination?.name ? item?.finalDestination?.name : '-',
                    finalDestinationStreet: item?.finalDestination?.street ? item?.finalDestination?.street : '-',
                    finalDestinationCity: item?.finalDestination?.city ? item?.finalDestination?.city : '-',
                    finalDestinationState: item?.finalDestination?.state ? item?.finalDestination?.state : '-',
                    finalDestinationCountry: item?.finalDestination?.country ? item?.finalDestination?.country : '-',
                    carrierUpdateLifecycleHealth: item?.carrierUpdateLifecycleHealth ? item?.carrierUpdateLifecycleHealth : '-',
                    stops: parsedStopDetails,
                    shipmentAttributesParsed: item?.shipmentAttributes?.length ? parsedPendingTask : '-',
                    minutesLeftForCarrierToProvideActualsFormatted: item.minutesLeftForCarrierToProvideActuals || item.minutesLeftForCarrierToProvideActuals === 0 ? toHoursAndMinutes(item.minutesLeftForCarrierToProvideActuals) : '-',
                    sentToSAP: item?.sentToSAP ? item.sentToSAP : '-',
                    tmsInSync: item?.tmsInSync ? item.tmsInSync : '-',
                    totalComments: item?.commentResponse?.totalComments ? item.commentResponse.totalComments : '+',
                    liveLoadInd: item?.liveLoadInd ? item.liveLoadInd : '-',
                    cpShipmentType: item?.cpShipmentType ? item.cpShipmentType : '-'
                }
                parsedShipmentValues.push(obj);
            })
        }  
        let rest = payload.tableName === 'laneAwardCompliance' || payload.tableName === 'trailerPoolCompliance'
            ? {
                onPage: data?.onPage,
                pageSize: data?.pageSize,
                totalPages: data?.totalPages,
                totalRecords: data?.totalRecords,
                complianceTargetPercentage: payload.tableName === 'laneAwardCompliance'
                    ? data?.complianceTargetPercentage ? data.complianceTargetPercentage : 0
                    : data?.trailerPoolComplianceTargetPercentage ? data.trailerPoolComplianceTargetPercentage : 0,
                compliancePercentage: payload.tableName === 'laneAwardCompliance'
                    ? data?.compliancePercentage ? data.compliancePercentage : 0
                    : data?.trailerPoolCompliancePercentage ? data.trailerPoolCompliancePercentage : 0
            } 
            : { ...data }
        yield put(setTableDataSuccess({ ...rest, shipments: parsedShipmentValues }));       
    } catch (error) {
        yield put(setTableDataFailure(error.toString()))
    }
}

/**
* watcher saga - looks for the respective action dispatch. 
* First argument in the take/takeLatest/takeEvery is the action type and 
* the second argument is the worker saga function.
*/
function* tableDataSaga() {
    yield takeLatest("tableData/getTableData", workerGetTableDataFetch);
}

// export const tableSaga = [fork(tableDataSaga)];
export default tableDataSaga;