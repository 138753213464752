import axios from '../helpers/axioxInstance';
import URLS from '../constData/urls';

/**
 * API handler gets called by a worker saga
 */
export const fetchCarrierServiceMetadata = async ([abortController]) => {
    const response = await axios.get(`${URLS.FETCH_CARRIER_SERVICE_METADATA}?originRegion=NA`, { signal: abortController.signal });
    if (response.status >= 400) {
        throw new Error(response.message);
    }
    let list = response.data.map((item, idx) => {
        return {
            id: idx,
            label: item.carrierCode,
            value: item.carrierCode,
            checked: false,
            carrierCode: item.carrierCode,
            tariffServiceCodeList: item.tariffServiceCodeList
        }
    })

    return list;
}
