import React from "react";
import { Tooltip } from "react-tooltip";
import {
    BarChart,
    Bar,
    XAxis,
    Cell,
    LabelList,
    ResponsiveContainer,
    Customized,
    YAxis,
} from "recharts";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import qs from "query-string";
import "react-tooltip/dist/react-tooltip.css";
import { Box } from "@mui/material";
import ReferenceLine, { renderCustomizedLabel } from "./elements/BarChartElements";
import "../components/performance/custom.sass";
import { palette } from "../theme/ColorPalette";
import { useTranslation } from "react-i18next";
import { globalFiltersRequired } from "../helpers";

const COLORS = ["#0091EA", "#E56363", "#E58417"];
const LOADING_COLOR = palette.lightSpaceGey

export default function App({ xKey, yKey, referenceValue, loading=true, gotoPath, ...rest }) {
    const { t } = useTranslation();
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { carrierid } = useParams();

    const [data, setData] = React.useState(() => rest.data.map((d, i) => ({...d, [xKey]: `${t(d[xKey])} (${d.value})`, index: i})));
    const [isOpen, setIsOpen] = React.useState(false);
    const [isTopOpen, setIsTopOpen] = React.useState({ open: true, data: null });
    const [values, setValues] = React.useState(null);
    
    
    React.useEffect(() => {
        if (loading) {
            setData([
                { name: t('tender_accepted'), value: 100, percent: 100, index: 0 },
                { name: t('tender_rejected'), value: 80, percent: 80, index: 1 },
                { name: t('tender_no_response'), value: 60, percent: 60, index: 2 }
            ])
        } else {
            setData(rest.data.map((d, i) => ({...d, [xKey]: `${t(d[xKey])} (${d.value})`, index: i})) || [])
        }
    }, [loading, rest.data])

    const onClick = (props) => {
        setIsTopOpen({ open: false, data: null });
        setIsOpen(true);
        setValues(props);
    };

    const goToPage = (dataprop) => {   
        const queries = qs.parse(location.search);
        let filtersRequired = {};
        globalFiltersRequired.map(d => {
            filtersRequired[d] = queries[d];
        });
        if(gotoPath === 'firstTenderCompliance') {
            navigate({
                ...location,
                search: qs.stringify({
                    ...filtersRequired,
                    isPickedByPrimaryList: searchParams.get("tenderedToPrimaryList"),
                    tenderStatusList: dataprop?.name?.toLowerCase().includes('accepted')
                        ? JSON.stringify(["TENDER_ACCEPTED"])
                        : dataprop?.name?.toLowerCase().includes('rejected')
                            ? JSON.stringify(["TENDER_REJECTED"])
                            : JSON.stringify(["TENDER_NO_RESPONSE"])                    
                }),
                pathname: `/dashboard/performance/${carrierid}/${gotoPath}`,
            }, { replace: true })
        }        
    }

    return (
        <div >
            {/* <Tooltip
                className="tooltip"
                events={["hover"]}
                anchorSelect="[id^='testidtop-']"
                isOpen={isTopOpen.open}
                content={
                    <div style={{ borderLeft: `4px solid ${COLORS[isTopOpen?.data?.index]}` }}>
                        &nbsp;&nbsp;
                        {isTopOpen?.data?.name}
                    </div>
                }
                place="top"
            /> */}
            <Tooltip
                id={values?.index}
                className="tooltip"
                events={['hover']}
                anchorSelect="#testid"
                isOpen={isOpen}
                content={<div style={{ borderLeft: `4px solid ${COLORS[values?.index]}` }}>
                &nbsp;&nbsp;
                {values?.name}
            </div>}
                place="left"
            />
            <Box sx={{ width: "100%", height: 200 }}>
                <ResponsiveContainer>
                    <BarChart
                        data={data}
                        domain={[0, 100]}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        <XAxis dataKey={xKey} tickLine={false} onClick={(e) => goToPage({ name: e.value })} cursor={"pointer"} />
                        <YAxis domain={[0, 100]} hide />
                        <Bar
                            label={false}
                            stackId="a"
                            data-tooltip-id="testid"
                            data-testid="testid"
                            dataKey={"percent"}
                            id="testid"
                            onMouseEnter={onClick}
                            onMouseOut={() => setIsOpen(false)}
                            
                        >
                            {data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={loading ? LOADING_COLOR: COLORS[index]}
                                    onClick={() => goToPage(data[index])}
                                    cursor={"pointer"}
                                />
                            ))}
                            {/**LabelList represents the label on top of the bars */}
                            <LabelList
                                dataKey="name"
                                content={(props) => renderCustomizedLabel({
                                    ...props,
                                    loading, 
                                    data,
                                    tooltipStatus: isTopOpen,
                                    tooltipAction: setIsTopOpen,
                                    yKey
                                })}
                            />
                        </Bar>
                        {/**ReferenceLine represents the green target line on the bar graph */}
                        {!loading ? <Customized component={(props) => <ReferenceLine {...props} refrenceValue={referenceValue} />} /> : null}
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        </div>
    );
}
