import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo, useEffect } from 'react';
import uniqid from 'uniqid';

export const TAB_ID = 'Tab-Id';
export const DEVICE_TYPE = 'Device-Type';

export default () => {

    const id = useMemo(uniqid, []);
    let deviceType = "desktop";

    const deviceNames = {
        xs: "mobile",
        sm: "tablet",
        md: "small laptop",
        lg: "laptop & desktop",
        xl: "desktop",
    };
    Object.keys(deviceNames).map(d => {
        const screen = useMediaQuery(useTheme().breakpoints.only(d),  {
            defaultMatches: true
        });
        if(screen) {
            deviceType = deviceNames[d];
        }
        return d;
    });

    useEffect(() => {
        if (typeof Storage !== 'undefined') {
            sessionStorage.setItem(TAB_ID, id)
            sessionStorage.setItem(DEVICE_TYPE, deviceType)
        }
    }, [id, deviceType]);

    return id;
}