import axios from '../helpers/axioxInstance';
import URLS from '../constData/urls';

/**
 * API handler gets called by a worker saga
 */

const tableURlMapping = {
    trackNtrace: URLS.FETCH_TABLE_DATA,
    onTimePickup: URLS.FETCH_TABLE_DATA_ON_TIME_PERFORMANCE,
    onTimeDelivery: URLS.FETCH_TABLE_DATA_ON_TIME_PERFORMANCE,
    firstTenderCompliance: URLS.FETCH_TABLE_DATA_FIRST_TENDER,
    laneAwardCompliance: URLS.FETCH_TABLE_DATA_LANE_AWARD,
    trailerPoolCompliance: URLS.FETCH_TABLE_DATA_TRAILER_POOL
}

export const fetchTableData = async ([pageNum, reqBody, pageSize, tableName, controller]) => {
    
    const response = await axios.post(`${tableURlMapping[tableName]}?pageNum=${pageNum}&pageSize=${pageSize}`, reqBody, { signal: controller.signal });

    if(response.status >= 400) {
        throw new Error(response.message);
    }
    return response;
}