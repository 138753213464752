import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import './CustomButton.sass'
import clsx from 'clsx';

const CustomButton = (props) => {

    const {
        onClick,
        disabled,
        disableFocusRipple,
        disableRipple,
        endIcon,
        fullWidth,
        startIcon,
        href,
        children,
        btnType,
        datatestid,
        className,
        size,
        ...rest
    } = props;

    return (
        <Button
            data-testid={datatestid ? datatestid : 'customButton'}
            className={clsx(btnType, className)}
            onClick={onClick}
            disabled={disabled}
            disableFocusRipple={disableFocusRipple}
            disableRipple={disableRipple}
            endIcon={endIcon}
            fullWidth={fullWidth}
            startIcon={startIcon}
            href={href}
            size={size}
        >
            <>
                {children}
            </>
        </Button>
    )
}


CustomButton.propTypes = {
    btnType: PropTypes.oneOf([
        "button",
        "primary",
        "secondary",
        "tertiary",
        "quaternary",
        "quinary",
        "senary",
        "septnary",
        "octonary"
    ]),
    size: PropTypes.oneOf([
        "small",
        "medium",
        "large"
    ]),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    disableFocusRipple: PropTypes.bool,
    disableRipple: PropTypes.bool,
    fullWidth: PropTypes.bool,
    endIcon: PropTypes.node,
    startIcon: PropTypes.node,
    href: PropTypes.string,
}

CustomButton.defaultProps = {
    btnType: "button",
    disabled: false,
    fullWidth: false,
    disableFocusRipple: true,
    disableRipple: true,
}

export default CustomButton