import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';

const CustomCheckBox = (props) => {

    return (
        <Checkbox
            data-testid={props.name}
            {...props}
        />

    )
}

CustomCheckBox.propTypes = {
    checked: PropTypes.bool,
    disableRipple: PropTypes.bool,
    value: PropTypes.string,
    disabled: PropTypes.bool
}

CustomCheckBox.defaultProps = {
    id: 'custom-checkbox',
    disableRipple: true
}

export default CustomCheckBox;