import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import Typography from '@mui/material/Typography';
import './CustomChip.sass'

const CustomChip = (props) => {
    const { label, onDelete, chipType, filterLabel, valueLabel } = props;

    const handleDelete = () => {
        onDelete(filterLabel, valueLabel);
    }

    return (
        <div>
            <Chip
                label={<div className='chipLabel'>
                    <Typography variant='h6'>{label}</Typography>
                    &nbsp;-&nbsp;
                    <Typography variant='h6' className='chipValueLabel'>{valueLabel}</Typography>
                </div>}
                onDelete={handleDelete}
                className={chipType}
                deleteIcon={<ClearRoundedIcon data-testid={valueLabel} />}
            />
        </div>
    )
}

CustomChip.propTypes = {
    label: PropTypes.string,
    chipType: PropTypes.oneOf([
        "primary"
    ])
}

CustomChip.defaultProps = {
    chipType: "primary"
}

export default CustomChip