import { createSlice } from "@reduxjs/toolkit";

const tmsMetaDataSlice = createSlice({
    name: "tmsMetaDataService",
    initialState: {
        tmsReasonMetadata: [],
        isMetaDataLoading: false,
        errorMessage: undefined
    },
    reducers: {
        getTmsMetaData: (state) => {
            state.isMetaDataLoading = true;
            state.tmsReasonMetadata = []
        },
        setTmsMetaDataSuccess: (state, action) => {
            state.isMetaDataLoading = false;
            state.tmsReasonMetadata = action.payload;
        },
        setTmsMetaDataFailure: (state, action) => {
            state.isMetaDataLoading = false;
            state.tmsReasonMetadata = [];
            state.errorMessage = action.payload
        },
    }
});

export const {
    getTmsMetaData,
    setTmsMetaDataSuccess,
    setTmsMetaDataFailure
} = tmsMetaDataSlice.actions;

export default tmsMetaDataSlice.reducer;
