import React, { useCallback, useEffect } from 'react'
import Dashboard from '../components/Dashboard';
import { Routes, Route, useNavigate } from "react-router-dom";
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import OktaConfig from '../OktaConfig';
import Home from "../components/Home";
import TrackNTrace from '../components/TrackNTrace';
import CarrierPerformance from '../components/CarrierPerformance';
import OnTimePickup from '../components/performance/OnTimePickup';
import OnTimeDelivery from '../components/performance/OnTimeDelivery';
import FirstTenderCompliance from '../components/performance/FirstTenderCompliance';
import useUniqTab from '../helpers/UniqTab';
import CarrierLayout from './CarrierLayout';
import LaneAwardCompliance from '../components/performance/LaneAwardCompliance';
import TrailerPoolCompliance from '../components/performance/TrailerPoolCompliance';

const oktaAuth = new OktaAuth(OktaConfig);
const NotFound = () => <h1>Page Not Found</h1>;

const RoutePath = () => {
    const navigate = useNavigate();
    const restoreOriginalUri = useCallback(async (_oktaAuth, originalUri) => {
        // const to = originalUri || "/dashboard";
        // navigate(to, { replace: true })
        navigate(toRelativeUrl(originalUri || '/dashboard/tracking', window.location.origin));
    }, [])

    useUniqTab();

    /* 
    Following useEffect is a fix for warning: 
    Two custom restoreOriginalUri callbacks are detected. 
    The one from the OktaAuth configuration will be overridden by the provided restoreOriginalUri prop from the Security component. 
    */
    useEffect(() => {
        return () => {
            oktaAuth.options.restoreOriginalUri = undefined
        }
    }, [])
    return (
        <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
            <Routes>
                <Route path='/' exact={true} element={<Home />} />
                <Route path='/dashboard' element={<Dashboard />} >
                    <Route index element={<TrackNTrace />} />
                    <Route path="tracking" element={<TrackNTrace />} />
                    <Route path="performance" element={<CarrierLayout />}>
                        {/* <=====================================================> */}
                        {/* Also add reference paths in helper file , we will be needing in regenerating paths */}
                        {/* <=====================================================> */}
                        <Route path="" index element={<CarrierPerformance />} />
                        <Route path=":carrierid" element={<CarrierPerformance />} />
                        <Route path=":carrierid/onTimePickup" element={<OnTimePickup />} />
                        <Route path=":carrierid/onTimeDelivery" element={<OnTimeDelivery />} />
                        <Route path=":carrierid/firstTenderCompliance" element={<FirstTenderCompliance />} />
                        <Route path=":carrierid/laneAwardCompliance" element={<LaneAwardCompliance />} />
                        <Route path=":carrierid/trailerPoolCompliance" element={<TrailerPoolCompliance />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Route>
                <Route path='/callback' element={<LoginCallback />} />
                <Route path='/*' element={<NotFound />} />
            </Routes>
        </Security>
    )
}

export default RoutePath;