import { createSlice } from "@reduxjs/toolkit";

const carrierServiceMetadataSlice = createSlice({
    name: "carrierServiceMetadata",
    initialState: {
        carrierServiceMetadata: [],
        isCarrierServiceMetadataLoading: false,
        carrierServiceMetadataErrorMessage: undefined
    },
    reducers: {
        getCarrierServiceMetadata: (state) => {
            state.isCarrierServiceMetadataLoading = true;
            state.carrierServiceMetadata = []
        },
        setCarrierServiceMetadataSuccess: (state, action) => {
            state.isCarrierServiceMetadataLoading = false;
            state.carrierServiceMetadata = action.payload;
        },
        setCarrierServiceMetadataFailure: (state, action) => {
            state.isCarrierServiceMetadataLoading = false;
            state.carrierServiceMetadata = [];
            state.carrierServiceMetadataErrorMessage = action.payload
        },
        resetCarrierServiceMetadata: (state) => {
            state.carrierServiceMetadata = [];
            state.isCarrierServiceMetadataLoading = false;
            state.carrierServiceMetadataErrorMessage = undefined
        }
    }
});

export const {
    getCarrierServiceMetadata,
    setCarrierServiceMetadataSuccess,
    setCarrierServiceMetadataFailure,
    resetCarrierServiceMetadata
} = carrierServiceMetadataSlice.actions;

export default carrierServiceMetadataSlice.reducer;
