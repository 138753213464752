import React from "react";
import { useTranslation } from "react-i18next";

export const renderCustomizedLabel = (props) => {
    const { x, y, width, index, tooltipAction, tooltipStatus, yKey, loading } = props;
    const radius = 10;
    const data = props.data[index]
    return (
        <g
            data-testid="tooltip-g"
            onMouseMove={() => {
                if (!tooltipStatus.open) {
                    tooltipAction({ open: true, data })
                }
            }}>
            <text
                id={`testidtop-${index}`}
                x={x + width / 2}
                y={y - radius}
                textAnchor="middle"
                dominantBaseline="middle">
                {loading ? "-": data[yKey]}%
            </text>
        </g>
    );
};

const ReferenceLine = (props) => {
    const { t } = useTranslation();
    const { height, refrenceValue } = props;
    // const [show, setShow] = React.useState(false);
    const y1 = props.yAxisMap[0].scale(refrenceValue) - props.height;
    const x2 = props.xAxisMap[0].bandSize + props.xAxisMap[0].x;

    return (
        <g
            className="pointer"
            data-testid="referenceline"
            transform={`translate(0, ${height})`}
            // onMouseEnter={() => setShow(true)}
            // onMouseOut={() => setShow(false)}
        >
            <line
                x1={0 + props.offset.brushBottom}
                y1={y1}
                x2={x2}
                y2={y1}
                className="strokegreen"
            />
            {
                // show && 
                <text
                    x={x2 + props.barGap}
                    y={y1}
                    textAnchor="start"
                    dominantBaseline="middle">
                    {`${refrenceValue}% ${t('acceptance_target')}`}
                </text>
            }
        </g>
    );
};

export default ReferenceLine;