import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { browserTimezone } from '../helpers';

const Comment = ({ comments }) => {

    const { t } = useTranslation();

    return (
        <Grid container data-testid="comment">
            {
                comments?.map((d, index) => {
                    return <>
                        <Grid item xs={12} key={index}>
                            <div className='paginationDiv'>
                                <Typography variant='h3' className='comment'>{d?.createdByUserFirstAndLastName}</Typography>
                                <Typography variant='h3'>{moment(d?.createdAt).tz(browserTimezone).format('LT')}</Typography>
                            </div>
                            <div className='paginationDiv'>
                                <Typography variant='body2'>{`${t('id')}: ${d?.createdByUserId}`}</Typography>
                                <Typography variant='body2'>{moment(d?.createdAt).tz(browserTimezone).format('DD MMM YYYY')}</Typography>
                            </div>

                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5' mt={'10px'} mb={'10px'} className='comment'>{d?.commentText}</Typography>
                        </Grid>
                    </>
                })
            }
        </Grid>
    )
}

export default Comment