import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { eventType } from '../../helpers/index';
import "./CustomDropdown.sass";

const ListGroup = ({ selectedChild, ...props }) => {
  const [open, setOpen] = useState(props.open);
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        data-testid="list-item-button"
      >
        <ListItemText primary={props.parent.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div"
          className="container-dropdown"
          disablePadding
        >
          {data.length && data.map((d) => {
            return (
              <ListItemButton
                data-testid={`list-item-button-${d.code}`}
                key={d.id}
                onClick={() => {
                  if (d.code !== selectedChild) {
                    props.setItem(d);
                    props.updateList(props.parent.id, d.code);
                    props.changeEvent(d.code);
                  }
                }}
                selected={d.code === selectedChild}
                className="list-item-button"
              >
                <ListItemText primary={d.description} />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

function NestedList({ nested, changeEvent, eventName, ...props }) {
  const [listdata, setListData] = useState(nested);
  const updateChild = (_, childid) => {
    const final = nested?.map((d) => {
      const isChildSelected = d.children.filter((ch) => ch.id)[0];
      return {
        ...d,
        open: isChildSelected ? true : false,
        children: d.children.map((c) => {
          return {
            ...c,
            open: c.id === childid ? true : false
          };
        })
      };
    });
    // changeEvent(childid);
    setListData(final);
  };

  useEffect(() => {
    updateChild(null, props.selectedChild);
  }, [props.selectedChild]);


  return (
    <List
      className={"popper-body"}
      // sx={{
      //   width: "100%",
      //   border: 1,
      //   borderRadius: 1,
      //   maxWidth: 300,
      //   bgcolor: "background.paper"
      // }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {listdata.map((d) => {
        return (
          <ListGroup
            {...props}
            key={d.id}
            updateList={updateChild}
            data={d.children}
            open={d.open}
            parent={d}
            setItem={props.setItem}
            changeEvent={changeEvent}
          />
        );
      })}
    </List>
  );
}

function getNestedChildren(arr, eventName) {
  let result = {};
  let filtered = arr.filter(item => item.eventType !== null && item.eventType === eventType[eventName]);
  filtered.map(d => {
    if (result[d.category]) {
      result[d.category].push({
        code: d.code,
        description: d.description,
        eventType: d.eventType
      })
    } else {
      result[d.category] = [{
        code: d.code,
        description: d.description,
        eventType: d.eventType
      }]
    }
  });
  result = Object.keys(result).map((key, index) => {
    return {
      id: index,
      name: key,
      open: false,
      children: [
        ...result[key]
      ]
    }
  });
  return result;
}

export default function TextBox({ value, changeEvent, eventName, error, forceError }) {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const tmsReasonMetadata = useSelector(({ tmsMetaData: { tmsReasonMetadata } }) => tmsReasonMetadata);

  const nested = getNestedChildren(tmsReasonMetadata, eventName);

  const handleClick = (event) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  const [item, setItem] = useState({});

  useEffect(() => {
    const found = tmsReasonMetadata.filter(d => d.code === value)[0]
    if (found) {
      setItem({
        name: found?.description,
        id: found?.code,
        eventType: found?.eventType
      })
    }
  }, [value]);

  return (
    <>
      <ListItemButton
        data-testid={"dd_open_button"}
        className={(error || forceError)
          ? "popper-open-button-red"
          : open
            ? "popper-open-button-indigo"
            : "popper-open-button-white"
        }
        // sx={{ width: 200, borderRadius: 1, border: 1, height: 32 }}
        onClick={handleClick}
      >
        <ListItemText title={item?.name} primary={item?.name || t("select_option")} className="ellipsis" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {open && <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={"right-start"}
          transition
          sx={{ zIndex: 1000 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={"popper-container"}>
                <NestedList
                  setItem={setItem}
                  selectedChild={item.id}
                  nested={nested}
                  changeEvent={changeEvent}
                  eventName={eventName}
                />
              </Paper>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>}

    </>
  );
}
