import React from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const CustomSnackbar = (props) => {
    const {
        open,
        message,
        handleClose,
        severity,
        variant,
        anchorOrigin,
        autoHideDuration,
        datatestId
    } = props;

    return (
        <div>
            <Snackbar
                open={open}
                anchorOrigin={anchorOrigin}
                autoHideDuration={autoHideDuration}
                data-testid={datatestId}
                onClose={handleClose}
            >
                <MuiAlert
                    data-testid={`alert_${datatestId}`}
                    onClose={handleClose}
                    severity={severity}
                    variant={variant}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

CustomSnackbar.propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string,
    handleClose: PropTypes.func,
    severity: PropTypes.oneOf([
        "error",
        "info",
        "success",
        "warning"
    ]),
    variant: PropTypes.oneOf([
        "filled",
        "outlined",
        "standard"
    ]),

}

CustomSnackbar.defaultProps = {
    open: false,
    severity: "info",
    variant: "filled",
    anchorOrigin: {
        horizontal: 'center',
        vertical: 'top'
    }
}

export default CustomSnackbar