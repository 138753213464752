import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import TextFieldWrapperComponent from './FilterWrappers/TextFieldWrapperComponent';
import CheckboxWrapperComponent from './FilterWrappers/CheckboxWrapperComponent';
import DatePickerWrapperComponent from './FilterWrappers/DatePickerWrapperComponent';
import { carrierRestrictedFilters, carrierRoles } from '../helpers';
import './FiltersMenu.sass';

export const Component = (props) => {
  const filterModules = {
    text: TextFieldWrapperComponent,
    checkbox: CheckboxWrapperComponent,
    date: DatePickerWrapperComponent
  }

  const { type, filter, title, multiSearch, filterKey, dateFilter, tableName } = props;
  const FilterComponent = filterModules[type];

  return <FilterComponent
    tableName={tableName}
    type={type}
    filter={filter}
    title={title}
    multiSearch={multiSearch}
    filterKey={filterKey}
    dateFilter={dateFilter}
  />
}

const FiltersMenu = ({ tableName }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const { [tableName]: { filters } } = useSelector(state => state?.filterSelected);
  const { userInfo: { role } } = useSelector(state => state?.user);

  const hangelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  const renderFilters = (index, item) => {
    return <div key={index}>
      <Accordion
        expanded={expanded === filters[item]?.name}
        onChange={hangelChange(filters[item]?.name)}
        className={expanded === filters[item]?.name ? "accordionStylesActive" : "accordionStyles"}
      >
        <AccordionSummary
          expandIcon={<></>}
          aria-controls={`${index}-content`}
          id={`${index}-header`}
          data-testid={`summary-${filters[item]?.name}`}
        >
          <Typography variant='h3' className="filterTitle">{t(filters[item]?.name)}</Typography>
        </AccordionSummary>
        <AccordionDetails className='accordionDetailsStyles' data-testid={`details-${filters[item]?.name}`}>
          <Component
            tableName={tableName}
            type={filters[item]?.type}
            filter={filters[item]?.data}
            title={filters[item]?.name}
            multiSearch={filters[item]?.stringToArray}
            filterKey={item}
            filterMenu={true}
            dateFilter={{
              dummytime: filters[item]?.dummytime,
              startTime: filters[item]?.startTime,
              endTime: filters[item]?.endTime,
            }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  }

  return (
    <>
      {
        Object.keys(filters).map((item, index) => carrierRoles.includes(role)
          ? !carrierRestrictedFilters.includes(item) && renderFilters(index, item)
          : item !== 'shipmentNumList' && renderFilters(index, item))
      }
    </>
  )
}

export default FiltersMenu