import './App.css';
import Routes from './layouts/Routes';
import { appTheme } from './theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ErrorBoundary from './ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <div data-testid="app" className="App">
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <Routes />
        </ThemeProvider>
      </div>
    </ErrorBoundary>
  );
}

export default App;
