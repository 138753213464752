import axios from '../helpers/axioxInstance';
import URLS from '../constData/urls';
import { getFloatValue, toHoursAndMinutes } from '../helpers';

/**
 * API handler gets called by a worker saga
 */
const getPath = (type) => {
    let pathMapping = {
        onTimePickup: URLS.ON_TIME_PERFORMANCE,
        onTimeDelivery: URLS.ON_TIME_PERFORMANCE,
        firstTenderCompliance: URLS.FIRST_TENDER,
        laneAwardCompliance: URLS.LANE_AWARD_COMPLIANCE,
        carrierEventResponse: URLS.CARRIER_EVENT_RESPONSE,
        fourKitesTracking: URLS.FOURKITES_TRACKING,
        billingAccuracy: URLS.BILLING_ACCURACY,
        trailerPoolCompliance: URLS.TRAILER_POOL_COMPLIANCE
    }
    return pathMapping[type];
}

const stopEventTypeResponseRate = [
    'PICKUP_ARRIVAL_CARRIER_RESPONSE_RECEIVED',
    'PICKUP_DEPARTURE_CARRIER_RESPONSE_RECEIVED',
    'DELIVERY_ARRIVAL_CARRIER_RESPONSE_RECEIVED',
    'DELIVERY_DEPARTURE_CARRIER_RESPONSE_RECEIVED'
];

const stopEventTypeResponseRateOnTime = [
    'PICKUP_ARRIVAL_CARRIER_RESPONSE_RECEIVED_ON_TIME',
    'PICKUP_DEPARTURE_CARRIER_RESPONSE_RECEIVED_ON_TIME',
    'DELIVERY_ARRIVAL_CARRIER_RESPONSE_RECEIVED_ON_TIME',
    'DELIVERY_DEPARTURE_CARRIER_RESPONSE_RECEIVED_ON_TIME'
];

const stopTypeMapping = {
    "PICKUP_ARRIVAL_CARRIER_RESPONSE_RECEIVED": "arrival_at_pickstop",
    "PICKUP_DEPARTURE_CARRIER_RESPONSE_RECEIVED": "departure_from_pickstop",
    "DELIVERY_ARRIVAL_CARRIER_RESPONSE_RECEIVED": "arrival_at_dropstop",
    "DELIVERY_DEPARTURE_CARRIER_RESPONSE_RECEIVED": "departure_from_dropstop",

    "PICKUP_ARRIVAL_CARRIER_RESPONSE_RECEIVED_ON_TIME": "arrival_at_pickstop",
    "PICKUP_DEPARTURE_CARRIER_RESPONSE_RECEIVED_ON_TIME": "departure_from_pickstop",
    "DELIVERY_ARRIVAL_CARRIER_RESPONSE_RECEIVED_ON_TIME": "arrival_at_dropstop",
    "DELIVERY_DEPARTURE_CARRIER_RESPONSE_RECEIVED_ON_TIME": "departure_from_dropstop"
}

export const fetchSummary = async ([reqBody, type, controller]) => {

    const path = getPath(type);

    const response = await axios.post(path, reqBody, { signal: controller.signal });
    if (response.status >= 400) {
        throw new Error(response.message);
    }

    if (['onTimePickup', 'onTimeDelivery'].includes(type)) {
        let greenTotalCount = 0;
        let redTotalCount = 0;
        const chartData = response.data.summaryResponse.map(item => {
            let chartItem = {};
            let total = 0;
            total = item.redCount + item.greenCount;
            chartItem.data = [
                { name: item.cpShipmentType.toLowerCase(), value: item.greenCount, key: 'greenCount', type: item.cpShipmentType },
                { name: item.cpShipmentType.toLowerCase(), value: item.redCount, key: 'redCount', type: item.cpShipmentType }
            ]
            chartItem.centerTitle = item.cpShipmentType.toLowerCase();
            chartItem.centerSubTitle = total;
            chartItem.title = isNaN(getFloatValue(item.greenCount, total)) ? 0 : getFloatValue(item.greenCount, total);
            greenTotalCount = greenTotalCount + item.greenCount;
            redTotalCount = redTotalCount + item.redCount;
            return chartItem;
        });

        return {
            data: {
                ...response.data,
                summaryResponse: {
                    chartData,
                    greenTotalCount,
                    redTotalCount
                }
            },
            type: type
        };
    } else if (type === 'firstTenderCompliance') {
        let total = 0;
        let x = response.data.summaryResponse.forEach(item => {
            total = total + item.totalCount;
            return total;
        });
        const chartData = response.data.summaryResponse.map(item => {
            return {
                name: item.tenderStatus.toLowerCase(),
                value: item.totalCount,
                percent: isNaN(getFloatValue(item.totalCount, total)) ? 0 : getFloatValue(item.totalCount, total),
            }
        });
        return {
            data: {
                ...response.data,
                summaryResponse: {
                    chartData,
                    total
                },
                targetPercentage: response.data.summaryResponse[0].targetPercentage
            },
            type: type
        };
    } else if (type === 'laneAwardCompliance') {
        return {
            data: {
                summaryResponse: {
                    complianceTargetPercentage: response.data?.complianceTargetPercentage,
                    compliancePercentage: response.data?.compliancePercentage,
                    nonCompliancePercentage: (response.data?.compliancePercentage || response.data?.compliancePercentage === 0) ? (100 - response.data?.compliancePercentage) : 0
                }
            },
            type: type
        }
    } else if (type === 'carrierEventResponse') {
        let responseRate = response.data.summaryResponse.filter(item => stopEventTypeResponseRate.includes(item.stopEventType))?.map(item => {
            return {
                ...item,
                label: item?.stopEventType ? stopTypeMapping[item.stopEventType] : '-',
                responsePercentage: isNaN(getFloatValue(item.greenCount, item.totalCount)) ? 0 : getFloatValue(item.greenCount, item.totalCount)
            }
        });

        let onTimeResponse = response.data.summaryResponse.filter(item => stopEventTypeResponseRateOnTime.includes(item.stopEventType))?.map(item => {
            return {
                ...item,
                label: item?.stopEventType ? stopTypeMapping[item.stopEventType] : '-',
                onTimeResponsePercentage: isNaN(getFloatValue(item.greenCount, item.totalCount)) ? 0 : getFloatValue(item.greenCount, item.totalCount)
            }
        });
        let responseRatePickupStopNumbers = responseRate.filter(item => item.stopEventType === 'PICKUP_ARRIVAL_CARRIER_RESPONSE_RECEIVED')[0]?.totalCount
        let responseRateDropStopNumbers = responseRate.filter(item => item.stopEventType === 'DELIVERY_ARRIVAL_CARRIER_RESPONSE_RECEIVED')[0]?.totalCount
        let onTimeResponsePickupStopNumbers = onTimeResponse.filter(item => item.stopEventType === 'PICKUP_ARRIVAL_CARRIER_RESPONSE_RECEIVED_ON_TIME')[0]?.totalCount
        let onTimeResponseDropStopNumbers = onTimeResponse.filter(item => item.stopEventType === 'DELIVERY_ARRIVAL_CARRIER_RESPONSE_RECEIVED_ON_TIME')[0]?.totalCount

        return {
            data: {
                summaryResponse: {
                    responseRate: responseRate,
                    responseRatePickupStopNumbers: responseRatePickupStopNumbers,
                    responseRateDropStopNumbers: responseRateDropStopNumbers,
                    onTimeResponsePickupStopNumbers: onTimeResponsePickupStopNumbers,
                    onTimeResponseDropStopNumbers: onTimeResponseDropStopNumbers,
                    onTimeResponse: onTimeResponse,
                },
                responseExpectationInHours: response.data?.responseExpectationInMinutes ? toHoursAndMinutes(response.data.responseExpectationInMinutes, true) : '-'
            },
            type: type
        }
    } else if (type === 'fourKitesTracking') {
        const tracked = response.data?.summaryResponse.find(item => item.fourkitesTrackedStatus === 'TRACKED');
        const superTracked = response.data?.summaryResponse.find(item => item.fourkitesTrackedStatus === 'SUPER_TRACKED')

        return {
            data: {
                total: tracked.totalCount,
                summaryResponse: {
                    tracked: {
                        ...tracked,
                        trackedPercentage: isNaN(getFloatValue(tracked.greenCount, tracked.totalCount)) ? 0 : getFloatValue(tracked.greenCount, tracked.totalCount)
                    },
                    superTracked: {
                        ...superTracked,
                        superTrackedPercentage: isNaN(getFloatValue(superTracked.greenCount, superTracked.totalCount)) ? 0 : getFloatValue(superTracked.greenCount, superTracked.totalCount)
                    }
                }
            },
            type: type
        }
    } else if (type === 'billingAccuracy') {
        let summaryResponse = response.data?.summaryResponse[0];
        return {
            data: {
                summaryResponse: {
                    invoiceStatus: summaryResponse?.invoiceStatus ? summaryResponse.invoiceStatus.toLowerCase() : '-',
                    targetPercentage: summaryResponse?.targetPercentage ? summaryResponse.targetPercentage : '-',
                    totalInvoices: summaryResponse?.totalCount ? summaryResponse.totalCount : '-',
                    approvedInvoices: summaryResponse?.greenCount ? summaryResponse.greenCount : '-',
                    rejectedInvoices: summaryResponse?.redCount ? summaryResponse.redCount : '-',
                    compliancePercentage: isNaN(getFloatValue(summaryResponse?.greenCount, summaryResponse?.totalCount)) ? 0 : getFloatValue(summaryResponse?.greenCount, summaryResponse?.totalCount)
                }
            },
            type: type
        }
    } else if (type === 'trailerPoolCompliance') {
        return {
            data: {
                summaryResponse: {
                    trailerPoolComplianceTargetPercentage: response?.data?.trailerPoolComplianceTargetPercentage,
                    trailerPoolCompliancePercentage: response?.data?.trailerPoolCompliancePercentage,
                    nonCompliancePercentage: (response?.data?.trailerPoolCompliancePercentage || response?.data?.trailerPoolCompliancePercentage === 0) ? (100 - response.data.trailerPoolCompliancePercentage) : '-'
                }
            },
            type: type
        }
    }
}
