import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import BarChart from '../../charts/BarChart';
import CustomCardHeader from './CustomCardHeader';
import LanesWrapperComponent from '../FilterWrappers/LanesWrapperComponent';
import CardNoteComponent from './CardNoteComponent';
import './Card.sass';

const FirstTenderComplianceCard = ({ carrierLoading }) => {
    const { t } = useTranslation();
    const pageName = 'firstTenderCompliance';
    const [loading, setLoading] = useState(() => carrierLoading);

    const { summary: { firstTenderCompliance: { data: { targetPercentage, summaryResponse }, isLoading } } } = useSelector(state => state.chartSummary);

    useEffect(() => {
        setLoading(carrierLoading || isLoading);
    }, [carrierLoading, isLoading]);

    return (
        <div className='card1'>
            <CustomCardHeader 
                title={t('first_tender_compliance')}
                pageName={pageName}
            />
            <div className='subTitle'>
                <Typography variant='h4'>{loading ? "-": `${summaryResponse?.total} ${t('total_loads')}`}</Typography>
            </div>
            
            <LanesWrapperComponent queryStringKey="tenderedToPrimaryList" />
            <div className="barContainer">
                <BarChart
                    data={summaryResponse?.chartData || []}
                    xKey={"name"}
                    yKey={"percent"}
                    referenceValue={targetPercentage}
                    loading={loading}
                    gotoPath={pageName}
                />
            </div>
            <CardNoteComponent note={t('kpi_note_pickup_date')} />
        </div>
    )
}

export default FirstTenderComplianceCard;