import React, { useState, useEffect } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { useParams, useSearchParams } from "react-router-dom";
import DataTable from '../Table';
import { useDispatch, useSelector } from 'react-redux';
import { 
    pageSize, 
    generateGlobalFilters, 
    optionalFiltersByTable, 
    generateTableFilters,
    defaultHeaderFiltersByTable,
    globalFiltersRequired,
    isSubset
} from '../../helpers';
import TrackNTraceHeader from '../TrackNTraceHeader';
import DetailsPageHeader from './DetailsPageHeader';
import { COLUMNS_ON_TIME } from '../../constData/columnOnTime';
import { getTableData } from '../../redux/reducers/tableDataSlice';
import qs from "query-string";
import { filtersOnTime as defaultStoreFilters } from "../../constData/filtersOnTime";
import { optionalFilterPayloadOTP } from '../../constData/filterRequestPayloadOnTimePickup';
import './page.sass';


const OnTimePickup = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tableName = 'onTimePickup';
    const { carrierid } = useParams();

    const { tableData, isLoading } = useSelector(state => state?.loadDetails);

    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState(() => Object.fromEntries([...searchParams]));

    const [shipments, setShipments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [onPage, setOnPage] = useState(1);
    const [reqPayload, setReqPayload] = useState({});

    useDeepCompareEffect(() => {
        let filterValues = {};
        const params = Object.fromEntries([...searchParams]);
        Object.keys(optionalFilterPayloadOTP).map(d => {
            if (optionalFilterPayloadOTP[d]) {
                filterValues[d] = JSON.stringify(optionalFilterPayloadOTP[d]);
            }
        })
        setSearchParams(qs.stringify({
            ...filterValues,
            ...params,
        }));
        setSearch({
            ...filterValues,
            ...params,
        });
        setOnPage(1);
    }, [searchParams]);

    useDeepCompareEffect(() => {
        const controller = new AbortController();
        const globalFilters = generateGlobalFilters(globalFiltersRequired, search);
        const { tableFilters, definedValues } = generateTableFilters(optionalFiltersByTable[tableName], search, defaultStoreFilters);
        const commonPayload = defaultHeaderFiltersByTable[tableName];

        const reqBody = {
            ...globalFilters,
            ...commonPayload,
            ...tableFilters,
            carrierid: undefined,
            carrierCodeList: [carrierid],
        };

        if (isSubset(Object.keys(search), Object.keys(definedValues))) {
            setReqPayload(reqBody);
            dispatch(getTableData({
                reqBody,
                pageSize: pageSize,
                pageNum: onPage,
                tableName: tableName,
                controller
            }));
        }
        return () => {
            controller.abort();
        };
    }, [
        search, onPage, carrierid
    ]);


    useEffect(() => {
        setShipments(tableData.shipments);
        setLoading(isLoading);
        setTotalPages(tableData.totalPages);
        setTotalRecords(tableData.totalRecords);
        // setOnPage(tableData.onPage)
    }, [tableData]);

    return (
        <Grid container className="pageContainer">
            <DetailsPageHeader
                buttonTitle={t("on_time_pickup")}
                pageName={tableName}
                enableLanes={true}
            />
            <Grid item xs={12} className='actionSection'>
                <TrackNTraceHeader 
                    refreshBtn={false} 
                    tab={'performance'} 
                    tableName={tableName}
                    reqPayload={reqPayload} 
                />
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    tableName={tableName}
                    rowData={shipments}
                    columnOrder={COLUMNS_ON_TIME}
                    loading={loading}
                    size={pageSize}
                    totalPages={totalPages}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    setOnPage={setOnPage}
                />
            </Grid>
        </Grid>
    )
}

export default OnTimePickup