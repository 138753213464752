import axios from '../helpers/axioxInstance';
import URLS from '../constData/urls';

/**
 * API handler gets called by a worker saga
 */
export const fetchLocationMetadata = async ([controller]) => {
    const response = await axios.get(`${URLS.FETCH_LOCATION_METADATA}?originRegion=NA`, { signal: controller.signal });
    if (response.status >= 400) {
        throw new Error(response.message);
    }
    let x = response.data.map((item, index) => {
        return {
            id: index,
            label: item?.cityState,
            value: item?.cityState,
            checked: false
        }
    })
    return x;
}
