import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import CustomToggleButtonGroup from '../common/CustomToggleButtonGroup';
import { isJsonParsable } from "../../helpers";
import { useEffect } from 'react';

const LanesWrapperComponent = ({ queryStringKey }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState(() => Object.fromEntries([...searchParams]));
    const [active, setActive] = useState("all_lanes");
    const lane = isJsonParsable(searchParams.get(queryStringKey)) || [];

    const lanes = {
        "all_lanes": [],
        "awarded_lanes": ["Y"],
        "non_awarded_lanes": ["N"]
    }

    useEffect(() => {
        setSearch(Object.fromEntries([...searchParams]));
    }, [searchParams]);

    useEffect(() => {
        if (lane) {
            if (lane.length === 0) setActive("all_lanes");
            else if (lane.length === 1 && lane[0] === "Y") setActive("awarded_lanes");
            else if (lane.length === 1 && lane[0] === "N") setActive("non_awarded_lanes");
            else setActive("all_lanes")
        }
        if(!searchParams.get(queryStringKey)) {
            setSearchParams({
                ...search,
                [queryStringKey]: JSON.stringify(lanes[active])
            })
        }
        
    }, [lane]);

    const handleChange = (event, newActive) => {
        if (newActive !== null) {
            setActive(newActive);
            setSearchParams({
                ...search,
                [queryStringKey]: JSON.stringify(lanes[newActive])
            })
        }
    };

    return (
        <CustomToggleButtonGroup
            buttons={Object.keys(lanes)}
            onChange={handleChange}
            active={active}
            card={false}
        />
    )
}

export default LanesWrapperComponent