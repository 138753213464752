import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';
import qs from "query-string";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import DetailsPageHeader from './DetailsPageHeader';
import Typography from '@mui/material/Typography';
import { COLUMN_TRAILER_POOL_COMPLIANCE } from '../../constData/columnTrailerPoolCompliance';
import DataTable from '../Table';
import {
    pageSize,
    generateGlobalFilters,
    defaultHeaderFiltersByTable,
    globalFiltersRequired
} from '../../helpers';
import TrackNTraceHeader from '../TrackNTraceHeader';
import { getTableData } from '../../redux/reducers/tableDataSlice';
import moment from 'moment-timezone';
import { optionalFilterPayloadTPC } from '../../constData/filterRequestPayloadTPC';
import { FormControlLabel } from '@mui/material';
import CustomCheckBox from '../common/CustomCheckBox';

const TrailerPoolCompliance = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tableNameTPC = 'trailerPoolCompliance';
    const { carrierid: carrieridTPC } = useParams();

    const { tableData: tableDataTPC, isLoading: isLoadingTPC } = useSelector(state => state?.loadDetails);

    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearchLAC] = React.useState(() => Object.fromEntries([...searchParams]));

    const [shipments, setShipmentsTPC] = useState([]);
    const [loading, setLoadingTPC] = useState(true);
    const [totalPages, setTotalPagesTPC] = useState(0);
    const [totalRecords, setTotalRecordsTPC] = useState(0);
    const [onPage, setOnPageTPC] = useState(1);
    const [reqPayload, setReqPayloadTPC] = useState({});
    const [trailerPoolCompliant, setTrailerPoolCompliant] = useState(search.trailerPoolCompliant || null);

    const handleChange = (e) => {
        setTrailerPoolCompliant(e.target.checked ? 'N' : null);
        setSearchParams(qs.stringify({
            ...search,
            trailerPoolCompliant: e.target.checked ? 'N' : ''
        }));
    }

    useDeepCompareEffect(() => {
        let filterValues = {};
        const params = Object.fromEntries([...searchParams]);
        Object.keys(optionalFilterPayloadTPC).map(d => {
            if (optionalFilterPayloadTPC[d]) {
                filterValues[d] = optionalFilterPayloadTPC[d];
            }
        })
        setSearchParams(qs.stringify({
            ...filterValues,
            ...params,
        }));
        setSearchLAC({
            ...filterValues,
            ...params,
        });
        setOnPageTPC(1);
    }, [searchParams]);

    useDeepCompareEffect(() => {
        const controller = new AbortController();
        const globalFilters = generateGlobalFilters(globalFiltersRequired, search);
        const commonPayload = defaultHeaderFiltersByTable[tableNameTPC];
        const trailerPoolComplianceWindow = {
            startTime: moment(globalFilters.deliveryActualArrivalDatetimeFinalDestTZ.startTime).format('YYYY-MM-DD'),
            endTime: moment(globalFilters.deliveryActualArrivalDatetimeFinalDestTZ.endTime).format('YYYY-MM-DD')
        }

        const reqBody = {
            ...globalFilters,
            ...commonPayload,
            trailerPoolComplianceWindow,
            trailerPoolCompliant: search.trailerPoolCompliant || trailerPoolCompliant,
            carrierid: undefined,
            carrierCodeList: [carrieridTPC],
            deliveryActualArrivalDatetimeFinalDestTZ: undefined,
            isPickedByPrimaryList: undefined,
            tenderedToPrimaryList: undefined
        };

        setReqPayloadTPC(reqBody);
        dispatch(getTableData({
            reqBody,
            pageSize: pageSize,
            pageNum: onPage,
            tableName: tableNameTPC,
            controller
        }));
        
        return () => {
            controller.abort();
        };
    }, [search, onPage, carrieridTPC]);

    useEffect(() => {
        setShipmentsTPC(tableDataTPC.shipments);
        setLoadingTPC(isLoadingTPC);
        setTotalPagesTPC(tableDataTPC.totalPages);
        setTotalRecordsTPC(tableDataTPC.totalRecords);
        // setOnPage(tableData.onPage)
    }, [tableDataTPC]); 


    return (
        <Grid container className="pageContainer">
            <DetailsPageHeader
                buttonTitle={t("trailer_pool_compliance")}
                pageName={tableNameTPC}
                enableLanes={false}
            />
            <Grid item xs={6} >
                <div className='ratioLAC'>
                    {
                        loading ? "-" :
                            <>
                                <Typography variant='h2' className={tableDataTPC.compliancePercentage > tableDataTPC.complianceTargetPercentage ? 'good' : 'bad'}>{`${t('total_compliance')} ${tableDataTPC.compliancePercentage}%`}</Typography>
                                &nbsp; | &nbsp;
                                <Typography variant='h2' className='target'>{`${tableDataTPC.complianceTargetPercentage}% ${t('target')}`}</Typography>
                            </>
                    }
                </div>
            </Grid>
            <Grid item xs={5}>
                <div className='checkboxFilterLAC'>
                    <FormControlLabel
                        control={<CustomCheckBox
                            name={t("show_only_non_compliant")}
                            data-testid="lane_award_compliance_checkbox"
                            onChange={(e) => handleChange(e)}
                            checked={search.trailerPoolCompliant === "N"}
                        />}
                        label={<Typography
                            variant='h5'
                        >
                            {t("show_only_non_compliant")}
                        </Typography>}
                    />
                </div>
            </Grid >
            <Grid item xs={1} className='actionSectionPart'>
                <TrackNTraceHeader
                    refreshBtn={false}
                    tab={'performance'}
                    tableName={tableNameTPC}
                    reqPayload={reqPayload}
                    filtersMenu={false}
                />
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    tableName={tableNameTPC}
                    rowData={shipments}
                    columnOrder={COLUMN_TRAILER_POOL_COMPLIANCE}
                    loading={loading}
                    size={pageSize}
                    totalPages={totalPages}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    setOnPage={setOnPageTPC}
                />
            </Grid>
        </Grid>
    )
}

export default TrailerPoolCompliance