import { put, takeEvery, call } from 'redux-saga/effects';
import { setSummarySuccess, setSummaryfailure } from '../reducers/summarySlice';
import { fetchSummary } from '../../service/fetchSummary';


/**
 * worker saga upon being called by the watcher saga makes the appropriate API call
 * and set the response data for both success and failuer calls.
 */
export function* workerSummarySaga({ payload }) {
    try {
        const response = yield call(fetchSummary, [payload.reqBody, payload.type, payload.controller]);
        yield put(setSummarySuccess(response));
    } catch (error) {
        yield put(setSummaryfailure({
            type: payload.type,
            errorMessage: error.toString()
        }))
    }
}

/**
* watcher saga - looks for the respective action dispatch. 
* First argument in the take/takeLatest/takeEvery is the action type and 
* the second argument is the worker saga function.
*/
function* summarySaga() {
    yield takeEvery("summary/getSummary", workerSummarySaga);
}

export default summarySaga;