import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from "react-use";
import Box from "@mui/material/Box";
import Radio from '@mui/material/Radio';
import Popper from "@mui/material/Popper";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useSearchParams } from "react-router-dom";
import { isJsonParsable, globalFilters } from "../../helpers";
import qs from 'query-string';
import "./CustomAutoComplete.sass";

export default function CustomAutoComplete({ 
    label, radio, data, value, 
    queryStringKey 
}) {

    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filtered, setFiltered] = React.useState(() => data);
    const [selected, setSelected] = React.useState([]);
    const [searchString, setSearchString] = React.useState("");
    const [selectedRadio, setSelectedRadio] = React.useState(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState(() => Object.fromEntries([...searchParams]));
    const [ids, setIds] = React.useState([]);
    const [isFirstRender, setIsFirstRender] = React.useState(true);

    useEffect(() => {
        setSearch(Object.fromEntries([...searchParams]));
    }, [searchParams]);

    useEffect(() => {
        setSelectedRadio(value);
    }, [value]);

    React.useEffect(() => {
        var rgxp = new RegExp(searchString, "gi");
        if (searchString) {
            setFiltered(data.filter(({ label: inputLabel }) => inputLabel.match(rgxp)));
        } else {
            setFiltered(data);
        } 
    }, [searchString])

    const clearAll = () => {
        setSelected([]);
        setIds([]);
        setFiltered(data);
    };

    const selectItems = (item, unSelect = false) => {
        if (radio) {
            setIds([item.value])
            return setSelectedRadio(item.label);
        }

        // Unselect filter(s)
        if (unSelect) { 
            let newFiltered = [...filtered,
            {
                ...item,
                checked: false
            }]?.sort((a, b) => a?.id - b?.id)
            setFiltered(newFiltered);
            let tempids = [...ids];
            let index = tempids.indexOf(item.value)
            tempids.splice(index, 1)
            setIds([...tempids])
            setSelected(selected.filter(d => d?.label !== item?.label));
            return;
        }

        // Selection of filter(s)
        let newSelected = [...selected, {
            ...item,
            checked: true
        }]?.sort((a, b) => a.id - b.id);
        setIds([...ids, item.value]);
        setFiltered(filtered.filter(y => y?.label !== item?.label));
        setSelected(newSelected);
    };

    useDeepCompareEffect(() => {
        if (!isFirstRender) {
            setSearchParams(qs.stringify({
                ...globalFilters,
                ...search,
                [queryStringKey]: JSON.stringify(ids)
            }));
        }
    }, [ids, isFirstRender]);

    useDeepCompareEffect(() => {
        setSelected([]);
        setFiltered(data);
    }, [data]);

    useDeepCompareEffect(() => {
        const urlData = isJsonParsable(search[queryStringKey]) || [];
        setIsFirstRender(false);
        if (urlData.length === 1 && radio) {
            setSelectedRadio(urlData[0]);
        } else {
            const selectedArr = [];
            const restOf = [];
            let x = data?.map(d => {
                if (urlData.includes(d.value)) {
                    selectedArr.push({
                        ...d,
                        checked: true
                    })
                } else {
                    restOf.push(d)
                }
            });
            setIds(urlData)
            setSelected(selectedArr);   
            setFiltered(restOf);
        }
    }, [search[queryStringKey], isFirstRender, data]);

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? "spring-popper" : undefined;

    return (
        <div data-testid="custom_auto_complete">
            <ClickAwayListener
                onClickAway={() => {
                    if (open) {
                        setSearchString("")
                        handleClose()
                    }
                }}
            >
                <div>
                    <Button
                        aria-describedby={id}
                        className="autocompletebutton"
                        data-testid="ac-button"
                        size={"small"}
                        endIcon={open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        type="button"
                        onClick={handleClick}
                    >
                        {radio
                            ? <>
                                <Typography variant='h6'>{label}</Typography>
                                &nbsp;-&nbsp;
                                <Typography variant='h6' className='chipValueLabel'>{selectedRadio}</Typography>
                            </>
                            : selected.length === 1
                                ? <>
                                    <Typography>{label}</Typography>
                                    &nbsp;-&nbsp;
                                    <Typography variant='h6' className='chipValueLabel'>{selected[0]?.label}</Typography>
                                </>
                                : selected.length >= 1
                                    ? <>
                                        <Typography>{label}</Typography>
                                        &nbsp;-&nbsp;
                                        <Typography variant='h6' className='chipValueLabel'>{t('multi_select')}</Typography>
                                    </>
                                    : label
                        }
                    </Button>
                    <Popper
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        placement={"bottom-start"}
                        className="ac-popper"
                        transition
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps}>
                                <Box className="ac-container">
                                    <FormGroup>
                                        <TextField
                                            size="small"
                                            value={searchString}
                                            placeholder={`${t('search')} ${label}`}
                                            inputProps={{
                                                "data-testid": "text-outlined"
                                            }}
                                            onChange={(e) => setSearchString(e.target.value)}
                                        />
                                        {
                                            selected.length > 0 && <Box className="ac-innerbox-selected">
                                                {
                                                    selected.map((x, i) => {
                                                        return (
                                                            <FormControlLabel
                                                                key={i}
                                                                className="ac-form"
                                                                data-testid={`check-${x.id}`}
                                                                onChange={() => selectItems(x, true)}
                                                                value={x.value}
                                                                label={x.label}
                                                                control={
                                                                    <Checkbox
                                                                        checked={x.checked}
                                                                        defaultChecked
                                                                    />
                                                                }
                                                            />
                                                        )
                                                    })
                                                }
                                            </Box>
                                        }
                                        {
                                            !radio && <Typography
                                                onClick={clearAll}
                                                data-testid="clearall"
                                                className="clearall">{t("clear_all")}
                                            </Typography>
                                        }
                                        <Box className="ac-innerbox">
                                            {filtered?.map((d, i) => {
                                                return (
                                                    <FormControlLabel
                                                        className="ac-form"
                                                        data-testid={`check-${d.id}`}
                                                        onChange={() => selectItems(d)}
                                                        value={d.value}
                                                        label={d.label}
                                                        control={
                                                            radio
                                                                ? <Radio
                                                                    checked={d?.label === selectedRadio}
                                                                />
                                                                : <Checkbox
                                                                    checked={d.checked}
                                                                    defaultChecked
                                                                />
                                                        }
                                                    />
                                                );
                                            })}
                                        </Box>
                                    </FormGroup>
                                </Box>
                            </Fade>
                        )}
                    </Popper>
                </div>
            </ClickAwayListener>
        </div>
    );
}
