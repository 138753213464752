import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import CustomCardHeader from './CustomCardHeader';
import { useSelector } from 'react-redux';
import CardNoteComponent from './CardNoteComponent';
import ProgressChart from '../../charts/ProgressChart';
import './Card.sass';

const TrailerPoolComplianceCard = ({ carrierLoading }) => {

  const { t } = useTranslation();
  const pageName = 'trailerPoolCompliance';
  const [loading, setLoading] = useState(() => carrierLoading);
  const { summary: { trailerPoolCompliance: { data: { summaryResponse }, isLoading } } } = useSelector(state => state.chartSummary);

  useEffect(() => {
    setLoading(carrierLoading || isLoading);
  }, [carrierLoading, isLoading]);

  return (
    <div className='card5'>
      <CustomCardHeader
        title={t('trailer_pool_compliance')}
        pageName={pageName}
      />
      <div className='styleFKYCBar'>
        <ProgressChart
          value1={loading ? '-' : summaryResponse?.trailerPoolCompliancePercentage}
          value2={loading ? '-' : summaryResponse?.nonCompliancePercentage}
          loading={loading}
          value1Title={loading ? '-' : <Typography variant='h3' className='totalComplianceTitle'>{t('total_compliance')}</Typography>}
          value2Title={<div className='ratio'>
            <Typography variant='h1' className={summaryResponse?.trailerPoolCompliancePercentage > summaryResponse?.trailerPoolComplianceTargetPercentage ? 'good' : 'bad'}>{!summaryResponse?.trailerPoolCompliancePercentage ? '-' : `${summaryResponse?.trailerPoolCompliancePercentage}%`}</Typography>
            &nbsp; | &nbsp;
            <Typography variant='h1' className='target'>{!summaryResponse?.trailerPoolComplianceTargetPercentage ? '-' : `${summaryResponse?.trailerPoolComplianceTargetPercentage}% ${t('target')}`}</Typography>
          </div>
          }
          color={summaryResponse?.trailerPoolCompliancePercentage > summaryResponse?.trailerPoolComplianceTargetPercentage ? "primary" : "secondary"}
          noRail={true}
          gotoPath={pageName}
        />
      </div>
      {/* {!loading && !summaryResponse?.complianceTargetPercentage && <div className='buffer'></div>} */}
      <CardNoteComponent note={t('kpi_note_trailer_pool')} />
    </div>
  )
}

export default TrailerPoolComplianceCard