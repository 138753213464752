import Typography from "@mui/material/Typography";
import './columns.sass';

export const COLUMN_TRAILER_POOL_COMPLIANCE = [
    {
        Header: 'date',
        accessor: "date",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    },
    {
        Header: 'origin_location',
        accessor: "originLocation",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    },
    {
        Header: 'site_number',
        accessor: "siteNum",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    },
    {
        Header: 'service',
        accessor: "tariffServiceCode",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    },
    {
        Header: 'target_pool',
        accessor: "trailerPoolTarget",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    },
    {
        Header: 'actual',
        accessor: "trailerPoolActual",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    },
    {
        Header: 'target_delta',
        accessor: "targetDelta",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    },
    {
        Header: 'daily_compliance_percentage',
        accessor: "trailerPoolCompliancePercentage",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={(props.row.values?.trailerPoolCompliancePercentage >= props.row.original?.complianceTargetPercentage)
                ? "complianceGood"
                : "complianceBad"
            }>
                {props.value === '-' ? '-' : `${props.value}%`}
            </Typography>
        }
    },
    {
        Header: 'out_service_trailers',
        accessor: "outOfServiceTrailers",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    }
]