import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import CustomCheckBox from '../common/CustomCheckBox';
import { isJsonParsable } from '../../helpers/index';
import { useEffect } from 'react';

const CheckboxWrapperComponent = (props) => {

    const { t } = useTranslation();
    const { filterKey, tableName } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState(() => Object.fromEntries([...searchParams]));
    const [values, setValues] = React.useState([]);
    const [filter, setFilter] = React.useState(() => props.filter);

    const { [tableName]: { filters } } = useSelector(state => state?.filterSelected);

    useEffect(() => {
        const params = Object.fromEntries([...searchParams]);
        setSearch(params);
        const tempValues = isJsonParsable(params[filterKey]) || [];
        setValues(tempValues);
        setFilter(filter.map(d => ({ ...d, checked: tempValues.includes(d.value) })));
    }, [searchParams])

    const handleFilter = ({ item, event }) => {
        let filterPayload = [];
        const checked = event.target.checked;
        const updatedobject = filter.map(d => {
            if (d.value === item.value) {
                return { ...d, checked }
            } else {
                return d;
            }
        });
        setFilter(updatedobject);

        updatedobject.forEach(itm => {
            if (itm.checked) {
                filterPayload.push(itm.value)
            }
        });
        setValues(filterPayload);
        setSearchParams(qs.stringify({
            ...search,
            [filterKey]: JSON.stringify(filterPayload)
        }))
    }

    return (
        <FormControl>
            <FormGroup>
                {
                    filters[filterKey]?.data?.map((d, idx) => {
                        return (<FormControlLabel
                            key={idx}
                            control={<CustomCheckBox name={d.value} onChange={(e) => handleFilter({ item: d, event: e })} checked={values.includes(d.value)} />}
                            label={<Typography
                                variant='body1'
                                className="lableStyles"
                            >
                                {t(d.name)}
                            </Typography>}
                        />)
                    })
                }
            </FormGroup>
        </FormControl>
    )
}

export default CheckboxWrapperComponent