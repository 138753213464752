export const filtersOnTime = {
    shipmentNumList: {
        type: "text",
        name: "load_id",
        stringToArray: true,
        data: null
    },
    trailerNumList: {
        type: "text",
        name: "trailer_number",
        stringToArray: true,
        data: null
    },
    liveLoadIndList: {
        type: "checkbox", 
        name: "live_load",
        data: [
            { name: 'yes', value: 'Y', checked: false },
            { name: 'no', value: 'N', checked: false },
            { name: 'blank', value: '-', checked: false }
        ]
    },
    pickupApptDateTimeOriginTZ: {
        type: "date",
        name: "pickup_appt_date",
        dummytime: true,
        startTime: "00:00:00.000",
        endTime: "23:59:59.999",
        data: null
    },    
    // pickupOnTime: {
    //     type: "checkbox", 
    //     name: "pickup_on_time",
    //     data: [
    //         { name: 'yes', value: 'Y', checked: false },
    //         { name: 'no', value: 'N', checked: false },
    //         { name: 'blank', value: '-', checked: false }
    //     ]
    // },
    deliveryApptDateTimeDestTZ: {
        type: "date",
        name: "delivery_appt_date",
        dummytime: true,
        startTime: "00:00:00.000",
        endTime: "23:59:59.999",
        data: null
    },
    equipmentTypeList: {
        type: "text",
        name: "equip_type",
        stringToArray: true,
        data: null
    },
    // deliveryOnTime: {
    //     type: "checkbox", 
    //     name: "delivery_on_time",
    //     data: [
    //         { name: 'yes', value: 'Y', checked: false },
    //         { name: 'no', value: 'N', checked: false },
    //         { name: 'blank', value: '-', checked: false }
    //     ]
    // },
    originLocationNameList: {
        type: "text",
        name: "origin_location_name",
        stringToArray: true,
        data: null
    },
    originCityList: {
        type: "text",
        name: "origin_city",
        stringToArray: true,
        data: null
    },
    originStateList: {
        type: "text",
        name: "origin_state",
        stringToArray: true,
        data: null
    },
    originCountryList: {
        type: "text",
        name: "origin_country",
        stringToArray: true,
        data: null
    },
    destLocationNameList: {
        type: "text",
        name: "destination_location_name",
        stringToArray: true,
        data: null
    },
    destCityList: {
        type: "text",
        name: "destination_city",
        stringToArray: true,
        data: null
    },
    destStateList: {
        type: "text",
        name: "destination_state",
        stringToArray: true,
        data: null
    },
    destCountryList: {
        type: "text",
        name: "destination_country",
        stringToArray: true,
        data: null
    },    
    cpShipmentTypeList: {
        type: "checkbox", 
        name: "cp_shipment_type",
        data: [
            { name: 'sto', value: 'STO', checked: false },
            { name: 'cust', value: 'CUST', checked: false },
            { name: 'vendor_inbound', value: 'VENDOR_INBOUND', checked: false }
        ]
    }, 
}
