import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { useOktaAuth } from "@okta/okta-react";
import { OktaAuth } from '@okta/okta-auth-js';
import Box from '@mui/material/Box';
import OktaConfig from '../OktaConfig';
import Header from './Header';
import axios from '../helpers/axioxInstance';
import { getUserInfo } from '../redux/reducers/userInfoSlice';
import './Dashboard.sass';
import userGroupConfig from '../userGroupConfig';

const Dashboard = () => {
    const useOkta = useOktaAuth();
    const oktaAuth = new OktaAuth(OktaConfig);
    const location = useLocation();
    const dispatch = useDispatch();

    const [user, setUser] = useState("");
    const [userID, setUserID] = useState("");

    const sessionData = JSON.parse(sessionStorage?.getItem('okta-token-storage'));
    const idToken = sessionData?.idToken?.idToken;

    axios.interceptors.request.use(request => {
        request.headers['Authorization'] = `Bearer ${idToken}`
        return request;
    });

    useEffect(() => {
        if (!sessionData) {
            oktaAuth.setOriginalUri(`${location?.pathname}${location?.search}`)
            oktaAuth.token.getWithRedirect({
                responseType: 'id_token',
                state: `${location?.pathname}${location?.search}`
            })
        }
    }, [sessionData])

    useEffect(() => {
        async function authenticate() {
            if (!useOkta?.authState?.idToken) {
                await useOkta?.oktaAuth?.signInWithRedirect();
            }
        }

        if (useOkta?.authState?.idToken) {
            const { idToken: { claims: { name, UserID, Roles, email } } } = useOkta?.authState;
            const userGroupKeys = Object.keys(userGroupConfig);
            setUser(name);
            setUserID(UserID);
            dispatch(getUserInfo({
                userName: name,
                userID: UserID,
                role: Roles.find(item => userGroupKeys.includes(item))
            }));
            window.FS.identify(UserID, {
                displayName: name,
                email
            });

        }
        authenticate();
    }, [useOkta?.authState, useOkta?.oktaAuth]);

    useEffect(() => {
        oktaAuth.setOriginalUri(`${location?.pathname}${location?.search}`)
    }, [location?.search]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (Date.now() > new Date(sessionData?.idToken?.expiresAt * 1000)) {
                oktaAuth.token.getWithRedirect({
                    responseType: 'id_token',
                    state: `${location?.pathname}${location?.search}`
                })
            }
        }
            , 60000);
        return () => clearInterval(timer);
    }, []);

    return (
        <div data-testid="dashboard">
            {useOkta?.authState?.idToken &&
                <>
                    <Header authState={useOkta?.authState} oktaAuth={useOkta?.oktaAuth} user={user} userID={userID} />
                    <Box className='main' />
                    <Outlet />
                </>
            }
        </div>
    )
}

export default Dashboard