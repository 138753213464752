import React, { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import qs from 'query-string';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import CustomDatePicker from '../common/CustomDatePicker';
import { isJsonParsable } from '../../helpers';
import './DatePickerWrapperComponent.sass';

const DatePickerWrapperComponent = (props) => {

  const { t } = useTranslation();
  const { filterKey, dateFilter } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const [startTime, setStartDate] = React.useState(null);
  const [endTime, setEndDate] = React.useState(null);
  const [search, setSearch] = React.useState(() => Object.fromEntries([...searchParams]));

  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);
    setSearch(params);
    const tempValues = isJsonParsable(params[filterKey]) || {};
    setStartDate(tempValues.startTime);
    setEndDate(tempValues.endTime);
  }, [searchParams]);

  const handleStartDate = (newValue) => {
    let date = null;
    date = dateFilter.dummytime && moment(newValue, "YYYY-MM-DD").format(`YYYY-MM-DD ${dateFilter.startTime}`)
    setStartDate(date);
    if (date && endTime) {
      setSearchParams(qs.stringify({
        ...search,
        [filterKey]: JSON.stringify({ startTime: date, endTime })
      }))
    }
  };

  const handleEndDate = (newValue) => {
    let date = null;
    date = dateFilter.dummytime && moment(newValue, "YYYY-MM-DD").format(`YYYY-MM-DD ${dateFilter.endTime}`);
    setEndDate(date);
    if (date && startTime) {
      setSearchParams(qs.stringify({
        ...search,
        [filterKey]: JSON.stringify({ startTime, endTime: date })
      }))
    }
  }

  return (
    <div className='containerBox'>
      <CustomDatePicker
        label={t('start_date')}
        onChange={handleStartDate}
        value={startTime}
        maxDate={endTime}
      />
      <Typography variant="h5" className="text">{t('to')}</Typography>
      <CustomDatePicker
        value={endTime}
        onChange={handleEndDate}
        label={t('end_date')}
        minDate={startTime}
      />
    </div>
  )
}

export default DatePickerWrapperComponent