import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import ProgressChart from '../../charts/ProgressChart';
import CardNoteComponent from './CardNoteComponent';
import './Card.sass';

const BillingAccuracyCard = ({ carrierLoading }) => {
    const { t } = useTranslation();
    const pageName = 'billingAccuracy';
    const [loading, setLoading] = useState(() => carrierLoading);
    const { summary: { billingAccuracy: { data: { summaryResponse }, isLoading } } } = useSelector(state => state.chartSummary);

    useEffect(() => {
        setLoading(carrierLoading || isLoading);
    }, [carrierLoading, isLoading]);
    
    return (
        <div className='card5'>
            <div className='titleBox'>
                <Typography variant='h2' className='titleStyle'>{t("billing_accuracy")}</Typography>
            </div>
            <div className='subTitle'>
                <Typography variant='h4'>{`${t('total_invoices')}: ${loading || !summaryResponse?.totalInvoices ? "-" : summaryResponse?.totalInvoices}`}</Typography>
            </div>
            <div className='styleBABar'>
                <ProgressChart
                    value1={loading ? '-' : summaryResponse?.approvedInvoices} // greenCount
                    value2={loading ? '-' : summaryResponse?.rejectedInvoices} // redCount
                    loading={loading}
                    value1Title={loading ? '-' : <Typography variant='h3'>{`${!summaryResponse?.approvedInvoices ? '-' : summaryResponse?.approvedInvoices} ${t('invoices')} ${!summaryResponse?.invoiceStatus ? '-' : t(summaryResponse?.invoiceStatus)}`}</Typography>}
                    value2Title={<>
                        <div className='ratio'>
                            <Typography variant='h1' className={summaryResponse?.compliancePercentage > summaryResponse?.targetPercentage ? 'good' : 'bad'}>{!summaryResponse?.compliancePercentage ? '-' : `${summaryResponse?.compliancePercentage}%`}</Typography>
                            &nbsp; | &nbsp;
                            <Typography variant='h1' className='target'>{!summaryResponse?.targetPercentage ? '-' : `${summaryResponse?.targetPercentage}% ${t('target')}`}</Typography>
                        </div>
                    </>}
                    color={summaryResponse?.compliancePercentage > summaryResponse?.targetPercentage ? "primary" : "secondary"}
                    noRail={true}
                    gotoPath={pageName}
                />
            </div>
            <CardNoteComponent note={t('kpi_invoice_paid_date')} />
            <Typography variant='h6' className='noteTextBAC'>{t('kpi_note_billing')}</Typography>
        </div>

    )
}

export default BillingAccuracyCard;