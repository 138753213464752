import { put, takeLatest, call, take } from 'redux-saga/effects';
import moment from "moment-timezone";
import { setCSVDataSuccess, setCSVDataFailure } from '../reducers/csvDataSlice';
import { fetchCSVData } from '../../service/fetchCSVData';
import { ENUM_DATA } from '../../constData/enum';
import { browserTimezone, getEnumValue, toHoursAndMinutes } from '../../helpers';

/**
 * worker saga upon being called by the watcher saga makes the appropriate API call
 * and set the response data for both success and failuer calls.
 */

export function* workerGetCSVDataFetch({ payload }) {
    const tableWithDetails = ['trackNtrace', 'onTimePickup', 'onTimeDelivery'];
    try {
        const response = yield call(fetchCSVData, [payload.reqBody, payload.totalPages, payload.tableName]);
        const data = response;
        let parsedShipmentValues = [];

        if (tableWithDetails.includes(payload.tableName)) {
            let x = data?.shipments?.length && data?.shipments.forEach(itemCsvData => {
                if (itemCsvData?.stops?.length) {
                    let parsedPendingTask = [];
                    let comments = [];
                    let p = itemCsvData?.shipmentAttributes?.forEach(pt => {
                        let val = getEnumValue(pt, ENUM_DATA);
                        parsedPendingTask.push(val);
                    })
                    let c = itemCsvData?.commentResponse?.comments?.forEach(com => {
                        let commentDateTime = com?.createdAt ? moment(com.createdAt).tz(browserTimezone).format(`MMM DD YYYY HH:mm z`) : '-';
                        let val = `${commentDateTime} by ${com?.createdByUserFirstAndLastName}(${com?.createdByUserId}) : ${com?.commentText}`;
                        comments.push(val);
                    })

                    let obj = {
                        shipmentNum: itemCsvData?.shipmentNum ? itemCsvData?.shipmentNum : '-',
                        shipmentAttributesParsed: itemCsvData?.shipmentAttributes?.length ? parsedPendingTask : '-',
                        commentsParsed: itemCsvData?.commentResponse?.comments?.length ? comments : '-',
                        minutesLeftForCarrierToProvideActualsFormatted: itemCsvData.minutesLeftForCarrierToProvideActuals ? toHoursAndMinutes(itemCsvData.minutesLeftForCarrierToProvideActuals) : '-',
                        tariffServiceCode: itemCsvData?.tariffServiceCode ? itemCsvData?.tariffServiceCode : '-',
                        tmsShipmentStatus: itemCsvData?.tmsShipmentStatus ? itemCsvData?.tmsShipmentStatus : '-',
                        orderExecutionBucketDesc: itemCsvData?.orderExecutionBucketDesc ? itemCsvData?.orderExecutionBucketDesc : '-',
                        trailerNum: itemCsvData?.trailerNum ? itemCsvData?.trailerNum : '-',
                        originName: itemCsvData?.origin?.name ? itemCsvData?.origin?.name : '-',
                        originCity: itemCsvData?.origin?.city ? itemCsvData?.origin?.city : '-',
                        originState: itemCsvData?.origin?.state ? itemCsvData?.origin?.state : '-',
                        originStreet: itemCsvData?.origin?.street ? itemCsvData?.origin?.street : '-',
                        originCountry: itemCsvData?.origin?.country ? itemCsvData?.origin?.country : '-',
                        // carrierId: itemCsvData?.carrierId ? itemCsvData?.carrierId : '-',
                        carrierCode: itemCsvData?.carrierCode ? itemCsvData?.carrierCode : '-',
                        carrierDesc: itemCsvData?.carrierDesc ? itemCsvData?.carrierDesc : '-',
                        tariffServiceCode: itemCsvData?.tariffServiceCode ? itemCsvData?.tariffServiceCode : '-',
                        equipmentType: itemCsvData?.equipmentType ? itemCsvData?.equipmentType : '-',
                        liveLoadInd: itemCsvData?.liveLoadInd ? itemCsvData.liveLoadInd : '-',
                        cpShipmentType: itemCsvData?.cpShipmentType ? itemCsvData.cpShipmentType : '-'
                    }

                    let y = itemCsvData?.stops.forEach(el => {
                        if (el?.stopType === 'delivery') {
                            let val = {
                                ...obj,
                                stopNum: el?.stopNum ? el.stopNum : '-',
                                pickupApptDateTimeFormatted: '-',
                                deliveryApptDateTimeFormatted: el?.apptDateTime && el?.location?.timezone ? moment(el?.apptDateTime).tz(el?.location?.timezone).format(`MMM DD YYYY HH:mm z`) : '-',
                                pickupOnTime: '-',
                                deliveryOnTime: el?.arrivalOnTime ? el?.arrivalOnTime : '-',
                                pickupReasonCodeFinal: '-',
                                deliveryReasonCodeFinal: el?.actualArrivalReasonCodeDescFinal ? el?.actualArrivalReasonCodeDescFinal : '-',
                                destLocationName: el?.location?.name ? el?.location?.name : '-',
                                destCity: el?.location?.city ? el?.location?.city : '-',
                                destState: el?.location?.state ? el?.location?.state : '-',
                                destStreet: el?.location?.street ? el?.location?.street : '-',
                                destCountry: el?.location?.country ? el?.location?.country : '-',
                                pickupLocationID: itemCsvData?.stops[0]?.location?.id ? itemCsvData?.stops[0]?.location?.id : '-',
                                dropLocationID: el?.location?.id ? el?.location?.id : '-',
                                baselinePickupApptDateTime: '-',
                                baselineDeliveryApptDateTime: el?.baseLineApptDateTime && el?.location?.timezone ? moment(el.baseLineApptDateTime).tz(el?.location?.timezone).format(`MMM DD YYYY HH:mm z`) : '-',
                                actualPickupApptDateTime: '-',
                                actualDeliveryApptDateTime: el?.location?.timezone && ((el?.actualArrivalTimestampCarrier && el?.actualArrivalTimestampKC) || (el?.actualArrivalTimestampCarrier && !el?.actualArrivalTimestampKC))
                                    ? moment(el.actualArrivalTimestampCarrier).tz(el?.location?.timezone).format(`MMM DD YYYY HH:mm z`)
                                    : !el.actualArrivalTimestampCarrier && el.actualArrivalTimestampKC && el?.location?.timezone
                                        ? moment(el.actualArrivalTimestampKC).tz(el?.location?.timezone).format(`MMM DD YYYY HH:mm z`)
                                        : '-',
                                // minutesLeftForCarrierToProvideActualsFormatted: el.minutesLeftForCarrierToProvideActuals || el.minutesLeftForCarrierToProvideActuals === 0 ? toHoursAndMinutes(el.minutesLeftForCarrierToProvideActuals) : '-'
                            }
                            parsedShipmentValues.push(val);
                        } else {
                            let val = {
                                ...obj,
                                stopNum: el?.stopNum ? el.stopNum : '-',
                                pickupApptDateTimeFormatted: el?.apptDateTime && el?.location?.timezone ? moment(el?.apptDateTime).tz(el?.location?.timezone).format(`MMM DD YYYY HH:mm z`) : '-',
                                deliveryApptDateTimeFormatted: '-',
                                pickupReasonCodeFinal: el?.actualArrivalReasonCodeDescFinal ? el?.actualArrivalReasonCodeDescFinal : '-',
                                deliveryReasonCodeFinal: '-',
                                pickupOnTime: el?.arrivalOnTime ? el?.arrivalOnTime : '-',
                                deliveryOnTime: '-',
                                pickupLocationID: el?.location?.id ? el?.location?.id : '-',
                                dropLocationID: itemCsvData?.stops[itemCsvData?.stops.length - 1].location.id ? itemCsvData?.stops[itemCsvData?.stops.length - 1].location.id : '-',
                                destLocationName: itemCsvData?.finalDestination?.name ? itemCsvData?.finalDestination?.name : '-',
                                destCity: itemCsvData?.finalDestination?.city ? itemCsvData?.finalDestination?.city : '-',
                                destState: itemCsvData?.finalDestination?.state ? itemCsvData?.finalDestination?.state : '-',
                                destStreet: itemCsvData?.finalDestination?.street ? itemCsvData?.finalDestination?.street : '-',
                                destCountry: itemCsvData?.finalDestination?.country ? itemCsvData?.finalDestination?.country : '-',
                                baselinePickupApptDateTime: el?.baseLineApptDateTime && el?.location?.timezone ? moment(el.baseLineApptDateTime).tz(el?.location?.timezone).format(`MMM DD YYYY HH:mm z`) : '-',
                                baselineDeliveryApptDateTime: '-',
                                actualPickupApptDateTime: el?.location?.timezone && ((el?.actualArrivalTimestampCarrier && el?.actualArrivalTimestampKC) || (el?.actualArrivalTimestampCarrier && !el?.actualArrivalTimestampKC))
                                    ? moment(el.actualArrivalTimestampCarrier).tz(el?.location?.timezone).format(`MMM DD YYYY HH:mm z`)
                                    : !el.actualArrivalTimestampCarrier && el.actualArrivalTimestampKC && el?.location?.timezone
                                        ? moment(el.actualArrivalTimestampKC).tz(el?.location?.timezone).format(`MMM DD YYYY HH:mm z`)
                                        : '-',
                                actualDeliveryApptDateTime: '-',
                                // minutesLeftForCarrierToProvideActualsFormatted: el.minutesLeftForCarrierToProvideActuals || el.minutesLeftForCarrierToProvideActuals === 0 ? toHoursAndMinutes(el.minutesLeftForCarrierToProvideActuals) : '-'
                            }
                            parsedShipmentValues.push(val);
                        }
                    })
                }
            })
        } else if (payload.tableName === 'firstTenderCompliance') {
            let x = data?.shipments?.length && data?.shipments.forEach(itemCsvData => {
                let obj = {
                    ...itemCsvData,
                    shipmentNum: itemCsvData?.shipmentNum ? itemCsvData?.shipmentNum : '-',
                    carrierId: itemCsvData?.carrierId ? itemCsvData?.carrierId : '-',
                    carrierCode: itemCsvData?.carrierCode ? itemCsvData?.carrierCode : '-',
                    carrierDesc: itemCsvData?.carrierDesc ? itemCsvData?.carrierDesc : '-',
                    tariffServiceCode: itemCsvData?.tariffServiceCode ? itemCsvData?.tariffServiceCode : '-',
                    tariffServiceCodeDesc: itemCsvData?.tariffServiceCodeDesc ? itemCsvData?.tariffServiceCodeDesc : '-',
                    equipmentType: itemCsvData?.equipmentType ? itemCsvData?.equipmentType : '-',
                    trailerNum: itemCsvData?.trailerNum ? itemCsvData?.trailerNum : '-',
                    pickupApptDateTimeFormatted: itemCsvData?.pickupApptDateTime && itemCsvData?.origin?.timezone ? moment(itemCsvData?.pickupApptDateTime).tz(itemCsvData?.origin?.timezone).format(`MMM DD YYYY HH:mm z`) : '-',
                    deliveryApptDateTimeFormatted: itemCsvData?.finalDeliveryApptDateTime && itemCsvData?.finalDestination?.timezone ? moment(itemCsvData?.finalDeliveryApptDateTime).tz(itemCsvData?.finalDestination?.timezone).format(`MMM DD YYYY HH:mm z`) : '-',
                    originName: itemCsvData?.origin?.name ? itemCsvData?.origin?.name : '-',
                    originStreet: itemCsvData?.origin?.street ? itemCsvData?.origin?.street : '-',
                    originCity: itemCsvData?.origin?.city ? itemCsvData?.origin?.city : '-',
                    originState: itemCsvData?.origin?.state ? itemCsvData?.origin?.state : '-',
                    originCountry: itemCsvData?.origin?.country ? itemCsvData?.origin?.country : '-',
                    destLocationName: itemCsvData?.finalDestination?.name ? itemCsvData?.finalDestination?.name : '-',
                    destStreet: itemCsvData?.finalDestination?.street ? itemCsvData?.finalDestination?.street : '-',
                    destCity: itemCsvData?.finalDestination?.city ? itemCsvData?.finalDestination?.city : '-',
                    destState: itemCsvData?.finalDestination?.state ? itemCsvData?.finalDestination?.state : '-',
                    destCountry: itemCsvData?.finalDestination?.country ? itemCsvData?.finalDestination?.country : '-',
                    liveLoadInd: itemCsvData?.liveLoadInd ? itemCsvData.liveLoadInd : '-',
                }
                parsedShipmentValues.push(obj);
            })
        } else if (payload.tableName === 'laneAwardCompliance') {
            let x = data?.shipments?.length && data?.shipments.forEach(itemCsvData => {
                let startDate = itemCsvData?.startDate ? itemCsvData.startDate : '-';
                let endDate = itemCsvData?.endDate ? itemCsvData.endDate : '-';

                let obj = {
                    ...itemCsvData,
                    week: `${startDate} - ${endDate}`,
                    lane: itemCsvData?.lane ? itemCsvData.lane : '-',
                    tariffServiceCode: itemCsvData?.tariffServiceCode ? itemCsvData.tariffServiceCode : '-',
                    awardedLoads: itemCsvData?.awardedLoads || itemCsvData?.awardedLoads === 0 ? itemCsvData.awardedLoads : '-',
                    tenderedLoads: itemCsvData?.tenderedLoads || itemCsvData?.tenderedLoads === 0 ? itemCsvData.tenderedLoads : '-',
                    tenderAcceptedInContract: itemCsvData?.tenderAcceptedInContract || itemCsvData?.tenderAcceptedInContract === 0 ? itemCsvData.tenderAcceptedInContract : '-',
                    compliancePercentage: itemCsvData?.compliancePercentage || itemCsvData?.compliancePercentage === 0 ? `${itemCsvData.compliancePercentage}%` : '-',
                    tenderAcceptedOverCompliancePercentage: itemCsvData.tenderAcceptedOverCompliancePercentage || itemCsvData.tenderAcceptedOverCompliancePercentage === 0 ? `${itemCsvData.tenderAcceptedOverCompliancePercentage}%` : '-',
                    tenderAcceptedInSpot: itemCsvData?.tenderAcceptedInSpot || itemCsvData?.tenderAcceptedInSpot === 0 ? itemCsvData.tenderAcceptedInSpot : '-',
                }
                parsedShipmentValues.push(obj);
            })
        } else {
            let x = data?.shipments?.length && data?.shipments.forEach(itemCsvData => {
                let obj = {
                    ...itemCsvData,
                    date: itemCsvData?.trailerPoolComplianceDate ? moment(itemCsvData.trailerPoolComplianceDate).format('DD-MMM-YYYY') : '-',
                    tariffServiceCode: itemCsvData?.tariffServiceCode ? itemCsvData.tariffServiceCode : '-',
                    carrierCode: itemCsvData?.carrierCode ? itemCsvData.carrierCode : '-',
                    siteNum: itemCsvData?.origin?.id ? itemCsvData.origin.id : '-',
                    originLocation: itemCsvData?.origin?.cityState ? itemCsvData.origin.cityState : '-',
                    trailerPoolTarget: itemCsvData?.trailerPoolTarget || itemCsvData?.trailerPoolTarget === 0 ? itemCsvData.trailerPoolTarget : '-',
                    trailerPoolActual: itemCsvData?.trailerPoolActual || itemCsvData?.trailerPoolActual === 0 ? itemCsvData.trailerPoolActual : '-',
                    targetDelta: itemCsvData?.targetDelta || itemCsvData?.targetDelta === 0 ? itemCsvData.targetDelta : '-',
                    trailerPoolCompliancePercentage: itemCsvData?.trailerPoolCompliancePercentage || itemCsvData?.trailerPoolCompliancePercentage === 0 ? `${itemCsvData.trailerPoolCompliancePercentage}%` : '-',
                    outOfServiceTrailers: itemCsvData?.outOfServiceTrailers || itemCsvData?.outOfServiceTrailers === 0 ? itemCsvData.outOfServiceTrailers : '-',
                }
                parsedShipmentValues.push(obj);
            })
        }
       
        yield put(setCSVDataSuccess({ ...data, shipments: parsedShipmentValues }));
        
    } catch (error) {
        yield put(setCSVDataFailure(error.toString()));
    }
}

/**
* watcher saga - looks for the respective action dispatch. 
* First argument in the take/takeLatest/takeEvery is the action type and 
* the second argument is the worker saga function.
*/
function* csvDataSaga() {
    yield takeLatest("csvData/getCSVData", workerGetCSVDataFetch);
}

export default csvDataSaga;