export const filterRequestPyaloadFirstTender = {
    "searchType": "CARRIER_PORTAL_ARCHIVE_PERFORMANCE_FIRST_TENDER_COMPLIANCE", // send always
    "returnOnlyTmsPlannedShipments": true,      // send always
    "excludeEquipmentTypeList": ["PKG", "LTL"],   // send always
    "originRegion": "NA", // send always
    "shipmentNumList": [], // Load # search 
    "tenderedCarrierCodeList": [], // Carrier ID
    "equipmentTypeList": [], // Equip Type 
    "pickupApptDateTimeOriginTZ": null,
    // "pickupOnTime": [],
    // "deliveryApptDateTimeDestTZ": null,
    // "deliveryOnTime": [],
    "originLocationNameList": [], // Origin Location name
    "originCityList": [], // Origin city 
    "originStateList": [], // Origin State
    "originCountryList": [], // Origin Country
    "destLocationNameList": [], // Dest Location name
    "destCityList": [], // Dest city 
    "destStateList": [], // Dest State
    "destCountryList": [], // Dest Country
    "liveLoadIndList": [],  // Optional , filter has to be mutiple list "Yes" , "No" ;  "Yes" will corresponds to "Y" , "No" will corresponds to "N"
    "cpShipmentTypeList": [], // Order Type 
    "tenderStatusList": [
        "TENDER_ACCEPTED",
        "TENDER_REJECTED",
        "TENDER_NO_RESPONSE"
    ], // Tender Status
    "tenderedToPrimaryList": [], // null when nothing selected (awarded non awarded) 
    "orderBy": [
        {
            "key": "shipmentPickupApptDatetime",
            "ordering": "ASC"
        },
        {
            "key": "shipmentNum",
            "ordering": "ASC"
        }
    ]
}


export const optionalFilterPayloadFTC = {
    "shipmentNumList": [], // Load # search 
    "tenderedCarrierCodeList": [], // Carrier ID
    "equipmentTypeList": [], // Equip Type 
    "pickupApptDateTimeOriginTZ": null,
    // "pickupOnTime": [],
    // "deliveryApptDateTimeDestTZ": null,
    // "deliveryOnTime": [],
    "originLocationNameList": [], // Origin Location name
    "originCityList": [], // Origin city 
    "originStateList": [], // Origin State
    "originCountryList": [], // Origin Country
    "destLocationNameList": [], // Dest Location name
    "destCityList": [], // Dest city 
    "destStateList": [], // Dest State
    "destCountryList": [], // Dest Country
    // "liveLoadIndList": [],  // Optional , filter has to be mutiple list "Yes" , "No" ;  "Yes" will corresponds to "Y" , "No" will corresponds to "N"
    "cpShipmentTypeList": [], // orderType 
    "tenderStatusList": [
        // "TENDER_ACCEPTED",
        // "TENDER_REJECTED",
        // "TENDER_NO_RESPONSE"
    ],
}

export const defaultFilterPayloadFTC = {
    "searchType": "CARRIER_PORTAL_ARCHIVE_PERFORMANCE_FIRST_TENDER_COMPLIANCE", // send always
    "returnOnlyTmsPlannedShipments": true,      // send always
    "excludeEquipmentTypeList": ["PKG", "LTL"],   // send always
    "originRegion": "NA", // send always
    "orderBy": [
        {
            "key": "tenderResponseShipmentPickupApptDatetime",
            "ordering": "ASC"
        },
        {
            "key": "tenderResponseTablePk",
            "ordering": "ASC"
        }
    ] // send always in archive api 
}