import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import StopEvent from './StopEvent'
import './StopEvents.sass'

const StopEvents = ({ eventsData, setUpdated, shouldEdit, userGroup, errors, setErrors, ...props }) => {

  const [edit, setEdit] = useState(() => props.edit || false);
  const [eventdata, setEventData] = React.useState({});
  const { saved } = useSelector(({ edit: { saved } }) => ({ saved }));

  useEffect(() => {
    setEdit(props.edit);
    if (!props.edit) setEventData({});
  }, [props.edit])

  useEffect(() => {
    if (saved?.completelyFailed === false) {
      setEventData({});
    }
  }, [saved?.completelyFailed]);

  return (
    <div className='eventsContainer'>
      {
        eventsData?.map((event, index) => {
          return <StopEvent
            key={index}
            event={event}
            index={index}
            edit={edit}
            setUpdated={setUpdated}
            eventdata={eventdata}
            setEventData={setEventData}
            shouldEdit={shouldEdit}
            userGroup={userGroup}
            errors={errors}
            setErrors={setErrors}
          />
        })
      }
    </div>
  )
}

export default StopEvents