import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CustomButton from '../common/CustomButton';
import './custom.sass';

const CarrierSelectionDialog = (props) => {

    const { openSelectCarrier, setOpenSelectCarrier } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();

    const { carrierServiceMetadata } = useSelector(state => state?.carrierMetadata);
    const { carrierInfo } = useSelector(state => state?.carrier);

    const [value, setValue] = useState(() => params.carrierid);
    const [searchList, setSearchList] = useState([]);
    const [carrierList, setCarrierList] = useState([]);

    useEffect(() => {
        setCarrierList(carrierServiceMetadata);
    }, [carrierServiceMetadata])

    const handleSearch = (event) => {
        let list = event.target.value !== '' && carrierList.filter(item => item?.carrierCode.toLowerCase().match(event.target.value.toLowerCase()));
        setSearchList(list);
    }
    const handleContinue = () => {

        navigate({
            ...location,
            pathname: `/dashboard/performance/${value}`,
          });

        setOpenSelectCarrier({
            open: false
        });
    }

    useEffect(() => {
        if (searchList.length) {
            setCarrierList(searchList);
        } else {
            setCarrierList(carrierServiceMetadata);
        }
    }, [searchList]);

    return (
        <Dialog
            open={openSelectCarrier.open}
            fullWidth
            maxWidth="xs"
            classes={{
                scrollPaper: "topScrollPaper",
                paperScrollBody: "topPaperScrollBody"
            }}
            data-testid="select-carrier-dialog"
        >
            <DialogTitle>
                <Typography variant='h2'>{t('carrier_id')}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div>
                        <TextField
                            variant="outlined"
                            onChange={(e) => handleSearch(e)}
                            fullWidth
                            data-testid='search-carrier'
                        />
                    </div>
                    <div className='radioGroup'>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="radio-buttons-group-label"
                                name="radio-buttons-group"
                                data-testid="radio-group"
                                defaultValue={value}
                                onChange={(event) => setValue(event.target.value)}
                            >
                                {
                                    carrierList.map(item => {
                                        return <FormControlLabel
                                            data-testid={item?.carrierCode}
                                            value={item?.carrierCode}
                                            control={<Radio />}
                                            label={item?.carrierCode}
                                        />
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButton
                    className="commentButton"
                    btnType="secondary"
                    onClick={() => handleContinue()}
                    datatestid={'continue-dialog-button'}
                    disabled={value ? false : true}
                >
                    {t('continue')}
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default CarrierSelectionDialog