export const ENUM_DATA = {
    // Pending Task - translation
    "SHIPMENT_MISSING_PICK_STOP_ARRIVE": "arrive_at_pickup",
    "SHIPMENT_MISSING_PICK_STOP_DEPART": "depart_from_pickup",
    "SHIPMENT_MISSING_DROP_STOP_ARRIVE": "arrive_at_drop",
    "SHIPMENT_MISSING_DROP_STOP_DEPART": "depart_from_drop",

    // Chips
    "load_id": "shipmentNumList",
    "carrier_code": "carrierCodeList", // currently not used due to lack of data
    "carrier_desc": "carrierDescList",
    // "carrier_id": "carrierIdList",
    "carrier_id": "carrierCodeList",  // should be removed and "carrier_id": "carrierIdList" uncommented when data comes
    "service": "tariffServiceCodeList",
    "equip_type": "equipmentTypeList",
    "trailer_number": "trailerNumList",
    "tm_load_status": "tmsShipmentStatus",
    "tender_response": "tenderStatusList",
    "kc_load_status": "orderExecutionBucket",
    "pickup_appt_date": "pickupApptDateTimeOriginTZ",
    "pickup_on_time": "pickupOnTime",
    "delivery_appt_date": "deliveryApptDateTimeDestTZ",
    "delivery_on_time": "deliveryOnTime",
    "origin_location_name": "originLocationNameList",
    "origin_city": "originCityList",
    "origin_state": "originStateList",
    "origin_country": "originCountryList",
    "destination_location_name": "destLocationNameList",
    "destination_city": "destCityList",
    "destination_state": "destStateList",
    "destination_country": "destCountryList",
    "pending_task": "carrierUpdateLifecycleHealthList",
    "shipment_missing": "shipmentAttributes",
    "sent_to_SAP": "sentToSAP",
    "tms_in_sync": "tmsInSync",
    "live_load": "liveLoadIndList",
    "cp_shipment_type": "cpShipmentTypeList",

    "Transportation Carrier Committed": "TRANS_PLAN_TENDER_ACCEPTED",
    "Shipment Created": "SHIPMENT_CREATED",
    "Checked in by DC": "CHECK_IN",
    "Loading Started": "LOADING_STARTED",
    "Ready for Pickup": "TRANS_EXEC_READY_PICK_UP",
    "In Transit": "TRANS_EXEC_IN_TRANSIT",
    "Delivery Confirmed": "TRANS_EXEC_DELIVERY_CONFIRMED",
    "Arrive at Pick Stop": "SHIPMENT_MISSING_PICK_STOP_ARRIVE",
    "Depart from Pick Stop": "SHIPMENT_MISSING_PICK_STOP_DEPART",
    "Arrive at Drop Stop": "SHIPMENT_MISSING_DROP_STOP_ARRIVE",
    "Depart from Drop Stop": "SHIPMENT_MISSING_DROP_STOP_DEPART",
    // "Urgent Priority (Red Criteria)": "RED",
    // "High Priority (Yellow Criteria)": "YELLOW",
    "Missing Carrier Data - FourKites Data Received": "RED",
    "Urgent - No Event Data": "YELLOW",
    "Yes": "Y",
    "No": "N",
    // "-": "-",
    "Blank": "-",
    "Tender Accepted": "Tender Accepted",
    "Confirming": "Confirming",
    "Completed": "Completed",
    "Success": "SUCCESS",
    "Pending": "PENDING",
    "Failed": "FAILED",

    "Vendor Inbound": "VENDOR_INBOUND",
    "STO": "STO",
    "Customer": "CUST",

    "Accepted": "TENDER_ACCEPTED",
    "Rejected": "TENDER_REJECTED",
    "No Response": "TENDER_NO_RESPONSE"
}
