import { createSlice } from "@reduxjs/toolkit";
import { filters } from "../../constData/filters";
import { filterRequestPayload } from "../../constData/filterRequestPayload";
import { filtersOnTime } from "../../constData/filtersOnTime";
import { filterRequestPayloadOnTimePickup } from "../../constData/filterRequestPayloadOnTimePickup";
import { filterRequestPayloadOnTimeDelivery } from "../../constData/filterRequestPayloadOnTimeDelivery";
import { filtersFirstTender } from "../../constData/filtersFirstTender";
import { filterRequestPyaloadFirstTender } from "../../constData/filterRequestPyaloadFirstTender";

const filterSlice = createSlice({
  name: "filters",
  initialState: {
    trackNtrace: {
      filters: {
        ...filters
      },
      requestPayload: {
        ...filterRequestPayload
      }
    },
    onTimePickup: {
      filters: {
        ...filtersOnTime
      },
      requestPayload: {
        ...filterRequestPayloadOnTimePickup
      }
    },
    onTimeDelivery: {
      filters: {
        ...filtersOnTime
      },
      requestPayload: {
        ...filterRequestPayloadOnTimeDelivery
      }
    },
    firstTenderCompliance: {
      filters: {
        ...filtersFirstTender
      },
      requestPayload: {
        ...filterRequestPyaloadFirstTender
      }
    }
  },
  reducers: {
    getFilters: (state) => {
      return state;
    },
    setFilter: (state, action) => {
      const { tableName, filter, reqPayload } = action.payload;
      return {
        ...state,
        [tableName]: {
          filters: {
            ...state[tableName].filters,
            ...filter
          },
          requestPayload: {
            ...state[tableName].requestPayload,
            ...reqPayload
          }
        }
      }
      
    },
  }
});

export const { getFilters, setFilter } = filterSlice.actions;

export default filterSlice.reducer;
