import axios from '../helpers/axioxInstance';
import URLS from '../constData/urls';

/**
 * API handler gets called by a worker saga
 */
export const addComment = async ([reqBody]) => {
    const response = await axios.put(`${URLS.ADD_COMMENT }`, reqBody);
    if(response.status >= 400) {
        throw new Error(response.message);
    }
    return response.data;
}