import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import CustomCardHeader from './CustomCardHeader';
import { useSelector } from 'react-redux';
import CardNoteComponent from './CardNoteComponent';
import ProgressChart from '../../charts/ProgressChart';
import './Card.sass';

const LaneAwardComplianceCard = ({ carrierLoading }) => {

  const { t } = useTranslation();
  const pageName = 'laneAwardCompliance';
  const [loading, setLoading] = useState(() => carrierLoading);
  const { summary: { laneAwardCompliance: { data: { summaryResponse }, isLoading } } } = useSelector(state => state.chartSummary);

  useEffect(() => {
    setLoading(carrierLoading || isLoading);
  }, [carrierLoading, isLoading]);

  return (
    <div className='card2'>
      <CustomCardHeader
        title={t('lane_award_compliance')}
        pageName={pageName}
      />
      <div className='styleFKYCBar'>
        <ProgressChart
          value1={loading ? '-' : summaryResponse?.compliancePercentage}
          value2={loading ? '-' : summaryResponse?.nonCompliancePercentage}
          loading={loading}
          value1Title={loading ? '-' : <Typography variant='h3' className='totalComplianceTitle'>{t('total_compliance')}</Typography>}
          value2Title={<div className='ratio'>
            <Typography variant='h1' className={summaryResponse?.compliancePercentage > summaryResponse?.complianceTargetPercentage ? 'good' : 'bad'}>{!summaryResponse?.compliancePercentage ? '-' : `${summaryResponse?.compliancePercentage}%`}</Typography>
            &nbsp; | &nbsp;
            <Typography variant='h1' className='target'>{!summaryResponse?.complianceTargetPercentage ? '-' : `${summaryResponse?.complianceTargetPercentage}% ${t('target')}`}</Typography>
          </div>
          }
          color={summaryResponse?.compliancePercentage > summaryResponse?.complianceTargetPercentage ? "primary" : "secondary"}
          noRail={true}
          gotoPath={pageName}
        />
      </div>
      {/* {!loading && !summaryResponse?.complianceTargetPercentage && <div className='buffer'></div>} */}
      <CardNoteComponent note={t('kpi_note_pickup_date')} />
    </div>
  )
}

export default LaneAwardComplianceCard