import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import { useDeepCompareEffect } from "react-use";
import qs from 'query-string';
import Grid from "@mui/material/Grid";
import DataTable from "./Table";
import TrackNTraceHeader from "./TrackNTraceHeader";
import { defaultHeaderFiltersByTable, generateTableFilters, optionalFiltersByTable, pageSize, isSubset } from "../helpers/index";
import { COLUMNS } from "../constData/columns";
import { getTableData } from "../redux/reducers/tableDataSlice";
import { getTmsMetaData } from "../redux/reducers/tmsMetaDataSlice";
import { roleSpecificColumns } from '../helpers/index';
import { filters as defaultStoreFilters } from "../constData/filters";
import { optionalFilterPayloadTNT } from '../constData/filterRequestPayload';
import './TrackNTrace.sass';

const TrackNTrace = () => {

    const dispatch = useDispatch();
    const tableName = 'trackNtrace';

    const { tableData, isLoading } = useSelector(state => state?.loadDetails);
    const { userInfo: { role } } = useSelector(state => state?.user);

    const [shipments, setShipments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [onPage, setOnPage] = useState(1);

    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState(() => Object.fromEntries([...searchParams]));
    const [controller] = useState(() => new AbortController());
    const [reqPayload, setReqPayload] = useState({})

    useDeepCompareEffect(() => {
        let filterValues = {};
        const params = Object.fromEntries([...searchParams]);
        Object.keys(optionalFilterPayloadTNT).map(d => {
            if (optionalFilterPayloadTNT[d]) {
                filterValues[d] = JSON.stringify(optionalFilterPayloadTNT[d]);
            }
        })
        setSearchParams(qs.stringify({
            ...filterValues,
            ...params
        }));
        setSearch({
            ...filterValues,
            ...params,
        });
        setOnPage(1);
    }, [searchParams]);

    useEffect(() => {
        setShipments(tableData.shipments);
        setLoading(isLoading);
        setTotalPages(tableData.totalPages);
        setTotalRecords(tableData.totalRecords);
        // setOnPage(tableData.onPage)
    }, [tableData])

    /**
     * Since React 18 uses Strict mode components tents to,
     * Mount -> Unmount -> Mount 
     * as a result the useEffect gets called twice, 
     * inorder to prevent this from happening we need to,
     * 
     * write cleanUp for useEffect that gets called whenever 
     * dependecy changes
     *          OR
     * When we want component to be mounted only ones for 
     * the first time, create a ref value and 
     * initialize it with true val and use as below. 
     * 
     * (We use ref instead of a local varriable 
     * as value for local variable will set back to true 
     * post render)
     */

    useEffect(() => {
        const controllerAbort = new AbortController();
        dispatch(getTmsMetaData({ controllerAbort }));
        return () => {
            controllerAbort.abort();
        }
    }, []);

    const refresh = (controller, pageNumber) => {
        const { tableFilters, definedValues } = generateTableFilters(optionalFiltersByTable[tableName], search, defaultStoreFilters, tableName);
        const commonPayload = defaultHeaderFiltersByTable[tableName];
        const finalPayload = {
            ...commonPayload,
            ...tableFilters,
        }
        if (isSubset(Object.keys(search), Object.keys(definedValues))) {
            setReqPayload(finalPayload);
            dispatch(getTableData({
                reqBody: finalPayload,
                pageSize,
                pageNum: pageNumber || onPage,
                tableName: tableName,
                controller
            }));
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        refresh(controller);
        return () => {
            controller.abort();
        }
    }, [search])


    return (
        <Grid container className="container">
            <Grid item xs={12}>
                <TrackNTraceHeader 
                    refresh={() => {
                        setOnPage(1);
                        refresh(controller, 1);
                    }} 
                    refreshBtn={true} 
                    tableName={tableName} 
                    reqPayload={reqPayload}
                />
                <DataTable
                    tableName={tableName}
                    rowData={shipments}
                    columnOrder={roleSpecificColumns(COLUMNS, role)}
                    loading={loading}
                    size={pageSize}
                    totalPages={totalPages}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    refresh={() => refresh(controller)}
                    setOnPage={(value) => {
                        setOnPage(value);
                        refresh(controller, value)
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default TrackNTrace;