import { createSlice } from "@reduxjs/toolkit";
import { summaryPayload } from "../../constData/summaryPayload";

const carrierInfoSlice = createSlice({
    name: "carrierInfo",
    initialState: {
        carrierInfo: {
            carrierCode: null,
            tariffServiceCodeList: [],
            // dateTime: null,
            // serviceId: [],
            // originLocation: [],
            summaryPayload: {
                ...summaryPayload
            }
        }
    },
    reducers: {
        getCarrierInfo: (state) => {
            return state;
        },
        setCarrierInfo: (state, action) => {
            state.carrierInfo = action.payload;
        },
        resetCarrierInfo: (state) => {
            return {
                carrierInfo: {
                    carrierCode: null,
                    tariffServiceCodeList: [],
                    summaryPayload: {
                        ...summaryPayload
                    }
                }
            }
        }
    }
});

export const { getCarrierInfo, setCarrierInfo, resetCarrierInfo } = carrierInfoSlice.actions;

export default carrierInfoSlice.reducer;