import { put, takeLatest, call } from 'redux-saga/effects';
import { retriggerSuccess, retriggerFailure } from '../reducers/retriggerEventSlice';
import { retriggerEvent } from '../../service/retriggerEvent';

/**
 * worker saga upon being called by the watcher saga makes the appropriate API call
 * and set the response data for both success and failuer calls.
 */
export function* workerRetriggerEventsSaga({ payload }) {
    try {
        const response = yield call(retriggerEvent, [payload]);
        yield put(retriggerSuccess(response));
    } catch (error) {
        yield put(retriggerFailure(error.toString()))
    }
}

/**
* watcher saga - looks for the respective action dispatch. 
* First argument in the take/takeLatest/takeEvery is the action type and 
* the second argument is the worker saga function.
*/
function* retriggerEventSaga() {
    yield takeLatest("retriggerEvent/retrigger", workerRetriggerEventsSaga);
}

export default retriggerEventSaga;