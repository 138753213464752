import { put, takeLatest, call } from 'redux-saga/effects';
import { saveStopEventsSuccess, saveStopEventsFailure } from '../reducers/saveStopEventsSlice';
import { saveStopEvents } from '../../service/saveStopEvents';

/**
 * worker saga upon being called by the watcher saga makes the appropriate API call
 * and set the response data for both success and failuer calls.
 */
export function* workerSaveStopEventsSaga({ payload }) {
    try {
        const response = yield call(saveStopEvents, [payload]);
        yield put(saveStopEventsSuccess(response));
    } catch (error) {
        yield put(saveStopEventsFailure(error.toString()))
    }
}

/**
* watcher saga - looks for the respective action dispatch. 
* First argument in the take/takeLatest/takeEvery is the action type and 
* the second argument is the worker saga function.
*/
function* saveStopEventsSaga() {
    yield takeLatest("saveStopEventsService/saveStopEvents", workerSaveStopEventsSaga);
}

export default saveStopEventsSaga;