import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';
import qs from "query-string";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import DetailsPageHeader from './DetailsPageHeader';
import Typography from '@mui/material/Typography';
import { COLUMN_LANE_AWARD_COMPLIANCE } from '../../constData/columnsLaneAwardCompliance';
import DataTable from '../Table';
import {
    pageSize,
    generateGlobalFilters,
    defaultHeaderFiltersByTable,
    globalFiltersRequired
} from '../../helpers';
import TrackNTraceHeader from '../TrackNTraceHeader';
import { getTableData } from '../../redux/reducers/tableDataSlice';
import moment from 'moment-timezone';
import { optionalFilterPayloadLAC } from '../../constData/filterRequestPayloadLAC';
import { FormControlLabel } from '@mui/material';
import CustomCheckBox from '../common/CustomCheckBox';

const LaneAwardCompliance = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tableNameLAC = 'laneAwardCompliance';
    const { carrierid: carrieridLAC } = useParams();

    const { tableData, isLoading: isLoadingLAC } = useSelector(state => state?.loadDetails);

    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearchLAC] = React.useState(() => Object.fromEntries([...searchParams]));

    const [shipments, setShipmentsLAC] = useState([]);
    const [loading, setLoadingLAC] = useState(true);
    const [totalPages, setTotalPagesLAC] = useState(0);
    const [totalRecords, setTotalRecordsLAC] = useState(0);
    const [onPage, setOnPageLAC] = useState(1);
    const [reqPayload, setReqPayloadLAC] = useState({});
    const [awardCompliant, setAwardCompliant] = useState(search.awardCompliant || null);

    const handleChange = (e) => {
        setAwardCompliant(e.target.checked ? 'N' : null);
        setSearchParams(qs.stringify({
            ...search,
            awardCompliant: e.target.checked ? 'N' : ''
        }));
    }

    useDeepCompareEffect(() => {
        let filterValues = {};
        const params = Object.fromEntries([...searchParams]);
        Object.keys(optionalFilterPayloadLAC).map(d => {
            if (optionalFilterPayloadLAC[d]) {
                filterValues[d] = optionalFilterPayloadLAC[d];
            }
        })
        setSearchParams(qs.stringify({
            ...filterValues,
            ...params,
        }));
        setSearchLAC({
            ...filterValues,
            ...params,
        });
        setOnPageLAC(1);
    }, [searchParams]);

    useDeepCompareEffect(() => {
        const controller = new AbortController();
        const globalFilters = generateGlobalFilters(globalFiltersRequired, search);
        const commonPayload = defaultHeaderFiltersByTable[tableNameLAC];
        const awardWindow = {
            startTime: moment(globalFilters.deliveryActualArrivalDatetimeFinalDestTZ.startTime).format('YYYY-MM-DD'),
            endTime: moment(globalFilters.deliveryActualArrivalDatetimeFinalDestTZ.endTime).format('YYYY-MM-DD')
        }

        const reqBody = {
            ...globalFilters,
            ...commonPayload,
            awardWindow,
            awardCompliant: search.awardCompliant || awardCompliant,
            carrierid: undefined,
            carrierCodeList: [carrieridLAC],
            deliveryActualArrivalDatetimeFinalDestTZ: undefined,
            isPickedByPrimaryList: undefined,
            tenderedToPrimaryList: undefined
        };

        setReqPayloadLAC(reqBody);
        dispatch(getTableData({
            reqBody,
            pageSize: pageSize,
            pageNum: onPage,
            tableName: tableNameLAC,
            controller
        }));
        
        return () => {
            controller.abort();
        };
    }, [search, onPage, carrieridLAC]);


    useEffect(() => {
        setShipmentsLAC(tableData.shipments);
        setLoadingLAC(isLoadingLAC);
        setTotalPagesLAC(tableData.totalPages);
        setTotalRecordsLAC(tableData.totalRecords);
        // setOnPage(tableData.onPage)
    }, [tableData]);


    return (
        <Grid container className="pageContainer">
            <DetailsPageHeader
                buttonTitle={t("lane_award_compliance")}
                pageName={tableNameLAC}
                enableLanes={false}
            />
            <Grid item xs={6} >
                <div className='ratioLAC'>
                    {
                        loading ? "-" :
                            <>
                                <Typography variant='h2' className={tableData.compliancePercentage > tableData.complianceTargetPercentage ? 'good' : 'bad'}>{`${t('total_compliance')} ${tableData.compliancePercentage}%`}</Typography>
                                &nbsp; | &nbsp;
                                <Typography variant='h2' className='target'>{`${tableData.complianceTargetPercentage}% ${t('target')}`}</Typography>
                            </>
                    }
                </div>
            </Grid>
            <Grid item xs={5}>
                <div className='checkboxFilterLAC'>
                    <FormControlLabel
                        control={<CustomCheckBox
                            name={t("show_only_non_compliant")}
                            data-testid="lane_award_compliance_checkbox"
                            onChange={(e) => handleChange(e)}
                            checked={search.awardCompliant === "N"}
                        />}
                        label={<Typography
                            variant='h5'
                        >
                            {t("show_only_non_compliant")}
                        </Typography>}
                    />
                </div>
            </Grid >
            <Grid item xs={1} className='actionSectionPart'>
                <TrackNTraceHeader
                    refreshBtn={false}
                    tab={'performance'}
                    tableName={tableNameLAC}
                    reqPayload={reqPayload}
                    filtersMenu={false}
                />
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    tableName={tableNameLAC}
                    rowData={shipments}
                    columnOrder={COLUMN_LANE_AWARD_COMPLIANCE}
                    loading={loading}
                    size={pageSize}
                    totalPages={totalPages}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    setOnPage={setOnPageLAC}
                />
            </Grid>
        </Grid>
    )
}

export default LaneAwardCompliance