import axios from '../helpers/axioxInstance';
// import axios from "axios"
import URLS from '../constData/urls';
// import { MOCK_REASON_CODE } from '../constData/MOCK_REASON_CODE';

/**
 * API handler gets called by a worker saga
 */
export const fetchTmsMetaData = async ([controllerAbort]) => {
    const response = await axios.get(`${URLS.FETCH_TMS_REASON_META}`, {signal: controllerAbort.signal});
    if(response.status >= 400) {
        throw new Error(response.message);
    }
    // let data = MOCK_REASON_CODE.filter(item => item.category !== null);
    // return data;
    let data = response.data.filter(item => item.category !== null);
    return data;
    
}
