import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from "react-router-dom";
import qs from "query-string";
import Grid from '@mui/material/Grid';
import DataTable from '../Table';
import { useSelector, useDispatch } from 'react-redux';
import { COLUMNS_FIRST_TENDER } from '../../constData/columnFirstTender';
import {
    defaultHeaderFiltersByTable,
    generateGlobalFilters,
    generateTableFilters,
    globalFiltersRequired,
    isSubset,
    optionalFiltersByTable,
    pageSize
} from '../../helpers';
import TrackNTraceHeader from '../TrackNTraceHeader';
// import ProgressBarWrapperComponent from './ProgressBarWrapperComponent';
import DetailsPageHeader from './DetailsPageHeader';
// import ProgressChartV2 from '../../charts/ProgressChartV2';
import { filterRequestPyaloadFirstTender, optionalFilterPayloadFTC } from '../../constData/filterRequestPyaloadFirstTender';
import { useDeepCompareEffect } from 'react-use';
import { filtersFirstTender } from "../../constData/filtersFirstTender";
import { getTableData } from '../../redux/reducers/tableDataSlice';
import './page.sass';

const FirstTenderCompliance = () => {

    const { t } = useTranslation();
    const { tableData, isLoading } = useSelector(state => state?.loadDetails);
    const tableName = 'firstTenderCompliance';
    const { carrierid } = useParams();
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState(() => Object.fromEntries([...searchParams]));

    const [shipmentsData, setShipmentsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [onPage, setOnPage] = useState(1);
    // const [progressData, setProgressData] = useState({});
    const [reqPayload, setReqPayload] = useState({});
    const [tenderStatus, setTenderStatus] = useState([]);

    useDeepCompareEffect(() => {
        let filterValues = {};
        const params = Object.fromEntries([...searchParams]);
        Object.keys(optionalFilterPayloadFTC).map(d => {
            if (optionalFilterPayloadFTC[d]) {
                filterValues[d] = JSON.stringify(optionalFilterPayloadFTC[d]);
            }
        })
        setSearchParams(qs.stringify({
            ...filterValues,
            ...params,
        }));
        setSearch({
            ...filterValues,
            ...params,
        });
        setOnPage(1);
    }, [searchParams]);

    useDeepCompareEffect(() => {
        const controller = new AbortController();
        const globalFilters = generateGlobalFilters(globalFiltersRequired, search);
        const { tableFilters, definedValues } = generateTableFilters(optionalFiltersByTable[tableName], search, filtersFirstTender);
        const commonPayload = defaultHeaderFiltersByTable[tableName];
        setTenderStatus(tableFilters.tenderStatusList?.[0]);

        const reqBody = {
            ...globalFilters,
            ...commonPayload,
            ...tableFilters,
            carrierid: undefined,
            tenderStatusList: tableFilters.tenderStatusList?.length ? tableFilters.tenderStatusList : filterRequestPyaloadFirstTender.tenderStatusList,
            tenderedCarrierCodeList: [carrierid],
            pickupApptDateTimeOriginTZ: globalFilters.deliveryActualArrivalDatetimeFinalDestTZ,
            tenderedTariffServiceCodeList: globalFilters.tariffServiceCodeList,
            tenderedToPrimaryList: globalFilters.isPickedByPrimaryList,
            isPickedByPrimaryList: undefined,
            deliveryActualArrivalDatetimeFinalDestTZ: undefined,
            tariffServiceCodeList: undefined
        };

        if (isSubset(Object.keys(search), Object.keys(definedValues))) {
            setReqPayload(reqBody);
            dispatch(getTableData({
                reqBody,
                pageSize: pageSize,
                pageNum: onPage,
                tableName: tableName,
                controller
            }));
        }
        return () => {
            controller.abort();
        };
    }, [
        search, onPage, carrierid
    ]);


    // useEffect(() => {
    //     setProgressData({
    //         title: `5,000 ${t('total_loads')}`,
    //         subtitle1: `75% ${t('on_time')}`,
    //         subtitle2: `85% ${t('target')}`,
    //         value1: 3000,
    //         value2: 1000,
    //         value1Title: `3,000 ${t('on_time_picked_stops')}`,
    //         value2Title: `1,000 ${t('late_picked_stops')}`,
    //         color: "primary"
    //     });
    // }, [])

    useEffect(() => {
        setShipmentsData(tableData.shipments);
        setLoading(isLoading);
        setTotalPages(tableData.totalPages);
        setTotalRecords(tableData.totalRecords);
        // setOnPage(tableData.onPage)
    }, [tableData]);

    return (
        <Grid container className="pageContainer">
            <DetailsPageHeader
                buttonTitle={t("first_tender_compliance")}
                pageName={tableName}
                requiredKeys={["isPickedByPrimaryList"]}
                enableLanes={true}
            />
            {/* <Grid item xs={12} className='chartSection' >
                <Grid container className='chartSection'>
                    <Grid item xs={12} lg={2} md={12} className='progressChartSection'>
                        <span className='customTextStyle'>{progressData?.title}</span>
                    </Grid>
                    <Grid item xs={12} lg={10} md={12} className='progressChartSection'>
                        <ProgressChartV2 />
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid item xs={12} className='actionSection'>
                <TrackNTraceHeader refreshBtn={false} tab={'performance'} tableName={tableName} reqPayload={reqPayload} />
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    tableName={tableName}
                    rowData={shipmentsData}
                    columnOrder={COLUMNS_FIRST_TENDER}
                    loading={loading}
                    size={pageSize}
                    totalPages={totalPages}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    setOnPage={setOnPage}
                />
            </Grid>
        </Grid>
    )
}

export default FirstTenderCompliance