import { put, takeLatest, call } from 'redux-saga/effects';
import { setCarrierServiceMetadataSuccess, setCarrierServiceMetadataFailure } from '../reducers/carrierServiceMetadataSlice';
import { fetchCarrierServiceMetadata } from '../../service/fetchCarrierServiceMetadata';


/**
 * worker saga upon being called by the watcher saga makes the appropriate API call
 * and set the response data for both success and failuer calls.
 */
export function* workerGetCarrierServiceMetadataSaga({ payload }) {
    try {
        const response = yield call(fetchCarrierServiceMetadata, [payload.abortController]);
        yield put(setCarrierServiceMetadataSuccess(response));
    } catch (error) {
        yield put(setCarrierServiceMetadataFailure(error.toString()))
    }
}

/**
* watcher saga - looks for the respective action dispatch. 
* First argument in the take/takeLatest/takeEvery is the action type and 
* the second argument is the worker saga function.
*/
function* carrierServiceMetadataSaga() {
    yield takeLatest("carrierServiceMetadata/getCarrierServiceMetadata", workerGetCarrierServiceMetadataSaga);
}

export default carrierServiceMetadataSaga;