import { createSlice } from "@reduxjs/toolkit";

const userInfoSlice = createSlice({
    name: "userInfo",
    initialState: {
        userInfo: {
            userName: null,
            userID: null,
            role: null
        }
    },
    reducers: {
        getUserInfo: (state) => {
            return state;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        }
    }
});

export const { getUserInfo, setUserInfo } = userInfoSlice.actions;

export default userInfoSlice.reducer;