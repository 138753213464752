import axios from '../helpers/axioxInstance';
import URLS from '../constData/urls';

/**
 * API handler gets called by a worker saga
 */

const tableURlMapping = {
    trackNtrace: URLS.FETCH_TABLE_DATA,
    onTimePickup: URLS.FETCH_TABLE_DATA_ON_TIME_PERFORMANCE,
    onTimeDelivery: URLS.FETCH_TABLE_DATA_ON_TIME_PERFORMANCE,
    firstTenderCompliance: URLS.FETCH_TABLE_DATA_FIRST_TENDER,
    laneAwardCompliance: URLS.FETCH_TABLE_DATA_LANE_AWARD,
    trailerPoolCompliance: URLS.FETCH_TABLE_DATA_TRAILER_POOL
}

export const fetchCSVData = async ([reqBody, totalPages, tableName]) => {
    const pageSize = 1000;
    let allData = {
        onPage: 1,
        pageSize: 0,
        shipments: [],
        totalPages: 0,
        totalRecords: 0
    };
    for (let i = 1; i <= totalPages; i++) {        
        const response = await axios.post(`${tableURlMapping[tableName]}?pageNum=${i}&pageSize=${pageSize}`, reqBody);
        if (response.status === 200) {
            let data = response.data;
            allData = {
                ...allData,
                pageSize: allData.pageSize + data.pageSize,
                shipments: tableName === 'laneAwardCompliance' || tableName === 'trailerPoolCompliance'
                    ? [
                        ...allData.shipments,
                        ...data[tableName === 'laneAwardCompliance' ? 'laneAwardComplianceResponse' : 'trailerPoolComplianceResponse']
                    ]
                    : [
                        ...allData.shipments,
                        ...data.shipments
                    ],
                totalPages: data.totalPages,
                totalRecords: data.totalRecords
            }
        }
    }

    return allData;
}