import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import CustomButton from "./common/CustomButton";
import closeIcon from '../assets/closeIcon.svg';
import IconButton from '@mui/material/Button';
import Comment from './Comment';
import { commentLimit, commentsFeature } from '../helpers';
import { resetComments } from '../redux/reducers/commentsSlice';
import { addComment as addCommentDispatch, resetAddComment } from '../redux/reducers/addCommentSlice';
import { setTableDataSuccess } from '../redux/reducers/tableDataSlice';
import './CommentDialog.sass';

const CommentDialog = (props) => {

    const { openComment, setOpenComment, setCallFetchComments } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [addComment, setAddComment] = useState(false);
    const [comment, setComment] = useState('');

    const { newComment, addCommentLoading, addCommentErrMsg } = useSelector(state => state?.addNewComment);
    let { tableData } = useSelector(state => state?.loadDetails);

    useEffect(() => {
        if (addCommentErrMsg === 'success') {
            setComment('');
            setAddComment(false);
            setOpenComment({
                ...openComment,
                comments: [...openComment.comments, { ...newComment }]
            });
            let shipments = tableData?.shipments.map(item => {
                let test  = {
                    ...item
                };
                if(item.shipmentNum === openComment.loadId) {
                    test.totalComments = [...openComment.comments, { ...newComment }]?.length
                }
                return test;
            });
            dispatch(setTableDataSuccess({
                    ...tableData,
                    shipments: shipments
                }
            ));
            dispatch(resetAddComment());
        }
    }, [addCommentLoading]); // here are not using addCommentErrMsg in dependency because this useEffect will not execute in case of 2 successful add comment upadtes.

    const handleClose = () => {
        setCallFetchComments({
            callApi: false,
            loadId: null
        })
        setComment('');
        setAddComment(false);
        setOpenComment({
            ...openComment,
            open: false,
        });
        dispatch(resetComments());
        dispatch(resetAddComment());
    }

    const handleAddComment = () => {
        if (addComment) {
            dispatch(addCommentDispatch({
                originRegion: "NA",
                shipmentNum: openComment?.loadId,
                feature: commentsFeature,
                commentText: comment
            }));
        } else {
            setAddComment(true);
        }
    }

    return (
        <Dialog
            open={openComment?.open}
            fullWidth
            maxWidth="xs"
            classes={{
                scrollPaper: "topScrollPaper",
                paperScrollBody: "topPaperScrollBody"
            }}
            data-testid="comment-dialog"
        >
            <DialogTitle className='commentTitle'>
                <Typography variant='h1'>{`${t('load')}: ${openComment?.loadId}`}</Typography>
                <IconButton
                    disableRipple
                    className='csvButtonStyles'
                    onClick={() => handleClose()}
                    data-testid={'closeIcon-comment-button'}
                >
                    <img src={closeIcon} alt="closeIcon-dialog" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <>
                        {
                            openComment?.comments?.length
                                ? <Comment comments={openComment?.comments} />
                                : <div className='noComment'>
                                    <Typography variant='h3'>{t("no_comments_yet")}</Typography>
                                </div>
                        }
                        {
                            addCommentErrMsg && addCommentErrMsg !== 'success' && <Typography variant='body1' className='alert'>{t('add_comment_failure')}</Typography>
                        }
                        {
                            addComment && <TextareaAutosize
                                className='textArea'
                                minRows={3}
                                placeholder={t("enter_comment")}
                                maxLength={500}
                                onChange={(e) => setComment(e.target.value)}
                                data-testid="text-comment"
                            />
                        }
                        {
                            comment.length > 0 && addComment && <Typography variant='body1' className={commentLimit - comment.length === 0 ? 'alert' : null}>{`${t('text_limit')} ${(commentLimit - comment.length)}`}</Typography>
                        }                         
                    </>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButton
                    className="commentButton"
                    btnType={addComment ? "secondary" : "octonary"}
                    onClick={() => handleAddComment()}
                    datatestid={addComment ? 'save-button' : 'new-message-button' }
                    disabled={addComment
                        ? comment?.length
                            ? false
                            : true
                        : false
                    }
                >
                    {addComment ? t("save") : t("new_message")}
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default CommentDialog