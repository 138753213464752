import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSummary, resetSummary } from '../../redux/reducers/summarySlice';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment-timezone';
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { dateFormat, dateFormat1, datetimeFormats, isJsonParsable } from "../../helpers";
import { useDeepCompareEffect } from "react-use";

const HeaderAPIHandler = () => {

    const params = useParams();
    const dispatch = useDispatch();

    const { carrierInfo } = useSelector(state => state?.carrier);
    const { carrierid } = params;
    let [searchParams] = useSearchParams();

    const [deliveryActualArrivalDatetimeFinalDestTZ, setDeliveryActualDT] = useState(() => {
        const urlDates = isJsonParsable(searchParams.get("deliveryActualArrivalDatetimeFinalDestTZ"));
        return {
            startTime: moment(urlDates?.startTime, dateFormat).format(datetimeFormats.startTime),
            endTime: moment(urlDates?.endTime, dateFormat).format(datetimeFormats.endTime)
        }
    });

    useEffect(() => {
        const urlDates = isJsonParsable(searchParams.get("deliveryActualArrivalDatetimeFinalDestTZ"));
        setDeliveryActualDT({
            startTime: moment(urlDates?.startTime, dateFormat).format(datetimeFormats.startTime),
            endTime: moment(urlDates?.endTime, dateFormat).format(datetimeFormats.endTime)
        });
    }, [searchParams.get("deliveryActualArrivalDatetimeFinalDestTZ")]);

    useDeepCompareEffect(() => {
        let location = isJsonParsable(searchParams.get("originCityStateList"))
        let serviceID = isJsonParsable(searchParams.get("tariffServiceCodeList"))
        const controller = new AbortController();
        const controller2 = new AbortController();
        const controller3 = new AbortController();
        const controller4 = new AbortController();
        const controller5 = new AbortController();
        const controller6 = new AbortController();
        const controller7 = new AbortController();

        if(carrierid && deliveryActualArrivalDatetimeFinalDestTZ && location?.length >= 0 && serviceID?.length >= 0) {
            /** On Time Pickup */            
            dispatch(getSummary({
                reqBody: {
                    ...carrierInfo.summaryPayload,
                    "subtype": "PICKUP_ARRIVAL_ON_TIME",
                    "carrierCodeList": [carrierid],
                    "deliveryActualArrivalDatetimeFinalDestTZ": deliveryActualArrivalDatetimeFinalDestTZ,
                    "liveLoadIndList": ["Y"],
                    "originCityStateList": location,
                    "tariffServiceCodeList": serviceID,
                    "tenderedToPrimaryList": undefined
                },
                type: 'onTimePickup',
                controller
            }));
            /** On Time Delivery */
            dispatch(getSummary({
                reqBody: {
                    ...carrierInfo.summaryPayload,
                    "subtype": "DELIVERY_ARRIVAL_ON_TIME",
                    "carrierCodeList": [carrierid],
                    "deliveryActualArrivalDatetimeFinalDestTZ": deliveryActualArrivalDatetimeFinalDestTZ,
                    "originCityStateList": location,
                    "tariffServiceCodeList": serviceID,
                    "tenderedToPrimaryList": undefined
                },
                type: 'onTimeDelivery',
                controller: controller2
            }));
            /** Lane Award Compliance */
            let awardWindow = {
                startTime: moment(deliveryActualArrivalDatetimeFinalDestTZ.startTime).format('YYYY-MM-DD'),
                endTime: moment(deliveryActualArrivalDatetimeFinalDestTZ.endTime).format('YYYY-MM-DD')
            }
            dispatch(getSummary({
                reqBody: {
                    ...carrierInfo.summaryPayload,
                    "subtype": "LANE_AWARD_COMPLIANCE",
                    "carrierCodeList": [carrierid],
                    "awardWindow": awardWindow,
                    "originCityStateList": location,
                    "tariffServiceCodeList": serviceID,
                    "tenderedToPrimaryList": undefined,
                    "deliveryActualArrivalDatetimeFinalDestTZ": undefined
                },
                type: 'laneAwardCompliance',
                controller: controller3
            }));
            /** Carrier Event Response Efficiency */
            dispatch(getSummary({
                reqBody: {
                    ...carrierInfo.summaryPayload,
                    "subtype": "CARRIER_EVENT_RESPONSE_EFFICIENCY",
                    "carrierCodeList": [carrierid],
                    "deliveryActualArrivalDatetimeFinalDestTZ": deliveryActualArrivalDatetimeFinalDestTZ,
                    "originCityStateList": location,
                    "tariffServiceCodeList": serviceID,
                    "tenderedToPrimaryList": undefined
                },
                type: 'carrierEventResponse',
                controller: controller4
            }));
            /** FourKites Tracking Consistency */
            dispatch(getSummary({
                reqBody: {
                    ...carrierInfo.summaryPayload,
                    "subtype": "FOURKITES_TRACKING_CONSISTENCY",
                    "carrierCodeList": [carrierid],
                    "fourkitesTerminationDate": {
                        startTime: moment(deliveryActualArrivalDatetimeFinalDestTZ.startTime).format(dateFormat1),
                        endTime: moment(deliveryActualArrivalDatetimeFinalDestTZ.endTime).format(dateFormat1)
                    },
                    "originCityStateList": location,
                    "tariffServiceCodeList": serviceID,
                    "tenderedToPrimaryList": undefined,
                    "deliveryActualArrivalDatetimeFinalDestTZ": undefined
                },
                type: 'fourKitesTracking',
                controller: controller5
            }));
            /** Billing Accuracy */
            dispatch(getSummary({
                reqBody: {
                    ...carrierInfo.summaryPayload,
                    "subtype": "BILLING_ACCURACY",
                    "carrierCodeList": [carrierid],
                    "invoicePaidDate": {
                        startTime: moment(deliveryActualArrivalDatetimeFinalDestTZ.startTime).format(dateFormat1),
                        endTime: moment(deliveryActualArrivalDatetimeFinalDestTZ.endTime).format(dateFormat1)
                    },
                    "originCityStateList": undefined,
                    "tariffServiceCodeList": undefined,
                    "tenderedToPrimaryList": undefined,
                    "deliveryActualArrivalDatetimeFinalDestTZ": undefined,
                },
                type: 'billingAccuracy',
                controller: controller6
            }));
            /** Trailer Pool Compliance */
            dispatch(getSummary({
                reqBody: {
                    ...carrierInfo.summaryPayload,
                    "subtype": "TRAILER_POOL_COMPLIANCE",
                    "carrierCodeList": [carrierid],
                    "trailerPoolComplianceWindow": {
                        startTime: moment(deliveryActualArrivalDatetimeFinalDestTZ.startTime).format(dateFormat1),
                        endTime: moment(deliveryActualArrivalDatetimeFinalDestTZ.endTime).format(dateFormat1)
                    },
                    "originCityStateList": location,
                    "tariffServiceCodeList": serviceID,
                    "tenderedToPrimaryList": undefined,
                    "deliveryActualArrivalDatetimeFinalDestTZ": undefined,
                },
                type: 'trailerPoolCompliance',
                controller: controller7
            }));
        }
        return () => {
            controller.abort();
            controller2.abort();
            controller3.abort();
            controller4.abort();
            controller5.abort();
            controller6.abort();
            controller7.abort();
            dispatch(resetSummary());
        };
    }, [carrierid, deliveryActualArrivalDatetimeFinalDestTZ, searchParams.get("originCityStateList"), searchParams.get("tariffServiceCodeList")]);

    useDeepCompareEffect(() => {
        let location = isJsonParsable(searchParams.get("originCityStateList"))
        let serviceID = isJsonParsable(searchParams.get("tariffServiceCodeList"));
        let tenderedToPrimaryList =  isJsonParsable(searchParams.get("tenderedToPrimaryList"));
        const controller = new AbortController();
        if(carrierid && deliveryActualArrivalDatetimeFinalDestTZ && location?.length >= 0 && serviceID?.length >= 0) {
            /** First Tender Compliance */
            dispatch(getSummary({
                reqBody: {
                    ...carrierInfo.summaryPayload,
                    "subtype": "FIRST_TENDER_COMPLIANCE",
                    "tenderedCarrierCodeList": [carrierid],
                    "pickupApptDateTimeOriginTZ": deliveryActualArrivalDatetimeFinalDestTZ,
                    "originCityStateList": location,
                    "tenderedTariffServiceCodeList": serviceID,
                    "tenderedToPrimaryList": tenderedToPrimaryList,
                    "tariffServiceCodeList": undefined,
                    "carrierCodeList": undefined,
                    "deliveryActualArrivalDatetimeFinalDestTZ": undefined
                },
                type: 'firstTenderCompliance',
                controller
            }));
        }
        return () => {
            controller.abort();
            // dispatch(resetSummary());
        };
    }, [
        carrierid, 
        deliveryActualArrivalDatetimeFinalDestTZ, 
        searchParams.get("originCityStateList"), 
        searchParams.get("tariffServiceCodeList"),
        searchParams.get("tenderedToPrimaryList")
    ]);


    return null;
}

export default HeaderAPIHandler;