import { createTheme } from '@mui/material/styles';
import { palette } from './ColorPalette';
import { typography } from './CustomTypography';

export const appTheme = createTheme({
    palette: palette,
    typography,
    components: {
        // https://mui.com/material-ui/customization/theme-components/#global-style-overrides
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: `0px 0px 8px rgba(0, 0, 0, 0.04)`,
                    backgroundColor: palette.primaryBase
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '12px',
                    fontWeight: 400,
                    fontStyle: "Outfit",
                    color: `${palette.ebony} !important`,
                    textTransform: 'none',
                    "&.Mui-selected": {
                        padding: '18px 30px',
                        backgroundColor: palette.primaryBase,
                        borderBottom: `4px solid ${palette.black}`
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: palette.primaryBase
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: '48px !important'
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
              body: {
                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  backgroundColor: palette.gray10,
                  width: 8,
                  height: 8
                },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                  borderRadius: 8,
                  backgroundColor: palette.lightGrey,
                },
                "*:hover::-webkit-scrollbar":{
                    width: 14,
                    height: 14
                },
                // "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                //   backgroundColor: palette.grey,
                // },
                // "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                //   backgroundColor: palette.grey,
                // },
                // "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                //   backgroundColor: palette.grey,
                // },
                // "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                //   backgroundColor: palette.gray10,
                //   borderRadius: 8,
                // },
              },
            },
          },
    }
})