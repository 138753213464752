import { put, takeLatest, call } from 'redux-saga/effects';
import { setTmsMetaDataSuccess, setTmsMetaDataFailure } from '../reducers/tmsMetaDataSlice';
import { fetchTmsMetaData } from '../../service/fetchTmsMetaData';


/**
 * worker saga upon being called by the watcher saga makes the appropriate API call
 * and set the response data for both success and failuer calls.
 */
export function* workerGetTmsMetaDataSaga({ payload }) {
    try {
        const response = yield call(fetchTmsMetaData, [payload.controllerAbort]);
        yield put(setTmsMetaDataSuccess(response));
    } catch (error) {
        yield put(setTmsMetaDataFailure(error.toString()))
    }
}

/**
* watcher saga - looks for the respective action dispatch. 
* First argument in the take/takeLatest/takeEvery is the action type and 
* the second argument is the worker saga function.
*/
function* tmsMetaDataSaga() {
    yield takeLatest("tmsMetaDataService/getTmsMetaData", workerGetTmsMetaDataSaga);
}

export default tmsMetaDataSaga;