import axios from '../helpers/axioxInstance';
// import axios from "axios"
import URLS from '../constData/urls';

/**
 * API handler gets called by a worker saga
 */
export const saveStopEvents = async ([reqBody]) => {
    try {
        const response = await axios.put(`${URLS.SAVE_STOPS_DATA}`, reqBody);
        return { 
            ...response?.data,
            loadId: reqBody.shipmentNum,    
      };
    } catch (err) {
        if(err.response.status === 409) {
            let formattedErrorObject = {};
            const test = err?.response?.data.failedFields.map( d => {
                formattedErrorObject[d.stopNum] = {
                    ...(formattedErrorObject[d.stopNum] || {}),
                    [d.failedField]: true
                }
            })
            return { 
                ...err?.response?.data,
                loadId: reqBody.shipmentNum,    
                formattedErrorObject
          };
        } else {
            throw new Error(err?.response?.data?.exceptionDesc || "Something Went Wrong, Please Try Again!")
        }        
    }
}
