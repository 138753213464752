export const CSVLABEL_FIRST_TENDER = [
    {
        label: "load_id",
        key: "shipmentNum"
    },
    {
        label:  "service",
        key: "tariffServiceCode"
    },
    // {
    //     label: "tm_load_status",
    //     key: "tmsShipmentStatus"
    // },
    // {
    //     label: "kc_load_status",
    //     key: "orderExecutionBucketDesc"
    // },
    {
        label: "trailer_number",
        key: "trailerNum"
    },
    {
        label: "live_load",
        key:"liveLoadInd",
    },
    // {
    //     label: "stopNum",
    //     key: "stopNum"
    // },
    {
        label: "pickup_appt_date_time",
        key: "pickupApptDateTimeFormatted"
    },
    // {
    //     label: "baseline_pickup_appt_date_time",
    //     key: "baselinePickupApptDateTime", // to be decided currently not available in response
    // },
    // {
    //     label: "actual_pickup_appt_date_time",
    //     key: "actualPickupApptDateTime",
    // },
    // {
    //     label: "final_pickup_reason_code",
    //     key: "pickupReasonCodeFinal"
    // },
    // {
    //     label: "pickup_on_time",
    //     key: "pickupOnTime"
    // },
    {
        label: "delivery_appt_date_time",
        key: "deliveryApptDateTimeFormatted"
    },
    // {
    //     label: "baseline_delivery_appt_date_time",
    //     key: "baselineDeliveryApptDateTime"  // to be decided currently not available in response
    // },
    // {
    //     label: "actual_delivery_appt_date_time",
    //     key: "actualDeliveryApptDateTime"
    // },
    // {
    //     label: "final_delivery_reason_code",
    //     key:"deliveryReasonCodeFinal",
    // },
    // {
    //     label: "delivery_on_time",
    //     key:"deliveryOnTime",
    // },
    {
        label: "origin_location_name",
        key:"originName",
    },
    {
        label: "origin_city",
        key:"originCity",
    },
    {
        label: "origin_state",
        key:"originState",
    },
    {
        label: "destination_location_name",
        key:"destLocationName",
    },
    {
        label: "destination_city",
        key:"destCity",
    },
    {
        label: "destination_state",
        key:"destState",
    },
    {
        label: "origin_address",
        key:"originStreet",
    },
    {
        label: "origin_country",
        key:"originCountry",
    },
    {
        label: "destination_address",
        key:"destStreet",
    },
    {
        label: "destination_country",
        key:"destCountry",
    },
    {
        label: "carrier_id",
        key:"carrierCode",
    },
    // {
    //     label: "pickup_location_id",
    //     key:"pickupLocationID",
    // },
    // {
    //     label: "drop_location_id",
    //     key:"dropLocationID",
    // },    
    {
        label: "carrier_desc",
        key:"carrierDesc",
    },
    {
        label: "service_desc",
        key:"tariffServiceCode",
    },
    {
        label: "equip_type",
        key:"equipmentType",
    },
    // {
    //     label: "cp_shipment_type",
    //     key:"cpShipmentType",
    // }
];