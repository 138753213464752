export const filters = {
    carrierUpdateLifecycleHealthList: {
        type: "checkbox", 
        name: "pending_task",
        data: [
            { name: 'show_only_critical_pending_tasks', value: 'RED', checked: false },
            { name: 'show_only_high_alert_pending_tasks', value: 'YELLOW', checked: false }
        ]
    },
    shipmentAttributes: {
        type: "checkbox",
        name: "shipment_missing",
        data: [
            { name: 'shipment_missing_pick_stop_arrive', value: 'SHIPMENT_MISSING_PICK_STOP_ARRIVE', checked: false },
            { name: 'shipment_missing_pick_stop_depart', value: 'SHIPMENT_MISSING_PICK_STOP_DEPART', checked: false },
            { name: 'shipment_missing_drop_arrive', value: 'SHIPMENT_MISSING_DROP_STOP_ARRIVE', checked: false },
            { name: 'shipment_missing_drop_depart', value: 'SHIPMENT_MISSING_DROP_STOP_DEPART', checked: false },
        ]
    },
    shipmentNumList: {
        type: "text",
        name: "load_id",
        stringToArray: true,
        data: null
    },
    // carrierIdList: {
    //     type: "text",
    //     name: "carrier_id",
    //     stringToArray: true,
    //     data: null
    // },
    carrierCodeList: {
        type: "text",
        name: "carrier_id",
        stringToArray: true,
        data: null
    },
    carrierDescList: {
        type: "text",
        name: "carrier_desc",
        stringToArray: true,
        data: null
    },
    tariffServiceCodeList: {
        type: "text",
        name: "service",
        stringToArray: true,
        data: null
    },
    equipmentTypeList: {
        type: "text",
        name: "equip_type",
        stringToArray: true,
        data: null
    },
    trailerNumList: {
        type: "text",
        name: "trailer_number",
        stringToArray: true,
        data: null
    },
    liveLoadIndList: {
        type: "checkbox", 
        name: "live_load",
        data: [
            { name: 'yes', value: 'Y', checked: false },
            { name: 'no', value: 'N', checked: false },
            { name: 'blank', value: '-', checked: false }
        ]
    },
    tmsShipmentStatus: {
        type: "checkbox",
        name: "tm_load_status",
        data: [
            { name: "tender_accepted_tnt", value: "Tender Accepted", checked: false },
            { name: "confirming", value: "Confirming", checked: false },
            { name: "in_transit", value: "In Transit", checked: false },
            { name: "completed", value: "Completed", checked: false }
        ]
    },
    orderExecutionBucket: {
        type: "checkbox", 
        name: "kc_load_status",
        data: [
            { name: 'transportation_carrier_committed', value: 'TRANS_PLAN_TENDER_ACCEPTED', checked: false },
            { name: 'shipment_created', value: 'SHIPMENT_CREATED', checked: false },
            { name: 'checked_in_by_DC', value: 'CHECK_IN', checked: false },
            { name: 'loading_started', value: 'LOADING_STARTED', checked: false },
            { name: 'ready_for_pickup', value: 'TRANS_EXEC_READY_PICK_UP', checked: false },
            { name: 'in_transit', value: 'TRANS_EXEC_IN_TRANSIT', checked: false },
            { name: 'delivery_confirmed', value: 'TRANS_EXEC_DELIVERY_CONFIRMED', checked: false },
        ]
    },
    pickupApptDateTimeOriginTZ: {
        type: "date",
        name: "pickup_appt_date",
        dummytime: true,
        startTime: "00:00:00.000",
        endTime: "23:59:59.999",
        data: null
    },
    pickupOnTime: {
        type: "checkbox", 
        name: "pickup_on_time",
        data: [
            { name: 'yes', value: 'Y', checked: false },
            { name: 'no', value: 'N', checked: false },
            { name: 'blank', value: '-', checked: false }
        ]
    },
    deliveryApptDateTimeDestTZ: {
        type: "date",
        name: "delivery_appt_date",
        dummytime: true,
        startTime: "00:00:00.000",
        endTime: "23:59:59.999",
        data: null
    },
    deliveryOnTime: {
        type: "checkbox", 
        name: "delivery_on_time",
        data: [
            { name: 'yes', value: 'Y', checked: false },
            { name: 'no', value: 'N', checked: false },
            { name: 'blank', value: '-', checked: false }
        ]
    },
    originLocationNameList: {
        type: "text",
        name: "origin_location_name",
        stringToArray: true,
        data: null
    },
    originCityList: {
        type: "text",
        name: "origin_city",
        stringToArray: true,
        data: null
    },
    originStateList: {
        type: "text",
        name: "origin_state",
        stringToArray: true,
        data: null
    },
    originCountryList: {
        type: "text",
        name: "origin_country",
        stringToArray: true,
        data: null
    },
    destLocationNameList: {
        type: "text",
        name: "destination_location_name",
        stringToArray: true,
        data: null
    },
    destCityList: {
        type: "text",
        name: "destination_city",
        stringToArray: true,
        data: null
    },
    destStateList: {
        type: "text",
        name: "destination_state",
        stringToArray: true,
        data: null
    },
    destCountryList: {
        type: "text",
        name: "destination_country",
        stringToArray: true,
        data: null
    },    
    sentToSAP: {
        type: "checkbox", 
        name: "sent_to_SAP",
        data: [
            { name: 'sent_to_SAP_success', value: 'SUCCESS', checked: false },
            { name: 'sent_to_SAP_pending', value: 'PENDING', checked: false },
            { name: 'sent_to_SAP_failed', value: 'FAILED', checked: false }
        ]
    },
    tmsInSync: {
        type: "checkbox", 
        name: "tms_in_sync",
        data: [
            { name: 'yes', value: 'Y', checked: false },
            { name: 'no', value: 'N', checked: false }
        ]
    }    
}
