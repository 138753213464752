import React, { useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Grid from '@mui/material/Grid';
import qs from "query-string";
import CustomButton from '../common/CustomButton';
import backArrowIcon from "../../assets/backArrowIcon.svg";
import './page.sass';
import CarrierPrtformanceHeader from './CarrierPerformanceHeader';
import { globalFiltersRequired } from '../../helpers';

const DetailsPageHeader = (props) => {

    const { buttonTitle, pageName, enableLanes, requiredKeys=[] } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const carryBackward = () => {
        const queries = qs.parse(location.search);
        let filtersRequired = {};
        globalFiltersRequired.map(d => {
            filtersRequired[d] = queries[d];
        });
        let additionaParams = {
            isPickedByPrimaryList: "[]",
            tenderedToPrimaryList: "[]"
        };
        // if (!requiredKeys.includes("isPickedByPrimaryList")) {
        //     additionaParams = {
        //         isPickedByPrimaryList: "[]"
        //     }
        // }
        navigate({
            ...location,
            search: qs.stringify({
                ...filtersRequired,
                carrierCodeList: [params.carrierid],
                ...additionaParams
            }),
            pathname: `/dashboard/performance/${params.carrierid}`,
        }, { replace: true })
    }

    return (
        <Grid container>
            <Grid item xs={12} md={3} className='backButton'>
                <CustomButton
                    onClick={carryBackward}
                    datatestid={`${pageName}-back-button`}
                    btnType="septnary"
                    startIcon={<img src={backArrowIcon} alt="back" />}
                >
                    {buttonTitle}
                </CustomButton>
            </Grid>
            <Grid item xs={12} md={9} className='globalFilter'>
                <CarrierPrtformanceHeader enableLanes={enableLanes} />
            </Grid>
        </Grid>
    )
}

export default DetailsPageHeader