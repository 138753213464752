import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import accordionIcon from '../assets/accordionIcon.svg';
// import downAccordionIcon from '../assets/downAccordionIcon.svg';
// import CustomRender from "../components/common/CustomRender";
import './columns.sass';

export const COLUMNS_FIRST_TENDER = [
    // {
    //     id: 'expander',
    //     fixed: "left",
    //     Header: "",
    //     Cell: ({ row }) => {
    //         return <span data-testid={row.isExpanded ? "downAccordionIcon" : "accordionIcon"}>
    //             <img src={row.isExpanded ? downAccordionIcon : accordionIcon} alt={row.isExpanded ? "downAccordionIcon" : "accordionIcon"} />
    //         </span>
    //     }
    // },
    {
        Header: 'load_id',
        fixed: "left",
        accessor: "shipmentNum",
        Cell: (props) => {
            return <Box className="loadIdStyle">
                <Typography variant="h3" data-testid={props.value} className="textStyle">{props.value}</Typography>
            </Box>
        }
    },
    {
        Header: 'service',
        accessor: "tariffServiceCode",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    // {
    //     Header: 'tm_load_status',
    //     accessor: "tmsShipmentStatus",
    //     Cell: (props) => {
    //         return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
    //     }
    // },
    // {
    //     Header: 'kc_load_status',
    //     accessor: "orderExecutionBucketDesc",
    //     Cell: (props) => {
    //         return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
    //     }
    // },
    {
        Header: 'trailer_number',
        accessor: "trailerNum",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'live_load',
        accessor: "liveLoadInd",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'pickup_appt_date_time',
        accessor: "pickupApptDateTimeFormatted",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'delivery_appt_date_time',
        accessor: "finalDeliveryApptDateTimeFormatted",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'origin_location_name',
        accessor: "originName",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'origin_city',
        accessor: "originCity",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'origin_state',
        accessor: "originState",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'destination_location_name',
        accessor: "finalDestinationName",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'destination_city',
        accessor: "finalDestinationCity",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'destination_state',
        accessor: "finalDestinationState",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'origin_address',
        accessor: "originStreet",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'origin_country',
        accessor: "originCountry",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'destination_address',
        accessor: "finalDestinationStreet",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'destination_country',
        accessor: "finalDestinationCountry",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'carrier_id',
        accessor: "carrierCode",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'carrier_desc',
        accessor: "carrierDesc",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    {
        Header: 'service_desc',
        accessor: "tariffServiceCodeDesc",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    },
    // {
    //     Header: 'cp_shipment_type',
    //     accessor: "cpShipmentType",
    //     Cell: (props) => {
    //         return <CustomRender variant="h3" value={props.value.toLowerCase()} />
    //     }
    // }   
    {
        Header: 'equip_type',
        accessor: "equipmentType",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={props.value === '-' ? "cellStyle" : null}>{props.value}</Typography>
        }
    } 
]