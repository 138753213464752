import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, usePagination, useExpanded } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from "react-use";
import TableContainer from '@mui/material/TableContainer';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import CustomButton from "../components/common/CustomButton";
import editIcon from "../assets/editIcon.svg";
import editSelectedIcon from "../assets/editSelectedIcon.svg";
import retriggerIcon from "../assets/retriggerIcon.svg";
import retriggerSelectedIcon from "../assets/retriggerSelectedIcon.svg";
import commentIcon from "../assets/commentIcon.svg";
import { getTableData, setTableDataSuccess } from "../redux/reducers/tableDataSlice";
import { saveStopEvents, resetSaveStops } from "../redux/reducers/saveStopEventsSlice";
import { retrigger, resetRetrigger } from "../redux/reducers/retriggerEventSlice";
import StopEvents from './StopEvents';
import CustomSnackbar from './common/CustomSnackbar';
import userGroupConfig from '../userGroupConfig';
import { carrierRoles, commentsFeature, renderRowComponentRestriction } from '../helpers';
import CommentDialog from './CommentDialog';
import { getComments } from '../redux/reducers/commentsSlice';
import "./Table.sass"

const DataTable = ({ rowData, columnOrder, size, totalPages, onPage, tableName, setOnPage, refresh }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    // const columns = useMemo(() => columnOrder, []);
    const [startRecNum, setStartRecNum] = useState(0);
    const [endRecNum, setEndRecNum] = useState(0);
    const [idx, setIdx] = useState(null);
    const [openComment, setOpenComment] = useState({
        open: false,
        loadId: null,
        comments: []
    });
    const [callFetchComments, setCallFetchComments] = useState({
        callApi: false,
        loadId: null
    });
    
    const [controller] = useState(() => new AbortController())
    // const { [tableName]: { requestPayload } } = useSelector(state => state?.filterSelected);
    const { tableData, isLoading } = useSelector(state => state?.loadDetails);
    const { userInfo: { role } } = useSelector(state => state?.user);
    const { comments, commentsErrMsg } = useSelector(state => state?.userComments);


    const RenderRowSubComponent = ({ row }) => {
        const [editEvents, setEditEvents] = useState(false);
        const [updated, setUpdated] = useState({});
        const [errors, setErrors] = useState({});

        const [snack, setSnack] = useState({
            open: false,
            message: null,
            severity: 'success'
        });
        const [shouldEdit, setShouldEdit] = useState({
            allowEdit: false,
            fields: null,
            allAccess: false
        });

        const { saved } = useSelector(({ edit: { saved } }) => ({ saved }));
        const { retriggered, retriggerLoading } = useSelector(({ retrigger: { retriggered, retriggerLoading } }) => ({ retriggered, retriggerLoading }));

        useEffect(() => {
            if (row.original.shipmentNum === saved?.loadId && !saved?.completelyFailed) {
                refresh();
                // dispatch(getTableData({
                //     reqBody: {
                //         ...requestPayload
                //     },
                //     pageSize: size,
                //     pageNum: 1,
                //     tableName: tableName,
                //     controller
                // }));
                dispatch(resetSaveStops());
                editStopEvents(false);
            }
        }, [saved?.loadId, saved?.completelyFailed]);

        useEffect(() => {
            if (retriggered?.loadId.includes(row.original.shipmentNum) && !retriggered?.completelyFailed && retriggered?.status !== 'good') {
                refresh();
                // dispatch(getTableData({
                //     reqBody: {
                //         ...requestPayload
                //     },
                //     pageSize: size,
                //     pageNum: 1,
                //     tableName: tableName,
                //     controller
                // }));
                dispatch(resetRetrigger());
            }
        }, [retriggered?.loadId]);

        useEffect(() => {
            return () => {
                dispatch(resetSaveStops());
                dispatch(resetRetrigger());
            };
        }, []);

        const editStopEvents = (status) => {
            setEditEvents(status);
            if (!status) {
                setErrors({});
                setUpdated({});
                dispatch(resetSaveStops());
            }
        }

        const callRetrigger = () => {
            dispatch(retrigger({
                "shipmentNumList": [row?.original?.shipmentNum],
                "originRegion": row?.original?.originRegion
            }))
        }

        const submitStopEvents = () => {
            const payload = {
                "shipmentNum": row?.original?.shipmentNum,
                "originRegion": row?.original?.originRegion,
                stops: Object.keys(updated).map(key => {
                    return {
                        stopNum: key,
                        ...updated[key],
                        updatedFields: [...Object.keys(updated[key])]
                    }
                })
            };
            dispatch(saveStopEvents(payload))
        }

        useEffect(() => {
            const editRights = userGroupConfig?.[role];
            if (editRights === "noEdit") {
                setShouldEdit({
                    ...shouldEdit,
                    allowEdit: false,
                    editEvents
                });
            } else if (editRights === "fullEdit") {
                setShouldEdit({
                    ...shouldEdit,
                    allowEdit: true,
                    allAccess: true,
                    editEvents
                });
            } else if (editRights === "partEdit") {
                setShouldEdit({
                    ...shouldEdit,
                    allowEdit: true,
                    fields: [
                        "apptDateTime",
                        "apptReasonCode",
                        "actualArrivalTimestampCarrier",
                        "actualArrivalReasonCodeCarrier",
                        // "actualArrivalReasonCodeDescCarrier",
                        "actualDepartureTimestampCarrier",
                        "actualDepartureReasonCodeCarrier"
                    ],
                    allAccess: false,
                    editEvents
                });
            }
        }, [role, editEvents]);

        return (
            <div className="sub-row-component">
                <CustomSnackbar
                    autoHideDuration={3000}
                    {...snack}
                    handleClose={() => setSnack({
                        ...snack,
                        open: false
                    })}
                />
                <div className='button-group'>
                    {
                        shouldEdit.allowEdit &&
                        <>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                className='subrowComponentActionButtons'
                            >
                                {
                                    tableName === 'trackNtrace' && <Grid item>
                                        <CustomButton
                                            onClick={() => editStopEvents(true)}
                                            datatestid="edit-button"
                                            btnType={!editEvents ? "button" : "primary"}
                                            startIcon={editEvents ? <img src={editSelectedIcon} alt="enable-edit" /> : <img src={editIcon} alt="edit" />}
                                        >
                                            {t("edit")}
                                        </CustomButton>
                                        {
                                            !carrierRoles.includes(role) && <CustomButton
                                                onClick={() => callRetrigger()}
                                                datatestid="retrigger-button"
                                                btnType={!retriggerLoading ? "button" : "primary"}
                                                startIcon={retriggerLoading ? <img src={retriggerSelectedIcon} alt="enable-retrigger" /> : <img src={retriggerIcon} alt="retrigger" />}
                                            >
                                                {t("retrigger")}
                                            </CustomButton>
                                        }
                                    </Grid>
                                }
                                {
                                    editEvents && <Grid item>
                                        <CustomButton
                                            onClick={() => editStopEvents(false)}
                                            datatestid="cancel-button"
                                            btnType={"quaternary"}
                                        >
                                            {t("cancel")}
                                            &nbsp;&nbsp;
                                        </CustomButton>
                                        <CustomButton
                                            onClick={() => submitStopEvents()}
                                            datatestid="submit-button"
                                            btnType={"secondary"}
                                            disabled={Object.values(errors).includes(true) || !Object.keys(updated).length}
                                        >
                                            {t("submit_changes")}
                                        </CustomButton>
                                    </Grid>
                                }
                            </Grid>
                        </>
                    }
                </div>
                <StopEvents
                    eventsData={row.original.stops}
                    edit={editEvents}
                    setUpdated={setUpdated}
                    shouldEdit={shouldEdit}
                    userGroup={role}
                    errors={errors}
                    setErrors={setErrors}
                />
            </div>
        )
    }

    const tableInstance = useTable({
        columns: columnOrder,
        data: rowData,
        initialState: {
            pageIndex: 0,
        },
        manualPagination: true,
    },
        useExpanded,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        // page,
        visibleColumns,
        setPageSize,
    } = tableInstance;

    const [page, setPage] = useState(tableInstance.page);

    useDeepCompareEffect(() => {
        setPage(tableInstance.page);
    }, [tableInstance.page])

    useEffect(() => {
        setPageSize(size)
        return () => {
            controller.abort();
        };
    }, [])

    useEffect(() => {
        if (!isLoading) {
            if (tableData?.shipments?.length === 0) {
                setStartRecNum(0);
                setEndRecNum(0);
            } else if (onPage > 1) {
                let num = onPage - 1;
                setStartRecNum(num * size + 1);
                setEndRecNum(num * size + tableData?.shipments?.length);
            } else if (onPage === 1 || tableData?.shipments?.length) {
                setStartRecNum(1);
                setEndRecNum(tableData?.shipments?.length);
            }
        }

    }, [tableData?.shipments, isLoading, onPage]);

    useEffect(() => {
        if (callFetchComments?.callApi) {
            dispatch(getComments({
                originRegion: "NA",
                shipmentNum: callFetchComments?.loadId,
                feature: commentsFeature
            }))
        }
    }, [callFetchComments?.callApi])

    useEffect(() => {
        if (commentsErrMsg === 'success') {
            let shipmentsData = tableData?.shipments.map(item => {
                let shipment = {
                    ...item
                };
                if (item.shipmentNum === comments?.shipmentNum) {
                    shipment.totalComments = comments.totalComments ? comments.totalComments : "+"
                }
                return shipment;
            });
            dispatch(setTableDataSuccess({
                ...tableData,
                shipments: shipmentsData
            }))
            setOpenComment({
                open: true,
                loadId: callFetchComments.loadId,
                comments: comments?.comments || []
            });
        }
    }, [commentsErrMsg]);

    const handlePageChange = (event, value) => {
        setOnPage(value);
        // dispatch(getTableData({
        //     reqBody: {
        //         ...requestPayload
        //     },
        //     pageSize: size,
        //     pageNum: value,
        //     tableName: tableName,
        //     controller
        // }))
    }

    const handleRowClick = (index, rowProps) => {
        setIdx(index);
        let arr = [];
        let y = page.forEach(item => {
            let obj = {};
            if (item.index === index) {
                obj = {
                    ...item,
                    isExpanded: item.isExpanded ? false : true,
                }
            } else {
                obj = {
                    ...item,
                    isExpanded: false
                }
            }
            arr.push(obj)
        })
        setPage(arr);
        rowProps.onClick();
    }

    return (
        <>
            <TableContainer className='tableContainer'>
                <Table stickyHeader aria-label="sticky table" className="table" {...getTableProps()} data-testid="table">
                    <TableHead className="head">
                        {
                            headerGroups.map(headerGroup => (
                                <TableRow {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map(column => {
                                            return (
                                                <TableCell {...column.getHeaderProps()}>
                                                    {
                                                        column.render('Header') === 'comment'
                                                            ? <img src={commentIcon} alt="commentIcon" />
                                                            : <Typography variant="h2" className="tableHeader" data-testid={column.render('Header')}>{t(column.render('Header'))}</Typography>
                                                    }
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>)
                            )
                        }

                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {
                            !tableData?.shipments?.length
                                ? <TableRow>
                                    <TableCell colSpan={visibleColumns.length} className="noRecodsRow">
                                        <Typography variant='h3' data-testid="no_data_to_display" className="noData">{isLoading ? t('loading_records') : t('no_data_to_display')}</Typography>
                                    </TableCell>
                                </TableRow>
                                : page.map((row, index) => {
                                    prepareRow(row);
                                    const rowProps = row.getToggleRowExpandedProps({ title: undefined });
                                    return (
                                        <Fragment key={index}>
                                            <TableRow data-testid={`table-row-${index}`} className="row" {...row.getRowProps()} {...rowProps} onClick={() => handleRowClick(index, rowProps)}>
                                                {
                                                    row.cells.map(cell => {
                                                        return (
                                                            <TableCell {...cell.getCellProps()} className="bodyCell">
                                                                {cell.render('Cell', { setCallFetchComments })}
                                                            </TableCell>
                                                        )
                                                    })
                                                }
                                            </TableRow>
                                            {row.isExpanded && index === idx && !renderRowComponentRestriction.includes(tableName) && (
                                                <TableRow className='sub-row'>
                                                    <TableCell colSpan={visibleColumns.length}>
                                                        <RenderRowSubComponent row={row} />
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </Fragment>
                                    )
                                })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className='paginationDiv'>
                <Pagination
                    data-testid='pagination'
                    className='pagination'
                    count={totalPages}
                    shape="rounded"
                    page={onPage}
                    siblingCount={1}
                    boundaryCount={1}
                    onChange={handlePageChange}
                />
                <div className='rowsPerPage'>
                    <Typography variant='h5' className='recordTextStyle'>{`${t('displaying')} ${startRecNum} - ${endRecNum} of ${tableData?.totalRecords} `}</Typography>
                </div>
            </div>
            {openComment?.open && <CommentDialog
                openComment={openComment}
                setOpenComment={setOpenComment}
                setCallFetchComments={setCallFetchComments}
            />}

        </>
    )
}

export default DataTable