export const CSVLABEL_TRAILER_POOL = [
    {
        label: "date",
        key: "date"
    },
    {
        label: "origin_location",
        key: "originLocation"
    },
    {
        label: "site_number",
        key: "siteNum"
    },
    {
        label: "service",
        key: "tariffServiceCode"
    },
    {
        label: "target_pool",
        key: "trailerPoolTarget"
    },
    {
        label: "actual",
        key: "trailerPoolActual"
    },
    {
        label: "target_delta",
        key: "targetDelta"
    },
    {
        label: "daily_compliance_percentage",
        key: "trailerPoolCompliancePercentage"
    },
    {
        label: "out_service_trailers",
        key: "outOfServiceTrailers"
    }
];