export const TRANSLATIONS_EN = { 

    /* 1. Track & Trace */

    // Header - Tab
    track_n_trace: "Track & Trace",
    carrier_performance: "Carrier Performance",

    // Header - Profile Menu
    appendix: "Appendix",
    logout: "Logout",

    // Track & Trace Header
    enter_load_number: "Enter Load #",

    // Table - Header
    load_id: "Load ID",
    carrier_id: "Carrier ID",
    carrier_code: "Carrier Code",
    carrier_desc: "Carrier Desc",
    service: "Service ID",
    service_desc: "Service Desc",
    equip_type: "Equip Type",
    trailer_number: "Trailer Number",
    tm_load_status: "TM Load Status",
    kc_load_status: "KC Load Status",
    pickup_appt_date_time: "Pickup Appt Date/Time",
    pickup_on_time: "Pickup On Time",
    delivery_appt_date_time: "Delivery Appt Date/Time",
    delivery_on_time: "Delivery On Time",
    origin_location_name: "Origin Location Name",
    origin_address: "Origin Address",
    origin_city: "Origin City",
    origin_state: "Origin State",
    origin_country: "Origin Country",
    destination_location_name: "Dest Location Name",
    destination_address: "Dest Address",
    destination_city: "Dest City",
    destination_state: "Dest State",
    destination_country: "Dest Country",
    time_left: "Time Remaining to Report",
    pending_task: "Pending Event",
    sent_to_SAP: "Sent To SAP",
    tms_in_sync: "TMS In Sync",
    live_load: "Live Load",    

    //Table - Body
    no_data_to_display: "No Results Found",
    loading_records: "Loading...",

    // Table - subRowComponent
    edit: "Edit",
    cancel: "Cancel",
    submit_changes: "Submit Changes",
    retrigger: "Retrigger",
    arrive_at_pick_stop: "Arrive at Pick Stop",
    depart_from_pick_stop: "Depart from Pick Stop",
    arrive_at_drop_stop: "Arrive at Drop Stop",
    depart_from_drop_stop: "Depart from Drop Stop",
    location: "Location",
    pickup_location: "Pickup Location",
    pickup_appt_data_time: "Pickup Appt Date/Time",
    planned_pickup_appt_date_time: "Planned Pickup Appt Date/Time",
    pickup_appt_reason_code: "Planned Pickup Appt Reason Code",
    carrier_actual_pickup_data_time: "Carrier Actual Pickup Date/Time",
    carrier_actual_depart_data_time: "Carrier Actual Depart Date/Time",
    carrier_actual_pickup_reason_code: "Carrier Actual Pickup Reason Code",
    final_pickup_reason_code: "Final Pickup Reason Code",
    kc_actual_date_time: "FourKites Actual Pickup Date/Time",
    kc_actual_depart_date_time: "FourKites Actual Depart Date/Time",
    kc_delivery_actual_date_time: "FourKites Actual Delivery Date/Time",
    appt_date_time: "Appt. Date/Time",
    reason_code: "Reason Code",
    latest: "Latest",
    old: "Old",
    data_missing: "Data missing",
    drop_location: "Destination Location",
    planned_delivery_appt_date_time: "Planned Delivery Appt Date/Time",
    planned_appt_reason_code: "Planned Delivery Appt Reason Code",
    carrier_actual_delivery_data_time: "Carrier Actual Delivery Date/Time",
    carrier_actual_delivery_reason_code: "Carrier Actual Delivery Reason Code",
    final_delivery_reason_code: "Final Delivery Reason Code",
    carrier_actual_delivery_depart_reason_code: "Carrier Actual Depart Reason Code",
    carrier_actual_pickup_depart_reason_code: "Carrier Actual Depart Reason Code",
    carrier_pickup_on_time: "Carrier Pickup on Time",
    carrier_delivery_on_time: "Carrier Delivery on Time",

    // Pagination
    displaying: "Displaying",

    // Pending Task
    pending_tasks: "Pending Tasks",

    // Filters
    // status: "Status",
    // origin_name: "Origin Name",
    destination_name: "Destination Name",
    shipment_missing: "Missing Event",
    enter: "Enter",
    transportation_carrier_committed: "Transportation Carrier Committed",
    shipment_created: "Shipment Created",
    checked_in_by_DC: "Checked in by DC",
    loading_started: "Loading Started",
    ready_for_pickup: "Ready for Pickup",
    in_transit: "In Transit",
    delivery_confirmed: "Delivery Confirmed",
    shipment_missing_pick_stop_arrive: "Arrive at Pick Stop",
    shipment_missing_pick_stop_depart: "Depart from Pick Stop",
    shipment_missing_drop_arrive: "Arrive at Drop Stop",
    shipment_missing_drop_depart: "Depart from Drop Stop",
    // show_only_critical_pending_tasks: "Urgent Priority (Red Criteria)",
    // show_only_high_alert_pending_tasks: "High Priority (Yellow Criteria)",
    show_only_critical_pending_tasks: "Missing Carrier Data - FourKites Data Received",
    show_only_high_alert_pending_tasks: "Urgent - No Event Data",
    yes: "Yes",
    no: "No",
    pickup_appt_date: "Pickup Appt Date",
    delivery_appt_date: "Delivery Appt Date",
    tender_accepted_tnt: "Tender Accepted",
    confirming: "Confirming",
    completed: "Completed",
    blank: "Blank",
    clear_all: "Clear All",
    sent_to_SAP_success: "Success",
    sent_to_SAP_pending: "Pending",
    sent_to_SAP_failed: "Failed",

    // Date Wrapper
    start_date: "Start Date",
    end_date: "End Date",
    to: "to",

    // Enum
    arrive_at_pickup: "Arrive at Pickstop",
    depart_from_pickup: "Depart from Pickstop",
    arrive_at_drop: "Arrive at Dropstop",
    depart_from_drop: "Depart from Dropstop",

    // CSV
    load_details: "Load_Details",
    download_started: "Exporting Load Details in progress. This might take a couple of seconds",
    download_completed: "Export successful",
    no_rec_to_download: "No records to download.",
    export_in_progress: "Export in progress",
    stopNum: "Stop #",
    actual_pickup_appt_date_time: "Actual Pickup Appt Date/Time",
    baseline_pickup_appt_date_time: "Baseline Pickup Appt Date/Time",
    actual_delivery_appt_date_time: "Actual Delivery Appt Date/Time",
    baseline_delivery_appt_date_time: "Baseline Delivery Appt Date/Time",
    drop_location_id: "Drop Location ID",
    pickup_location_id: "Pickup Location ID",
    comment: "Comments",

    // edit load
    save: "Save",
    delete: "Delete Time",
    
    // Text Field
    info_text: "Enter single, multiple or use * to search",

    // Custom Calendar
    select_date_time: "Select Date/Time",

    // Custom Dropdown
    select_option: "Select Option",

    // ApptHistory
    changed_by: "Changed By",
    changed_at: "Changed At",
    note_appt_history_delivery: "*Date used to calculate on time delivery",
    note_appt_history_pickup: "*Date used to calculate on time pickup",

    // Error Message
    please_update: "Please update",
    errorText : "Something Went Wrong, Please Try Again!",

    // Dialog
    pickup: "Pick stop",
    delivery: "Drop stop",
    success: "updated successfully",
    failed_title: "Failed to Update",
    failed: "failed to update",
    partial: "Updated Except",
    due_to: "due to",
    close: "Close",

    // Snackbar messages
    retrigger_successful: "Re-triggered Successfully",
    info_already_sent: "All required info already sent to target systems",

    // Comment
    load: "Load",
    new_message: "New Message",
    no_comments_yet: "No Comments Yet",
    enter_comment: "Enter Comment (max limit 500 characters)",
    text_limit: "Characters left",
    search_comments_failure: "Failed to load comments. Please try again later.",
    add_comment_failure: "Failed to add comment. Please try again later",
    id: "id",

    // Last Update
    last_updated_from_TMS: "Last Updated from TMS on",
    at: "at",

    /* 2. Carrier Performance */

    on_time_delivery: "On Time Delivery",
    on_time_pickup: "Live Load On Time Pickup",
    billing_accuracy: "Billing Accuracy",
    carrier_event_response_efficency: "Carrier Event Response Efficency",
    fourKites_tracking_consistency: "FourKites Tracking Consistency",
    first_tender_compliance: "First Tender Compliance",
    lane_award_compliance: "Lane Award Compliance",
    moved_on_spot_rate: "Moved On Spot Rate",
    lane: "Lane",
    awarded: "Awarded",
    tendered: "Tendered",
    tenders_accepted_with_contract_price: "Tenders Accepted With Contract Price",
    compliance: "Compliance",
    tenders_accepted_over_compliance: "Tenders Accepted Over Compliance",
    tenders_accepted_in_freight_auction: "Tenders Accepted In Freight Auction",
    total_compliance: "Total Compliance",
    target: "Target",
    export: "Export",
    all_lanes: "All Lanes",
    awarded_lanes: "Awarded Lanes",
    non_awarded_lanes: "Non-Awarded Lanes",
    total_loads_accepted: "Total Loads Accepted",
    in_contract: "In Contract",
    in_spot_market: "In Spot Market",
    total_loads: "Total Loads",
    delivered_stops: "Delivered Stops",
    on_time: "On Time",
    on_time_delivered_stops: "On Time Delivered Stops",
    late_stops: "Late Stops",
    sto: "STO",
    stops: "Stops",
    cust: "Customer",
    vendor_inbound: "Vendor Inbound",
    picked_stop: "Picked Stops",
    on_time_picked_stops: "On time Picked Stops",
    late_picked_stops: "Late Picked Stops",
    planned_delivery_appointment_date: "Planned Delivery Appointment Date",
    origin_location: "Origin Location",
    search: "Search",
    select_carrier: "Select Carrier",
    search_carrier : "Search Carrier",
    continue: "Continue",
    multi_select: "Multiple Selected",
    date: "Date",
    response_expectation: "Response Expectation",
    hours: "hours",

    // First Tender Complaiance Card
    tender_accepted: "Accepted",
    tender_rejected: "Rejected",
    tender_no_response: "No response",
    acceptance_target: "Acceptance Target",
    // tenders: "Tenders",
    // accepted: "Accepted",
    // no_response: "No Response",
    // rejected: "Rejected",

    // Lane Award Compliance 
    show_only_non_compliant: "Show only Non Compliant",

    // Carrier Event Response Efficency Card
    total_response_rate: "Total Response Rate",
    on_time_response_rate: "On Time Response Rate",
    arrival_at_pickstop: "Arrival at Pickstop",
    departure_from_pickstop:"Departure from Pickstop",
    arrival_at_dropstop: "Arrival at Dropstop",
    departure_from_dropstop: "Departure from Dropstop",
    number_of_pickstops: "Number of Pickstops",
    number_of_dropstops: "Number of Dropstops",

    // FourKites Tracking Consistency Card
    tracked: "Tracked",
    tracking_quality: "Tracking Quality",

    no_data: "No Data Found",
    loading: "Loading...",
    
    cp_shipment_type: "Order Type",
    kpi_note_delivery_date: "Based upon selected delivery date",
    kpi_note_pickup_date: "Based upon selected pickup date",
    kpi_note_fourkites_completion_date: "Based Upon selected Fourkites Completion Date",
    kpi_invoice_paid_date: "Based Upon selected Invoice paid date",
    kpi_note_billing: "Origin and Service ID filters will be inactive for this KPI. For further information, contact your KC Freight settlement contact",

    tender_response: "Tender Response",
    week: "Week",
    trailer_pool_compliance: "Trailer Pool Compliance",

    // Billing Accuracy
    total_invoices: "Total Invoices",
    invoices: "Invoices",
    auto_approved: "Auto Approved",

    // Trailer Pool Compliance
    site_number: "Site Number",
    target_pool: "Target Pool",
    actual: "Actual",
    target_delta: "Target Delta",
    daily_compliance_percentage: "Daily Compliance %",
    out_service_trailers: "Out Service Trailers",
    kpi_note_trailer_pool: "Based upon selected DY+ record date"
}