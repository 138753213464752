import React from 'react';
import Grid from '@mui/material/Grid';
import PieChart from '../../charts/PieChart';

const PieChartWrapperComponent = (props) => {

    const { loading, data, centerTitle, centerSubTitle, title, gotoPath } = props;

    return (
        <div>
            <PieChart
                data={data}
                loading={loading}
                centerTitle={centerTitle}
                centerSubTitle={centerSubTitle}
                title={title}
                gotoPath={gotoPath}
            />
        </div>
    )
}

export default PieChartWrapperComponent