export const errorFieldPickup = {
    apptDateTime: "planned_pickup_appt_date_time",
    apptReasonCode: "pickup_appt_reason_code",
    actualArrivalTimestampCarrier: "carrier_actual_pickup_data_time",
    actualArrivalReasonCodeDescCarrier: "carrier_actual_pickup_reason_code",
    actualArrivalTimestampKC: "kc_actual_date_time",
    actualDepartureTimestampCarrier: "carrier_actual_depart_data_time",
    actualDepartureReasonCodeDescCarrier: "carrier_actual_delivery_depart_reason_code",
    actualDepartureTimestampKC: "kc_actual_depart_date_time"
}

export const errorFieldDrop = {
    apptDateTime: "planned_delivery_appt_date_time",
    apptReasonCode: "planned_appt_reason_code",
    actualArrivalTimestampCarrier: "carrier_actual_delivery_data_time",
    actualArrivalReasonCodeDescCarrier: "carrier_actual_delivery_reason_code",
    actualArrivalTimestampKC: "kc_delivery_actual_date_time",
    actualDepartureTimestampCarrier: "carrier_actual_delivery_data_time",
    actualDepartureReasonCodeDescCarrier: "carrier_actual_delivery_depart_reason_code",
    actualDepartureTimestampKC: "kc_actual_depart_date_time"
}