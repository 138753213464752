import { all } from 'redux-saga/effects'
import tableSaga from './tableDataSaga'
import filterSaga from './filterSaga'
import csvDataSaga from './csvDataSaga';
import tmsMetaDataSaga from './tmsMetaDataSaga';
import saveStopEventsSaga from './saveStopEventsSaga';
import userInfoSaga from './userInfoSaga';
import retriggerEventSaga from './retriggerEventSaga';
import commentsSaga from './commentsSaga';
import addCommentSaga from './addCommentSaga';

/** Carrier Performance  */ 
import locationMetadataSaga from './locationMetadataSaga';
import carrierServiceMetadataSaga from './carrierServiceMetadataSaga';
import summarySaga from './summarySaga';
import carrierInfoSaga from './carrierInfoSaga';

/**
 * A root Saga aggregates multiple Sagas to a single entry point for the sagaMiddleware to run. 
 */
export default function* rootSaga() {
    yield all([
        /** Track & Trace  */ 
        tableSaga(),
        filterSaga(),
        csvDataSaga(),
        tmsMetaDataSaga(),
        saveStopEventsSaga(),
        userInfoSaga(),
        retriggerEventSaga(),
        commentsSaga(),
        addCommentSaga(),

        /** Carrier Performance  */ 
        locationMetadataSaga(),
        carrierServiceMetadataSaga(),
        summarySaga(),
        carrierInfoSaga()
    ]);
}