import { createSlice } from "@reduxjs/toolkit";

const locationMetadataSlice = createSlice({
    name: "locationMetadata",
    initialState: {
        locMetadata: [],
        isLocMetadataLoading: false,
        locErrorMessage: undefined
    },
    reducers: {
        getLocationMetadata: (state) => {
            state.isLocMetadataLoading = true;
            state.locMetadata = []
        },
        setLocationMetadataSuccess: (state, action) => {
            state.isLocMetadataLoading = false;
            state.locMetadata = action.payload;
        },
        setLocationMetadataFailure: (state, action) => {
            state.isLocMetadataLoading = false;
            state.locMetadata = [];
            state.locErrorMessage = action.payload
        },
        resetLocationMetadata: (state) => {
            state.locMetadata = [];
            state.isLocMetadataLoading = false;
            state.locErrorMessage = undefined
        }
    }
});

export const {
    getLocationMetadata,
    setLocationMetadataSuccess,
    setLocationMetadataFailure,
    resetLocationMetadata
} = locationMetadataSlice.actions;

export default locationMetadataSlice.reducer;
