export const filterRequestPyaloadLaneAward = {
    "searchType": "CARRIER_PORTAL_ARCHIVE_PERFORMANCE_FIRST_TENDER_COMPLIANCE", // send always
    "originRegion": "NA", // send always
    "awardCompliant": null,
    "carrierCodeList": [], // Carrier ID
    "awardWindow": null, // Week
    "tariffServiceCodeList": [], // Service 
    "originCityStateList": [], // Origin Location name
    "orderBy": [
        {
        "key": "awardedAndContractAcceptedDiff",
        "ordering": "DESC"
        },

        {
        "key": "awardComplianceLane",
        "ordering": "ASC"
        },

        {
        "key": "awardComplianceStartDate",
        "ordering": "ASC"
        }
    ]
}


export const optionalFilterPayloadLAC = {
    "awardCompliant": "N"
}

export const defaultFilterPayloadLAC = {
    "searchType": "CARRIER_PORTAL_ARCHIVE_PERFORMANCE_LANE_AWARD_COMPLIANCE", // send always
    "originRegion": "NA", // send always
    "orderBy": [
        {
        "key": "awardedAndContractAcceptedDiff",
        "ordering": "DESC"
        },

        {
        "key": "awardComplianceLane",
        "ordering": "ASC"
        },

        {
        "key": "awardComplianceStartDate",
        "ordering": "ASC"
        }
    ] // send always in archive api 
}