export const CSVLABEL_LANE_AWARD = [
    {
        label: "lane",
        key: "lane"
    },
    {
        label: "awarded",
        key: "awardedLoads"
    },
    {
        label: "service",
        key: "tariffServiceCode"
    },
    {
        label: "week",
        key: "week"
    },
    {
        label: "tendered",
        key: "tenderedLoads"
    },
    {
        label: "tenders_accepted_with_contract_price",
        key: "tenderAcceptedInContract"
    },
    {
        label: "compliance",
        key: "compliancePercentage"
    },
    {
        label: "tenders_accepted_over_compliance",
        key: "tenderAcceptedOverCompliancePercentage"
    },
    {
        label: "tenders_accepted_in_freight_auction",
        key: "tenderAcceptedInSpot"
    }
];