import { version } from "./lib/version";

const userGroupConfig = {
    "development": {
        ct_carrier_portal_kc_read_dev: "noEdit",
        ct_carrier_portal_ebam_write_dev: "partEdit",
        ct_carrier_portal_kc_write_dev: "fullEdit",
    },
    "test": {
        ct_carrier_portal_kc_read_qa: "noEdit",
        ct_carrier_portal_ebam_write_qa: "partEdit",
        ct_carrier_portal_kc_write_qa: "fullEdit",
    },
    "production": {
        ct_carrier_portal_kc_read_prod: "noEdit",
        ct_carrier_portal_ebam_write_prod: "partEdit",
        ct_carrier_portal_kc_write_prod: "fullEdit",
    }
}
console.log(process.env.REACT_APP_ENV, "Version ", version);

export default userGroupConfig[process.env.REACT_APP_ENV];