import React, { useEffect } from 'react'
import { useOktaAuth } from "@okta/okta-react";
import { Link, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import whiteKCLogo from '../assets/whiteKCLogo.svg';
import './Home.sass'

const Home = () => {

  const { authState, oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const to = "/dashboard/tracking";
    if (authState?.idToken?.idToken) {
      navigate(to, { replace: true })
    }
  }, [authState?.idToken?.idToken])

  return (
    <div data-testid="home" className="login">
      {!authState?.isAuthenticated ?
        <>
          <Box className="loginBox">
            <img src={whiteKCLogo} alt="Logo" />
          </Box>
          <div className='welcomeText'>
            Carrier Web Portal
          </div>
          <Link to="/" className='loginDiv' data-testid="login_button" onClick={() => oktaAuth.signInWithRedirect()}>
            Log In &nbsp;
            <ArrowForwardRoundedIcon fontSize='large' />
          </Link>
        </>
        : navigate("/dashboard", { replace: true })
      }
    </div>
  )
}

export default Home