import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from "moment-timezone";
import { useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { useDeepCompareEffect } from "react-use";
import { isJsonParsable, dateFormat } from "../../helpers";

export default function CustomDateRange({ label, isOpen=false, queryStringKey }) {
    const [open, setOpen] = React.useState(() => isOpen);

    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState(() => Object.fromEntries([...searchParams]));

    const [dates, setDates] = React.useState(() => {
        const urlDates = isJsonParsable(searchParams.get(queryStringKey));
        return {
            startTime: urlDates?.startTime ? moment(urlDates?.startTime, dateFormat) : moment().startOf("month"),
            endTime: urlDates?.endTime ? moment(urlDates?.endTime, dateFormat) : moment()
        }
    });

    useEffect(() => {
        setSearch(Object.fromEntries([...searchParams]))
    }, [searchParams])

    useDeepCompareEffect(() => {
        const urlDates = isJsonParsable(searchParams.get(queryStringKey));
        setDates({
            startTime: urlDates?.startTime ? moment(urlDates?.startTime, dateFormat) : moment().startOf("month"),
            endTime: urlDates?.endTime ? moment(urlDates?.endTime, dateFormat) : moment()
        })
    }, [searchParams.get(queryStringKey)])


    return (
        <div data-testid="custom_auto_complete">
            <DateRangePicker
                initialSettings={{
                    startDate: dates.startTime,
                    endDate: dates.endTime
                }}
                onApply={(e, p) => {
                    setSearchParams({
                        ...search,
                        [queryStringKey]: JSON.stringify({
                            startTime: p.startDate.format(dateFormat),
                            endTime: p.endDate.format(dateFormat)
                        })
                    })
                }}
                onShowCalendar={() => setOpen(true)}
                onHide={() => setOpen(false)}
            >
                <Button
                    className="autocompletebutton"
                    data-testid="daterange-button"
                    size={"small"}
                    startIcon={<AccessTimeIcon />}
                    endIcon={open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    type="button"
                >
                    <div className="dateRangeSection">
                        <Typography variant="h6" className="dateTitle">{label}</Typography>
                        <Typography variant="h6" className="dateValue">{`${dates.startTime.format('DD-MMM-YYYY')} - ${dates.endTime.format('DD-MMM-YYYY')}`}</Typography>
                    </div>
                </Button>
            </DateRangePicker>
        </div>
    );
}
