
import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ProgressChart from '../../charts/ProgressChart';
import CardNoteComponent from './CardNoteComponent';
import './Card.sass';

const CarrierEventResponseEfficencyCard = ({ carrierLoading }) => {

    const { t } = useTranslation();
    const pageName = 'carrierEventResponse';
    const [loading, setLoading] = useState(() => carrierLoading);
    const mockLoading = [{}, {}, {}, {}];

    const { summary: { carrierEventResponse: { data: { summaryResponse, responseExpectationInHours }, isLoading } } } = useSelector(state => state.chartSummary);

    useEffect(() => {
        setLoading(carrierLoading || isLoading);
    }, [carrierLoading, isLoading]);

    return (
        <div className='card1' data-testid="carrierEventResponseEfficencyCard">
            <div className='titleBox'>
                <Typography variant='h2' className='titleStyle'>{t('carrier_event_response_efficency')}</Typography>
            </div>
            <div className='subTitle'>
                <Typography variant='h3' className='subTitleStyleCER'>{t('total_response_rate')}</Typography>
            </div>
            <Grid container className='subtitleContsiner'>
                <Grid item xs={6} className='subtitleCER'>
                    <Typography variant='h5' className='subTitleStyleCER'>{`${t('number_of_pickstops')}: ${summaryResponse?.responseRatePickupStopNumbers || summaryResponse?.responseRatePickupStopNumbers === 0 ? summaryResponse?.responseRatePickupStopNumbers : '-'}`}</Typography>
                </Grid>
                <Grid item xs={6} className='subtitleCER'>
                    <Typography variant='h5' className='subTitleStyleCER'>{`${t('number_of_dropstops')}: ${summaryResponse?.responseRateDropStopNumbers || summaryResponse?.responseRateDropStopNumbers === 0 ? summaryResponse?.responseRateDropStopNumbers : '-'}`}</Typography>
                </Grid>
            </Grid>
            <div className='styleCRECBars'>
                {
                    (loading ? mockLoading : summaryResponse?.responseRate)?.map(item => <div className='styleFKYCBar'>
                        <ProgressChart
                            loading={loading}
                            value1={loading ? '-' : item.greenCount}
                            value2={loading ? '-' : item.redCount}
                            value1Title={<Typography variant='h3' className='subTitleStyle'>{t(item.label)}</Typography>}
                            value2Title={<>
                                <div className='ratio'>
                                    <Typography variant='h1' className={item.responsePercentage > item.targetPercentage ? 'good' : 'bad'}>{!item.responsePercentage ? '-' : `${item.responsePercentage}%`}</Typography>
                                    &nbsp; | &nbsp;
                                    <Typography variant='h1' className='target'>{!item.targetPercentage ? '-' : `${item.targetPercentage}% ${t('target')}`}</Typography>
                                </div>
                            </>}
                            color={item.responsePercentage > item.targetPercentage ? "primary" : "secondary"}
                            noRail={true}
                            gotoPath={pageName}
                        />
                    </div>)
                }
            </div>

            <Divider className='dividerStyle' />

            <div className='subTitle'>
                <Typography variant='h3' className='subTitleStyleCER'>{t('on_time_response_rate')}</Typography>
                <div className='responseBox'>
                    <Typography variant='h3' className='responseExpectation'>{t('response_expectation')}</Typography>
                    <Typography variant='h4' className='hoursText'>{`${loading || !responseExpectationInHours ? '-' : responseExpectationInHours} ${t('hours')}`}</Typography>
                </div>
            </div>
            <Grid container className='subtitleContsiner'>
                <Grid item xs={6} className='subtitleCER'>
                    <Typography variant='h5' className='subTitleStyleCER'>{`${t('number_of_pickstops')}: ${summaryResponse?.onTimeResponsePickupStopNumbers || summaryResponse?.onTimeResponsePickupStopNumbers === 0 ? summaryResponse?.onTimeResponsePickupStopNumbers : '-'}`}</Typography>
                </Grid>
                <Grid item xs={6} className='subtitleCER'>
                    <Typography variant='h5' className='subTitleStyleCER'>{`${t('number_of_dropstops')}: ${summaryResponse?.onTimeResponseDropStopNumbers || summaryResponse?.onTimeResponseDropStopNumbers === 0 ? summaryResponse?.onTimeResponseDropStopNumbers : '-'}`}</Typography>
                </Grid>
            </Grid>
            <div className='styleCRECBars'>
                {
                    (loading ? mockLoading : summaryResponse?.onTimeResponse)?.map(item => <div className='styleFKYCBar'>
                        <ProgressChart
                            loading={loading}
                            value1={item.greenCount}
                            value2={item.redCount}
                            value1Title={<Typography variant='h3' className='subTitleStyle'>{t(item.label)}</Typography>}
                            value2Title={<>
                                <div className='ratio'>
                                    <Typography variant='h1' className={item.onTimeResponsePercentage > item.targetPercentage ? 'good' : 'bad'}>{!item.onTimeResponsePercentage ? '-' : `${item.onTimeResponsePercentage}%`}</Typography>
                                    &nbsp; | &nbsp;
                                    <Typography variant='h1' className='target'>{!item.targetPercentage ? '-' : `${item.targetPercentage}% ${t('target')}`}</Typography>
                                </div>
                            </>}
                            color={item.onTimeResponsePercentage > item.targetPercentage ? "primary" : "secondary"}
                            noRail={true}
                            gotoPath={pageName}
                        />
                    </div>)
                }
            </div>
            <CardNoteComponent note={t('kpi_note_delivery_date')} />
        </div>
    )
}

export default CarrierEventResponseEfficencyCard;