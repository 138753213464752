import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDeepCompareEffect } from "react-use";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Grid from '@mui/material/Grid';
import OnTimePickupCard from "./performance/OnTimePickupCard";
import OnTimeDeliveryCard from "./performance/OnTimeDeliveryCard";
import FirstTenderComplianceCard from "./performance/FirstTenderComplianceCard";
import LaneAwardComplianceCard from "./performance/LaneAwardComplianceCard";
import BillingAccuracyCard from "./performance/BillingAccuracyCard";
import FourKitesTrackingConsistencyCard from "./performance/FourKitesTrackingConsistencyCard";
import CarrierEventResponseEfficencyCard from "./performance/CarrierEventResponseEfficencyCard";
import TrailerPoolComplianceCard from "./performance/TrailerPoolComplianceCard";
// import MovedOnSpotRateCard from "./performance/MovedOnSpotRateCard";
import CarrierSelectionDialog from "./performance/CarrierSelectionDialog";
import CarrierPerformanceHeader from "./performance/CarrierPerformanceHeader";
import { carrierRoles } from "../helpers";
import HeaderAPIHandler from "./common/HeaderAPIHandler";
import './CarrierPerformance.sass';

const CarrierPerformance = () => {

    const { carrierid } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const { userInfo: { role } } = useSelector(state => state?.user);
    const { carrierServiceMetadata } = useSelector(state => state.carrierMetadata);
    const { locMetadata } = useSelector(state => state.locationMetadata);

    const [openSelectCarrier, setOpenSelectCarrier] = useState({
        open: false,
    });
    const [carrierLoading, setCarrerLoading] = useState(() => carrierid ? false: true);
    const [carCode, setCarCode] = useState(undefined);

    useEffect(() => {
        setCarrerLoading(carrierid ? false: true)
    }, [carrierid]);    


    useEffect(() => {
        if (locMetadata.length && carrierServiceMetadata.length && !carrierRoles.includes(role)) { //Analyst
            setOpenSelectCarrier({
                open: true
            });
        }
        if(carrierRoles.includes(role) && carrierServiceMetadata.length) {
            const carrierCode = carrierServiceMetadata[0].value;
            navigate({
                ...location,
                pathname: `/dashboard/performance/${carrierCode}`,
              });
        }
    }, [carrierServiceMetadata, locMetadata, carrierid]);

    useDeepCompareEffect(() => {
        if(carrierid) {
            setCarCode(carrierid);
                navigate({
                    ...location,
                    pathname: `/dashboard/performance/${carrierid}`,
                  });
        }
    }, [carrierid])

    const isValidCarrier = () => {
        return carrierid
    }

    return (
        <div className="performanceContainer">
            <HeaderAPIHandler />
            <Grid container>
                <Grid item xs={12} className="performanceHeader">
                    <CarrierPerformanceHeader />
                </Grid>
                <Grid item xs={12} className="segments">
                    <Grid container spacing={1.5}>
                        <Grid item xs={12} md={12} lg={6}>
                            <OnTimePickupCard carrierLoading={carrierLoading} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <OnTimeDeliveryCard carrierLoading={carrierLoading} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="segments">
                    <Grid container spacing={1.5}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <FirstTenderComplianceCard carrierLoading={carrierLoading} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <LaneAwardComplianceCard carrierLoading={carrierLoading} />
                            <FourKitesTrackingConsistencyCard carrierLoading={carrierLoading} />
                        </Grid>
                    </Grid>
                </Grid> 
                <Grid item xs={12} className="segments">
                    <Grid container spacing={1.5}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <CarrierEventResponseEfficencyCard carrierLoading={carrierLoading} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <BillingAccuracyCard carrierLoading={carrierLoading} />
                            <TrailerPoolComplianceCard carrierLoading={carrierLoading} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {!carrierRoles.includes(role) && !isValidCarrier() && <CarrierSelectionDialog
                openSelectCarrier={openSelectCarrier}
                setOpenSelectCarrier={setOpenSelectCarrier}
            />}
        </div>

    )
}

export default CarrierPerformance;