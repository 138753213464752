import React from "react";
import { useTranslation } from "react-i18next";
import {
    PieChart,
    Pie,
    Cell,
} from "recharts";
import { Tooltip } from "react-tooltip";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import Typography from "@mui/material/Typography";
import qs from "query-string";
import { palette } from '../theme/ColorPalette';
import "../components/performance/custom.sass";
import { globalFiltersRequired } from "../helpers";

const COLORS = [palette.skyBlue, palette.peachPink, palette.sunshineYellow, palette.redOrange];
const LOADING_COLOR = palette.lightSpaceGey

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index, data }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            // fill="white"
            textAnchor={"middle"}
            pointerEvents={"none"}
            dominantBaseline="central"
            className="valueLable"
        >
            {data[index]?.value}
        </text>
    );
};

export default function App({
    centerTitle = null,
    centerSubTitle = null,
    colors = COLORS,
    title = null,
    valueLable = false,
    innerRadius = 60,
    outerRadius = 80,
    fill = palette.moodyBlue,
    gotoPath,
    loading,
    ...rest
}) {
    const [isOpen, setIsOpen] = React.useState({ open: false, data: null });
    const navigate = useNavigate();
    const location = useLocation();
    const { carrierid } = useParams();
    const { t } = useTranslation();
    const [data, setData] = React.useState(() => rest.data);

    React.useEffect(() => {
        if (loading) {
            setData([{ name: t('loading'), value: 100 }])
        } else {
            setData(rest.data || [])
        }
    }, [loading, rest.data])

    const goToPage = (dataprop) => {
        const queries = qs.parse(location.search);
        let filtersRequired = {};
        globalFiltersRequired.map(d => {
            filtersRequired[d] = queries[d];
        });
        const ontimeKey = gotoPath === "onTimePickup" ? "pickupOnTime": "deliveryOnTime";
        
        navigate({
            ...location,
            search: qs.stringify({
                ...filtersRequired,
                isPickedByPrimaryList: JSON.stringify([]),
                carrierCodeList: JSON.stringify([carrierid]),
                cpShipmentTypeList: JSON.stringify([dataprop.type]),
                [ontimeKey]: JSON.stringify([dataprop.key === "greenCount" ? "Y": "N"]),
            }),
            pathname: `/dashboard/performance/${carrierid}/${gotoPath}`,
        }, { replace: true })
    }

    return (
        <>
            <Tooltip
                className="tooltip"
                events={["hover"]}
                anchorSelect="#testidtop"
                isOpen={loading ? false : isOpen.open}
                content={
                    <div style={{ borderLeft: `4px solid ${COLORS[isOpen?.index]}` }}>
                        &nbsp;&nbsp;
                        {`${t(isOpen?.data?.name)}: ${isOpen?.data?.value}`}
                    </div>
                }
                place="top"
            />
            <PieChart height={200} width={200}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={valueLable ? (props) => renderCustomizedLabel({ ...props, data }) : ''}
                    fill={fill}
                    dataKey="value"
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    id="testidtop"
                    data-testid="testidtop"
                    onClick={(...props) => goToPage(props[0])}
                    onMouseEnter={(...props) => {
                        setIsOpen({
                            ...isOpen,
                            open: true,
                            data: props[0],
                            index: props[1]
                        });
                    }}
                    onMouseOut={(...props) => {
                        setIsOpen({
                            ...isOpen,
                            open: false,
                            data: props[0],
                            index: props[1]
                        });
                    }}
                >
                    {data.map((entry, index) => {
                        return <Cell
                            cursor={"pointer"}
                            key={`cell-${index}`}
                            fill={loading ? LOADING_COLOR: colors[index % colors.length]}
                            stroke="none"
                        />
                    })}
                </Pie>
                <g>
                    <text x={"50%"} y={"45%"} dy={8} fill={palette.gray60} textAnchor="middle" className="centerTitle">
                        {centerTitle}
                    </text>
                    <text x={"50%"} y={"50%"} dy={20} fill={palette.gray60} textAnchor="middle">
                        {loading ? "-": centerSubTitle}
                    </text>
                </g>
            </PieChart>
            {!loading && <Typography className="pieTitle" variant="h3">{loading ? "-": title}</Typography>}
        </>
    );
}
