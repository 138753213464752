import React from 'react';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import CustomAutoComplete from '../common/CustomAutoComplete';
import CustomDateRange from '../common/CustomDateRange';
import LanesWrapperComponent from '../FilterWrappers/LanesWrapperComponent';
import { carrierRoles } from "../../helpers";

const CarrierPerformanceHeader = ({ 
    enableLanes=false
}) => {

    const { t } = useTranslation();
    const { userInfo: { role } } = useSelector(state => state?.user);
    const tariffServiceCodeList = useSelector(state => state?.carrier?.carrierInfo?.tariffServiceCodeList);
    const { locMetadata } = useSelector(state => state?.locationMetadata);
    const { carrierServiceMetadata } = useSelector(state => state?.carrierMetadata);
    const { carrierid } = useParams();

    return (
        <>
            {enableLanes && <Box pt={1} pr={1} className={"header-filter"}>
                <LanesWrapperComponent queryStringKey="isPickedByPrimaryList" />
            </Box>}
            <Box pt={1} className={"header-filter"}>
                <CustomDateRange label={t('date')} queryStringKey="deliveryActualArrivalDatetimeFinalDestTZ"  />
            </Box>
            <Box pl={1} pt={1} className='header-filter'>
                {
                    !carrierRoles.includes(role)
                        ? <CustomAutoComplete 
                            label={t('carrier_id')} 
                            radio={true} 
                            value={carrierid} 
                            data={carrierServiceMetadata} 
                            // getNewCarrierCode={getNewCarrierCode}
                            queryStringKey={"carrierid"}
                        />
                        : <div className="carrierIDStyle">
                            <Typography varriant="body1" className="carrierIDText">{t('carrier_id')}</Typography>
                            <Typography varriant="h6" className="carrierIDTextValue">{carrierid}</Typography>
                        </div>
                }
            </Box>
            <Box pl={1} pt={1} className='header-filter'>
                <CustomAutoComplete 
                    label={t('service')} 
                    data={tariffServiceCodeList} 
                    // getList={getServiceCode} 
                    queryStringKey="tariffServiceCodeList" 
                />
            </Box>
            <Box pl={1} pt={1} className='header-filter'>
                <CustomAutoComplete 
                    label={t('origin_location')} 
                    data={locMetadata} 
                    // getList={getLocation} 
                    queryStringKey="originCityStateList" 
                />
            </Box>
        </>
    )
}

export default CarrierPerformanceHeader