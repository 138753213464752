import axios from '../helpers/axioxInstance';
import URLS from '../constData/urls';

/**
 * API handler gets called by a worker saga
 */
export const retriggerEvent = async ([reqBody]) => {
    try {
        const response = await axios.put(`${URLS.RETRIGGER_EVENT}`, reqBody);
        if (response.status === 200) {
            return {
                ...response?.data,
                loadId: reqBody.shipmentNumList,
                message: "retrigger_successful",
            };
        } else if (response.status === 204) {
            return {
                loadId: reqBody.shipmentNumList,
                message: "info_already_sent",
                status: "good"
            };
        }

    } catch (err) {
        if (err.response.status === 409) {
            return {
                ...err?.response?.data,
                loadId: reqBody.shipmentNumList,
            };
        } else {
            throw new Error(err?.response?.data?.exceptionDesc || "Something Went Wrong, Please Try Again!")
        }
    }
}
