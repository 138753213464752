import React from 'react';
import { useTranslation } from 'react-i18next';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import './CustomToggleButtonGroup.sass'


const CustomToggleButtonGroup = (props) => {

    const { t } = useTranslation();

    const {
        buttons,
        onChange,
        active,
        card
    } = props;

    return (
        <div className='buttonGroupContainer'>
            <ToggleButtonGroup
                className='buttonGroupStyle'
                color="primary"
                value={active}
                exclusive
                onChange={onChange}
                aria-label="Platform"
            >
                {
                    buttons.map(button => <ToggleButton
                        disableRipple
                        value={button}
                        key={button}
                        data-testid={`toggleButton-${button}`}
                        // className={active === button ? "toggleButtonActive" : "toggleButtonInactive"}
                        className={card
                            ? active === button
                                ? "toggleButtonActive"
                                : "toggleButtonInactive"

                            : active === button
                                ? "toggleButtonActivePage"
                                : "toggleButtonInactivePage"
                        }
                    >
                        {t(button)}
                    </ToggleButton>
                    )
                }
            </ToggleButtonGroup>
        </div>
    )
}

export default CustomToggleButtonGroup