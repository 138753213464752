import React, {useEffect} from "react";
import { 
    Outlet, useParams, useSearchParams, 
    useLocation, useNavigate, generatePath, 
} from "react-router-dom";
import qs from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { getLocationMetadata, resetLocationMetadata } from "../redux/reducers/locationMetadataSlice";
import { getCarrierServiceMetadata, resetCarrierServiceMetadata } from "../redux/reducers/carrierServiceMetadataSlice";
import { getCarrierInfo, resetCarrierInfo } from "../redux/reducers/carrierInfoSlice";
import { isJsonParsable, absolutePaths } from "../helpers";

const CarrierLayout = () => {
    const { carrierid } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    
    const { carrierServiceMetadata } = useSelector(state => state.carrierMetadata);
    const { carrierInfo } = useSelector(state => state?.carrier);
    let [searchParams] = useSearchParams();

    useEffect(() => {
        const controller = new AbortController();
        const abortController = new AbortController();
        dispatch(getLocationMetadata({ controller }));
        dispatch(getCarrierServiceMetadata({ abortController }));
        return () => {
            controller.abort();
            abortController.abort();
            dispatch(resetLocationMetadata());
            dispatch(resetCarrierServiceMetadata());
            dispatch(resetCarrierInfo());
        };
    }, []);

    useEffect(() => {
        const id = isJsonParsable(searchParams.get("carrierid"))?.[0];
        let queryParams = qs.parse(location?.search);
        if (id && carrierid && (id !== carrierid)) {
            const relativePage = location.pathname?.replace(/(.*\/)*/,"");
            const path = generatePath(absolutePaths?.[relativePage] || absolutePaths.default, {carrierid: id});
            navigate({
                ...location,
                search: qs.stringify({
                    ...queryParams,
                    tariffServiceCodeList: JSON.stringify([])
                }),
                pathname: path,
            });
        }
    }, [searchParams.get("carrierid"), carrierid]);

    const fetchServiceCodes = () => {

        let arr = carrierServiceMetadata.find(item => item?.carrierCode === carrierid);
        let serviceCode = arr?.tariffServiceCodeList.map((d, index) => {
            return {
                id: index,
                label: d,
                value: d,
                checked: false
            }
        });
        dispatch(getCarrierInfo({
            ...carrierInfo,
            carrierCode: carrierid,
            tariffServiceCodeList: serviceCode,
            summaryPayload: {
                ...carrierInfo.summaryPayload,
                carrierCodeList: [carrierid],
                tariffServiceCodeList: serviceCode
            }
        }));
    }

    useEffect(() => {
        if (carrierid && carrierServiceMetadata?.length) {
            fetchServiceCodes()
        }
    }, [carrierid, carrierServiceMetadata]);

    return <Outlet />
}

export default CarrierLayout;