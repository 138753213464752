import React from 'react';
import Typography from '@mui/material/Typography';
import { useCustomFetchDateParams } from '../../customHook/useCustomFetchDateParams';

const CardNoteComponent = ({ note }) => {
    
    return (
        <div className='note'>
            <Typography variant='h6' className='noteText'>{`${note}:`}</Typography>
            <Typography variant='subtitle1' className='noteValue'>{`${useCustomFetchDateParams()?.startTime} - ${useCustomFetchDateParams()?.endTime}`}</Typography>
        </div>
    )
}

export default CardNoteComponent