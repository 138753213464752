import React from 'react'
import clsx from 'clsx';
import { useTranslation } from 'react-i18next'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import './ApptHistory.sass'

const ApptHistory = ({ latestApptHistory, oldApptHistory }) => {

    const { t } = useTranslation();

    return (
        <TableContainer data-testid='apptHistory' className='appt-container'>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell className='cellStyle'></TableCell>
                        <TableCell className='cellStyle'>
                            <Typography variant='h5' className='headerColumn'>{t("appt_date_time")}</Typography>
                        </TableCell>
                        <TableCell className='cellStyle'>
                            <Typography variant='h5' className='headerColumn'>{t("reason_code")}</Typography>
                        </TableCell>
                        <TableCell className='cellStyle'>
                            <Typography variant='h5' className='headerColumn'>{t("changed_by")}</Typography>
                        </TableCell>
                        <TableCell className='cellStyle'>
                            <Typography variant='h5' className='headerColumn'>{t("changed_at")}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className='cellStyle'>
                            <Typography variant='h5' className='headerColumn'>{t("latest")}</Typography>
                        </TableCell>
                        <TableCell className={latestApptHistory.isBaseLineApptDateTime === 'Y' ? clsx('cellStyle', 'baseLineApptDateTime') : 'cellStyle'}>
                            <Typography variant='body2' className='dataColumn'>{latestApptHistory.isBaseLineApptDateTime === 'Y' ? `${latestApptHistory.apptDateTimeFormatted}*` : latestApptHistory.apptDateTimeFormatted}</Typography>
                        </TableCell>
                        <TableCell className={latestApptHistory.isBaseLineApptDateTime === 'Y' ? clsx('cellStyle', 'baseLineApptDateTime') : 'cellStyle'}>
                            <Typography variant='body2' className='dataColumn'>{latestApptHistory.apptReasonCodeDesc}</Typography>
                        </TableCell>
                        <TableCell className={latestApptHistory.isBaseLineApptDateTime === 'Y' ? clsx('cellStyle', 'baseLineApptDateTime') : 'cellStyle'}>
                            <Typography variant='body2' className='dataColumn'>{latestApptHistory.updatedByUserId}</Typography>
                        </TableCell>
                        <TableCell className={latestApptHistory.isBaseLineApptDateTime === 'Y' ? clsx('cellStyle', 'baseLineApptDateTime') : 'cellStyle'}>
                            <Typography variant='body2' className='dataColumn'>{latestApptHistory.updatedAtDateTime}</Typography>
                        </TableCell>
                    </TableRow>
                    {
                        oldApptHistory.length > 0 && <TableRow>
                            <TableCell rowSpan={oldApptHistory.length + 1} className='cellStyle'>
                                <Typography variant='h5' className='headerColumn'>{t("old")}</Typography>
                            </TableCell>
                        </TableRow>
                    }
                    {
                        oldApptHistory.length > 0 && oldApptHistory.map((cell, index) => <TableRow className={cell.isBaseLineApptDateTime === 'Y' ? 'baseLineApptDateTime' : null}>
                            <TableCell key={index} className='cellStyle'>
                                <Typography variant='body2' className='dataColumn'>{cell.isBaseLineApptDateTime === 'Y' ? `${cell.apptDateTimeFormatted}*` : cell.apptDateTimeFormatted}</Typography>
                            </TableCell>
                            <TableCell key={index} className='cellStyle'>
                                <Typography variant='body2' className='dataColumn'>{cell.apptReasonCodeDesc}</Typography>
                            </TableCell>
                            <TableCell key={index} className='cellStyle'>
                                <Typography variant='body2' className='dataColumn'>{cell.updatedByUserId}</Typography>
                            </TableCell>
                            <TableCell key={index} className='cellStyle'>
                                <Typography variant='body2' className='dataColumn'>{cell.updatedAtDateTime}</Typography>
                            </TableCell>
                        </TableRow>)
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ApptHistory