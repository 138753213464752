import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import logoutIcon from "../assets/logoutIcon.svg";
import appendixIcon from "../assets/appendixIcon.svg";
import reasonCodeIcon from "../assets/reasonCodeIcon.svg";
import userIcon from "../assets/userIcon.svg";
import appendix from '../assets/pdf/appendix.pdf';
import reasonCode from '../assets/pdf/reasonCode.pdf';
import './ProfileMenu.sass';

const ProfileMenu = ({ oktaAuth, user, userID }) => {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLink = () => {
    setAnchorEl(null);
    setOpen(!open);
  }
  

  return (
    <div data-testid="profileMenu">
      <Button
        size="small"
        className="profileIcon"
        data-testid="profileIcon"
        onClick={handleMenu}
        disableRipple
      >
        <img src={userIcon} alt="user" className="icon" />
        <div className="user">
          <Typography variant="h5" className="userName"> {user} </Typography>
          <Typography variant="body2" className="userId">{userID}</Typography>
        </div>
      </Button>
      <Menu
        id="menu-appbar"
        data-testid="menuAppbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLink} >
          <img src={appendixIcon} alt="appendix" className="icon" />
          <a className="linkStyle" href={appendix} target="_blank" rel="noreferrer">
            {t("appendix")}
          </a>
        </MenuItem>
        <MenuItem onClick={handleLink} >
          <img src={reasonCodeIcon} alt="reasonCode" className="icon" />
          <a className="linkStyle" href={reasonCode} target="_blank" rel="noreferrer">
            {t("reason_code")}
          </a>
        </MenuItem>
        <MenuItem onClick={() => oktaAuth.signOut("/")}>
          <img src={logoutIcon} alt="logout" className="icon" />
          <Typography variant="h6" className="logoutButton"> {t("logout")} </Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default ProfileMenu;