const { REACT_APP_OKTA_CLIENT_ID, REACT_APP_OKTA_ISSUER } = process.env;

const OktaConfig = {
    clientId: REACT_APP_OKTA_CLIENT_ID,
    issuer: REACT_APP_OKTA_ISSUER,
    redirectUri: `${window.location.origin}/callback`,
    postLogoutRedirectUri: `${window.location.origin}`,
    scopes: ["openid", "email", "profile"],
    responseType: 'id_token',
    pkce: false,
    storageManager: {
        token: {
            storageTypes: ["sessionStorage"]
        }
    }
};

process.env.REACT_APP_ENV !== 'production' && console.log("Okta COnfig", OktaConfig) 

export default OktaConfig
