import { put, takeLatest, call } from 'redux-saga/effects';
import { setLocationMetadataSuccess, setLocationMetadataFailure } from '../reducers/locationMetadataSlice';
import { fetchLocationMetadata } from '../../service/fetchLocationMetadata,';


/**
 * worker saga upon being called by the watcher saga makes the appropriate API call
 * and set the response data for both success and failuer calls.
 */
export function* workerGetLocationMetadataSaga({payload}) {
    try {
        const response = yield call(fetchLocationMetadata, [payload.controller]);
        yield put(setLocationMetadataSuccess(response));
    } catch (error) {
        yield put(setLocationMetadataFailure(error.toString()))
    }
}

/**
* watcher saga - looks for the respective action dispatch. 
* First argument in the take/takeLatest/takeEvery is the action type and 
* the second argument is the worker saga function.
*/
function* locationMetadataSaga() {
    yield takeLatest("locationMetadata/getLocationMetadata", workerGetLocationMetadataSaga);
}

export default locationMetadataSaga;