import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import CustomButton from "./common/CustomButton";
import crossIcon from '../assets/crossIcon.svg';
import { IconButton } from '@mui/material';
import './FaliureDialog.sass';


const FailureDialog = (props) => {

    const { openDialog, setOpenDialog } = props;
    const { t } = useTranslation();

    const handleClose = () => {
        setOpenDialog({
            open: false,
            message: [],
            loadId: null,
            failed: false
        });
    }

    return (
        <Dialog
            open={openDialog.open}
            classes={{
                scrollPaper: "topScrollPaper",
                paperScrollBody: "topPaperScrollBody"
            }}
        >
            <DialogTitle className='dialogTitle'>
                <IconButton
                    disableRipple
                    className='csvButtonStyles'
                    onClick={() => handleClose()}
                    data-testid={'closeIcon-dialog-button'}
                >
                    <img src={crossIcon} alt="crossIcon-dialog" />
                </IconButton>
                <Typography variant='h1' className='failedTitle'>{`${t('load_id')} ${openDialog?.loadId} ${openDialog.failed ? t('failed_title') : t('partial')}`}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <ul>
                        {
                            openDialog?.message.map((item, idx) => <li>
                                <Typography variant='h3' className='failedList'>
                                    {item}
                                </Typography>
                            </li>)
                        }
                    </ul>

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButton
                    btnType="primary"
                    onClick={() => handleClose()}
                    data-testid={'close-dialog-button'}
                >
                    {t("close")}
                </CustomButton>
            </DialogActions>


        </Dialog>
    )
}

export default FailureDialog