import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import moment from 'moment-timezone';
import qs from 'query-string';
import CustomChip from './common/CustomChip';
import { isJsonParsable, optionalFiltersByTable, stringToArray } from '../helpers';
import CustomButton from './common/CustomButton';
import "./FilterChips.sass"
import { useDeepCompareEffect } from 'react-use';

export const generateChipsData = (filters, filterSelections) => {
    let chipValues = [];
    Object.keys(filters).map(d => {
        if (filters[d].type === "text" && filterSelections[d] !== "[]" && filterSelections[d]) {
            if (filters[d].stringToArray) {
                let parts = stringToArray(filterSelections[d], d);
                parts.map(ele => {
                    chipValues.push({
                        filterLabel: filters[d].name,
                        valueLabel: ele,
                        filterData: parts,
                        filterKey: d,
                        ...filters[d]
                    })
                })
            } else {
                chipValues.push({
                    filterLabel: filters[d].name,
                    valueLabel: d,
                    filterData: filterSelections[d],
                    filterKey: d,
                    ...filters[d]
                })
            }
            return d;
        }
        if (filters[d].type === "checkbox" && filterSelections[d] !== "[]" && filterSelections[d]) {
            const parts = isJsonParsable(filterSelections[d]) || [];
            parts.map(ele => {
                filters[d].data.map(item => {
                    if(ele === item.value) {
                        chipValues.push({
                            filterLabel: filters[d].name,
                            valueLabel: item.name,
                            filterData: parts,
                            filterKey: d,
                            value: ele,
                            ...filters[d]
                        })
                    }
                })                
            })
            return d;
        }
        
        if (filters[d].type === "date") {
            const parsedDates = isJsonParsable(filterSelections[d]) || {};
            const { startTime, endTime } = parsedDates;
            if (startTime && endTime) {
                const formattedStartTime = moment(startTime, "YYYY-MM-DD").format("DD-MMM-YYYY");
                const formattedEndTime = moment(endTime, "YYYY-MM-DD").format("DD-MMM-YYYY");
                chipValues.push({
                    filterLabel: filters[d]?.name,
                    valueLabel: `${formattedStartTime} - ${formattedEndTime}`,
                    filterData: parsedDates,
                    filterKey: d,
                    ...filters[d]
                })
            }
        }
    })
    return chipValues;
}

const FilterChips = ({ tableName }) => {

    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState(() => Object.fromEntries([...searchParams]));
    const { [tableName]: { filters } } = useSelector(state => state?.filterSelected);
    const { isLoading } = useSelector(state => state?.loadDetails);
    const [chipsData, setChipsData] = useState([]);
    const params = Object.fromEntries([...searchParams]);

    useDeepCompareEffect(() => {
        setSearch(params)
        setChipsData(generateChipsData(filters, params));
    }, [params]);

    const getDeleteFilter = (chip) => {
        if (chip.type === "text" && chip.stringToArray) {
            setSearchParams(qs.stringify({
                ...search,
                [chip.filterKey]: chip.filterData.filter(d => d!== chip.valueLabel).join()
            }))
            return;
        }
        if (chip.type === "text" && !chip.stringToArray) {
            setSearchParams(qs.stringify({
                ...search,
                [chip.filterKey]: ""
            }));
            return;
        }
        if (chip.type === "checkbox") {
            setSearchParams(qs.stringify({
                ...search,
                [chip.filterKey]: JSON.stringify(chip.filterData.filter(d => d!== chip.value))
            }));
            return;
        }

        if (chip.type === "date") {
            setSearchParams(qs.stringify({
                ...search,
                [chip.filterKey]: null
            }));
            return;
        }
    }

    const clearFilter = () => {
        const defaultFilters = optionalFiltersByTable[tableName];
        let updatedObject = {};
        Object.keys(defaultFilters).map(d => {
            updatedObject[d] = defaultFilters[d] ? JSON.stringify(defaultFilters[d]): "";
            return d;
        });
        setSearchParams(qs.stringify({
            ...search,
            ...updatedObject,
            liveLoadIndList:  JSON.stringify([])
        }));
    }

    return (

        <>
            {
                chipsData?.map((item, index) => (
                    <CustomChip
                        key={index}
                        chipType="primary"
                        label={`${t(item.filterLabel)}`}
                        onDelete={() => getDeleteFilter(item)}
                        filterLabel={item.filterLabel}
                        valueLabel={`${t(item.valueLabel)}`}
                        disabled={isLoading}
                    />
                ))
            }
            {
                chipsData.length > 0 && <div className='clear-all'>
                    <CustomButton
                        onClick={() => clearFilter()}
                        datatestid="clearFilter-button"
                        btnType={"quinary"}
                        disabled={isLoading}
                    >
                        {t("clear_all")}
                    </CustomButton>
                </div>
            }
        </>
    )
}

export default FilterChips;