import { createSlice } from "@reduxjs/toolkit";

const csvDataSlice = createSlice({
    name: "csvData",
    initialState: {
        csvData: {
            onPage: 0,
            pageSize: 0,
            shipments: [],
            totalPages: 0,
            totalRecords: 0
        },
        isLoadingCSV: false,
        errorMessage: undefined
    },
    reducers: {
        getCSVData: (state) => {
            state.isLoadingCSV = true;
            state.csvData = {
                onPage: 0,
                pageSize: 0,
                shipments: [],
                totalPages: 0,
                totalRecords: 0
            }
        },
        setCSVDataSuccess: (state, action) => {
            state.isLoadingCSV = false;
            state.csvData = action.payload;
        },
        setCSVDataFailure: (state, action) => {
            state.isLoadingCSV = false;
            state.csvData = {
                onPage: 0,
                pageSize: 0,
                shipments: [],
                totalPages: 0,
                totalRecords: 0
            };
            state.errorMessage = action.payload
        },
        resetCSVData: (state) => {
            state.isLoadingCSV = false;
            state.csvData = {
                onPage: 0,
                pageSize: 0,
                shipments: [],
                totalPages: 0,
                totalRecords: 0
            };
        }
    }
});

export const { getCSVData, setCSVDataSuccess, setCSVDataFailure, resetCSVData } = csvDataSlice.actions;

export default csvDataSlice.reducer;
