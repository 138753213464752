import React from 'react';
import Typography from '@mui/material/Typography';
import ProgressChart from '../../charts/ProgressChart';

const ProgressBarWrapperComponent = ({ progressBarData, card, loading = false, ...rest }) => {

    return (
        <>
            <div className='subTitle'>
                {card
                    ? <Typography variant='h4'>{loading ? "-" : progressBarData?.title}</Typography>
                    : <span className='customTextStyle'>{loading ? "-" : progressBarData?.title}</span>
                }
                <div className='ratio'>
                    <Typography variant='h1' className={progressBarData?.subtitleValue1 < progressBarData?.subtitleValue2 ? 'bad' : 'good'}>{loading ? "-" : progressBarData?.subtitle1}</Typography>
                    &nbsp; | &nbsp;
                    <Typography variant='h1' className='target'>{loading ? "-" : progressBarData?.subtitle2}</Typography>
                </div>
            </div>
            <div className='styleFKYCBar'>
                <ProgressChart
                    {...rest}
                    value1={loading ? "-" : progressBarData?.value1}
                    value2={loading ? "-" : progressBarData?.value2}
                    value1Title={loading ? "-" : progressBarData?.value1Title}
                    value2Title={loading ? "-" : progressBarData?.value2Title}
                    color="primary"
                    loading={loading}
                />
            </div>
        </>
    )
}

export default ProgressBarWrapperComponent