import "@fontsource/outfit"

export const typography = () => {
    return {
        h1: {
            fontSize: '18px',
            fontWeight: '600',
            fontFamily: 'Outfit'
        },
        h2: {
            fontSize: '16px',
            fontWeight: '600',
            fontFamily: 'Outfit'
        },
        h3: {
            fontSize: '16px',
            fontWeight: '400',
            fontFamily: 'Outfit'
        },
        h4: {
            fontSize: '15px',
            fontWeight: '600',
            fontFamily: 'Outfit'
        },
        h5: {
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'Outfit',
        },
        h6: {
            fontSize: '14px',
            fontWeight: '400',
            fontFamily: 'Outfit',
            // color: palette.ebony,
        },
        body1: {
            fontSize: '13px',
            fontWeight: '400',
            fontFamily: 'Outfit',
            // color: palette.gray60
        },
        body2: {
            fontSize: '12px',
            fontWeight: '400',
            fontFamily: 'Outfit',
        },
        subtitle1: {
            fontSize: '12px',
            fontWeight: '600',
            fontFamily: 'Outfit',
            // color: palette.gray60
        }
    }
};