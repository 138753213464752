import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './CustomDatePicker.sass';

const CustomDatePicker = (props) => {

    const {
        label,
        value,
        onChange,
        minDate,
        maxDate
    } = props;
 
    const [date, setDate] = useState(value);

    const handleChange = (val) => {
        // console.log("date", val?.$d)
        if (val?.$d) {
            setDate(val.$d);
            onChange(val.$d);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={label}
                value={date}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
                minDate={minDate}
                maxDate={maxDate}
            />
        </LocalizationProvider>
    )
}

export default CustomDatePicker