import Typography from "@mui/material/Typography";
import './columns.sass';

export const COLUMN_LANE_AWARD_COMPLIANCE = [
    {
        Header: 'lane',
        accessor: "lane",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    },
    {
        Header: 'service',
        accessor: "tariffServiceCode",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    },
    {
        Header: 'week',
        accessor: "week",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    },
    {
        Header: 'awarded',
        accessor: "awardedLoads",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    },
    {
        Header: 'tendered',
        accessor: "tenderedLoads",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    },
    {
        Header: 'tenders_accepted_with_contract_price',
        accessor: "tenderAcceptedInContract",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    },
    {
        Header: 'compliance',
        accessor: "compliancePercentage",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className={(props.row.values?.compliancePercentage >= props.row.original?.complianceTargetPercentage)
                ? "complianceGood"
                : "complianceBad"
            }>
                {props.value === '-' ? '-' : `${props.value}%`}
            </Typography>
        }
    },
    {
        Header: 'tenders_accepted_over_compliance',
        accessor: "tenderAcceptedOverCompliancePercentage",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="complianceGood">{props.value === '-' ? '-' : `${props.value}%`}</Typography>
        }
    },
    {
        Header: 'tenders_accepted_in_freight_auction',
        accessor: "tenderAcceptedInSpot",
        Cell: (props) => {
            return <Typography variant="h3" data-testid={props.value} className="laneTextCellStyle">{props.value}</Typography>
        }
    }
]