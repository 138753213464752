export const filterRequestPayloadOnTimePickup = {
    "searchType": "CARRIER_PORTAL_ARCHIVE_PERFORMANCE_LOAD_DETAILS", // send always
    "returnOnlyTmsPlannedShipments": true,      // send always
    "excludeEquipmentTypeList": ["PKG", "LTL"],   // send always
    "originRegion": "NA", // send always
    "shipmentNumList": [], // Load # search 
    "carrierCodeList": [], // Carrier ID
    "tariffServiceCodeList": [], // Service 
    "equipmentTypeList": [], // Equip Type 
    "trailerNumList": [], // Trailer Number,
    "pickupApptDateTimeOriginTZ": null,
    "pickupOnTime": [],
    "deliveryApptDateTimeDestTZ": null,
    "deliveryOnTime": [],
    "originLocationNameList": [], // Origin Location name
    "originCityList": [], // Origin city 
    "originStateList": [], // Origin State
    "originCountryList": [], // Origin Country
    "destLocationNameList": [], // Dest Location name
    "destCityList": [], // Dest city 
    "destStateList": [], // Dest State
    "destCountryList": [], // Dest Country
    "liveLoadIndList": ['Y'],  // Optional , filter has to be mutiple list "Yes" , "No" ;  "Yes" will corresponds to "Y" , "No" will corresponds to "N"
    "originCityStateList": [],
    "deliveryActualArrivalDatetimeFinalDestTZ": null,
    "cpShipmentTypeList": [], // orderType 
    "isPickedByPrimaryList": [], // null when nothing selected (awarded non awarded) 
    "orderBy": [
        {
            "key": "shipmentPickupApptDatetime",
            "ordering": "ASC"
        },
        {
            "key": "shipmentNum",
            "ordering": "ASC"
        }
    ] // send always in archive api 
} 

export const optionalFilterPayloadOTP = {
    "shipmentNumList": [], // Load # search 
    "carrierCodeList": [], // Carrier ID
    "equipmentTypeList": [], // Equip Type 
    "trailerNumList": [], // Trailer Number,
    "pickupApptDateTimeOriginTZ": null,
    "pickupOnTime": [],
    "deliveryApptDateTimeDestTZ": null,
    "deliveryOnTime": [],
    "originLocationNameList": [], // Origin Location name
    "originCityList": [], // Origin city 
    "originStateList": [], // Origin State
    "originCountryList": [], // Origin Country
    "destLocationNameList": [], // Dest Location name
    "destCityList": [], // Dest city 
    "destStateList": [], // Dest State
    "destCountryList": [], // Dest Country
    "liveLoadIndList": ['Y'],  // Optional , filter has to be mutiple list "Yes" , "No" ;  "Yes" will corresponds to "Y" , "No" will corresponds to "N"
    "cpShipmentTypeList": [], // orderType 
} 

export const defaultFilterPayloadOTP = {
    "searchType": "CARRIER_PORTAL_ARCHIVE_PERFORMANCE_LOAD_DETAILS", // send always
    "returnOnlyTmsPlannedShipments": true,      // send always
    "excludeEquipmentTypeList": ["PKG", "LTL"],   // send always
    "originRegion": "NA", // send always
    "orderBy": [
        {
            "key": "shipmentPickupApptDatetime",
            "ordering": "ASC"
        },
        {
            "key": "shipmentNum",
            "ordering": "ASC"
        }
    ] // send always in archive api 
}