import { createSlice } from "@reduxjs/toolkit";

const tableDataSlice = createSlice({
  name: "tableData",
  initialState: {
    tableData: {
      onPage: 0,
      pageSize: 0,
      shipments: [],
      totalPages: 0,
      totalRecords: 0
    },
    isLoading: false,
    errorMessage: undefined
  },
  reducers: {
    getTableData: (state) => {
      state.isLoading = true;
      state.tableData = {
        ...state.tableData,
        shipments: [],
      }
    },
    setTableDataSuccess: (state, action) => {
      state.isLoading = false;
      state.tableData = action.payload;
    },
    setTableDataFailure: (state, action) => {
      state.isLoading = false;
      state.tableData = {
        onPage: 0,
        pageSize: 0,
        shipments: [],
        totalPages: 0,
        totalRecords: 0
      };
      state.errorMessage = action.payload
    }
  }
});

export const { getTableData, setTableDataSuccess, setTableDataFailure } = tableDataSlice.actions;

export default tableDataSlice.reducer;
