import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from "react-router-dom";
import { useDeepCompareEffect } from 'react-use';
import qs from "query-string";
import Grid from '@mui/material/Grid';
import DataTable from '../Table';
import { useDispatch, useSelector } from 'react-redux';
import { 
    pageSize,
    generateGlobalFilters,
    optionalFiltersByTable, 
    generateTableFilters,
    defaultHeaderFiltersByTable,
    globalFiltersRequired,
    isSubset
 } from '../../helpers';
import TrackNTraceHeader from '../TrackNTraceHeader';
import DetailsPageHeader from './DetailsPageHeader';
import { COLUMNS_ON_TIME } from '../../constData/columnOnTime';
import { getTableData } from '../../redux/reducers/tableDataSlice';
import { filtersOnTime as defaultStoreFilters } from "../../constData/filtersOnTime";
import { optionalFilterPayloadOTD } from '../../constData/filterRequestPayloadOnTimeDelivery';
import './page.sass';


const OnTimeDelivery = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tableNameDelivery = 'onTimeDelivery';
    const { carrierid } = useParams();

    const { tableData: tableDataDelivery, isLoading } = useSelector(state => state?.loadDetails);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchDeliveryParams, setSearchDeliveryParams] = useState(() => Object.fromEntries([...searchParams]));

    const [shipmentsDelivery, setDeliveryShipments] = useState([]);
    const [loadingDelivery, setDeliveryLoading] = useState(false);
    const [totalPagesDelivery, setDeliveryTotalPages] = useState(0);
    const [totalRecordsDelivery, setDeliveryTotalRecords] = useState(0);
    const [onPageDelivery, setDeliveryOnPage] = useState(1);
    const [reqPayloadDelivery, setReqPayload] = useState({});

    useDeepCompareEffect(() => {
        let filterValues = {};
        const params = Object.fromEntries([...searchParams]);
        Object.keys(optionalFilterPayloadOTD).map(d => {
            if (optionalFilterPayloadOTD[d]) {
                filterValues[d] = JSON.stringify(optionalFilterPayloadOTD[d]);
            }
        })
        setSearchParams(qs.stringify({
            ...filterValues,
            ...params,
            carrierCodeList: JSON.stringify([carrierid])
        }));
        setSearchDeliveryParams({
            ...filterValues,
            ...params,
            carrierCodeList: JSON.stringify([carrierid])
        });
        setDeliveryOnPage(1);
    }, [searchParams]);

    useEffect(() => {
        const controller = new AbortController();
        const globalFiltersDelivery = generateGlobalFilters(globalFiltersRequired, searchDeliveryParams);
        const { tableFilters: tableFiltersDelivery, definedValues } = generateTableFilters(optionalFiltersByTable[tableNameDelivery], searchDeliveryParams, defaultStoreFilters);
        const commonPayloadDelivery = defaultHeaderFiltersByTable[tableNameDelivery];

        const reqBody = {
            ...globalFiltersDelivery,
            ...commonPayloadDelivery,
            ...tableFiltersDelivery,
            carrierCodeList: [carrierid],
        };
        if (isSubset(Object.keys(searchDeliveryParams), Object.keys(definedValues))) {
            setReqPayload(reqBody);
            dispatch(getTableData({
                reqBody,
                pageSize: pageSize,
                pageNum: onPageDelivery,
                tableName: tableNameDelivery,
                controller
            }));
        }
        return () => {
            controller.abort();
        };
    }, [searchDeliveryParams, onPageDelivery, carrierid]);

    useEffect(() => {
        setDeliveryShipments(tableDataDelivery.shipments);
        setDeliveryLoading(isLoading);
        setDeliveryTotalPages(tableDataDelivery.totalPages);
        setDeliveryTotalRecords(tableDataDelivery.totalRecords);
        // setDeliveryOnPage(tableDataDelivery.onPage)
    }, [tableDataDelivery]);

    return (
        <Grid container className="pageContainer">
            <DetailsPageHeader
                buttonTitle={t("on_time_delivery")}
                pageName={tableNameDelivery}
                enableLanes={true}
            />
            <Grid item xs={12} className='actionSection'>
                <TrackNTraceHeader 
                    refreshBtn={false} 
                    tab={'performance'} 
                    tableName={tableNameDelivery} 
                    reqPayload={reqPayloadDelivery}
                />
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    tableName={tableNameDelivery}
                    rowData={shipmentsDelivery}
                    columnOrder={COLUMNS_ON_TIME}
                    loading={loadingDelivery}
                    size={pageSize}
                    totalPages={totalPagesDelivery}
                    totalRecords={totalRecordsDelivery}
                    onPage={onPageDelivery}
                    setOnPage={setDeliveryOnPage}
                />
            </Grid>
        </Grid>
    )
}

export default OnTimeDelivery;