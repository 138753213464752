/**
 * URL mapping file
 */
const URLS = {
    /** Track & Trace  */ 
    FETCH_TABLE_DATA: "/shipments/search",
    FETCH_TMS_REASON_META: "/shipment/tms-reason-metadata",
    SAVE_STOPS_DATA: "/shipment/stops",
    RETRIGGER_EVENT: "/shipments/retrigger",
    FETCH_COMMENTS: "/users/comment/search",
    ADD_COMMENT: "/users/comment",

    /** Carrier Performance  */ 
    FETCH_LOCATION_METADATA: "/archive/shipments/origin-locations",
    FETCH_CARRIER_SERVICE_METADATA: "/archive/shipments/carriers",
    // FETCH_SUMMARY: "archive/shipments/summary",
    /** On Time */
    ON_TIME_PERFORMANCE: "archive/shipments/summary/ontime-performance",
    FETCH_TABLE_DATA_ON_TIME_PERFORMANCE: "/archive/shipments/search",
    /** First Tender Compliance */
    FIRST_TENDER: "archive/shipments/summary/first-tender-compliance",   
    FETCH_TABLE_DATA_FIRST_TENDER: "/archive/shipments/tender-response/search",
    /** Lane Award Compliance */
    LANE_AWARD_COMPLIANCE: "/archive/summary/lane-award-compliance",
    FETCH_TABLE_DATA_LANE_AWARD: "/archive/lane-award-compliance/search", 
    /** Carrier Event Response */  
    CARRIER_EVENT_RESPONSE: "archive/shipments/summary/carrier-event-response-efficiency",
    /** FourKites Tracking Consistency */
    FOURKITES_TRACKING: "archive/shipments/summary/fourkites-tracking-consistency",
    /** Billing Accuracy */
    BILLING_ACCURACY: "archive/shipments/summary/billing-accuracy",
    /** Trailer Pool Visualization */
    TRAILER_POOL_COMPLIANCE: "/archive/summary/trailer-pool-compliance",
    FETCH_TABLE_DATA_TRAILER_POOL: "/archive/trailer-pool-compliance/search"
}

export default URLS;