import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import ProgressBarWrapperComponent from './ProgressBarWrapperComponent';
import PieChartWrapperComponent from './PieChartWrapperComponent';
import CustomCardHeader from './CustomCardHeader';
import CardNoteComponent from './CardNoteComponent';
import './Card.sass';

const OnTimeDeliveryCard = ({ carrierLoading }) => {

  const { t } = useTranslation();
  const [deliveryProgressBarCardData, setDeliveryProgressBarCardData] = useState({});

  const [loadingDelivery, setLoadingDelivery] = React.useState(() => carrierLoading);

  const pageName = "onTimeDelivery";
  const mockLoadingDelivery = [{}, {}, {}];
  const { summary: { onTimeDelivery: { data: { targetPercentage, summaryResponse }, isLoading } } } = useSelector(state => state.chartSummary);

  useEffect(() => {
    if (summaryResponse) {

      let total = summaryResponse.greenTotalCount + summaryResponse.redTotalCount
      let ontimePercentage = isNaN(parseFloat((summaryResponse.greenTotalCount / total) * 100).toFixed(1))
        ? 0
        : parseFloat((summaryResponse.greenTotalCount / total) * 100).toFixed(1);

      setDeliveryProgressBarCardData({
        title: `${total} ${t('delivered_stops')}`,
        subtitle1: `${ontimePercentage}% ${t('on_time')}`,
        subtitle2: `${targetPercentage}% ${t('target')}`,
        value1: summaryResponse.greenTotalCount,
        value2: summaryResponse.redTotalCount,
        value1Title: `${summaryResponse.greenTotalCount} ${t('on_time_delivered_stops')}`,
        value2Title: `${summaryResponse.redTotalCount} ${t('late_stops')}`,
        subtitleValue1: ontimePercentage,
        subtitleValue2: targetPercentage,
        color: "primary",
      });
    }
  }, [targetPercentage]);

  useEffect(() => {
    setLoadingDelivery(carrierLoading || isLoading);
  }, [carrierLoading, isLoading])

  return (
    <div className='card1'>
      <CustomCardHeader
        title={t('on_time_delivery')}
        pageName={pageName}
      />
      <ProgressBarWrapperComponent
        progressBarData={deliveryProgressBarCardData}
        card={true}
        gotoPath={pageName}
        loading={loadingDelivery}
      />
      <Divider />
      <div className='pieChartGroup'>
        {
          (loadingDelivery ? mockLoadingDelivery : summaryResponse?.chartData)?.map(item => {
            return <PieChartWrapperComponent
              loading={loadingDelivery}
              data={item.data || []}
              gotoPath={pageName}
              centerTitle={loadingDelivery ? t('loading') : t(item.centerTitle)}
              centerSubTitle={`${item.centerSubTitle} ${t('stops')}`}
              title={`${item.title}% ${t('on_time')}`}
            />
          })
        }
      </div>
      <CardNoteComponent note={t('kpi_note_delivery_date')} />
    </div>
  )
}

export default OnTimeDeliveryCard