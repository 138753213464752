import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    summary: {
        onTimePickup: {
            data: {},
            isLoading: false,
            errorMessage: undefined
        },
        onTimeDelivery: {
            data: {},
            isLoading: false,
            errorMessage: undefined
        },
        firstTenderCompliance: {
            data: {},
            isLoading: false,
            errorMessage: undefined
        },
        laneAwardCompliance : {
            data: {},
            isLoading: false,
            errorMessage: undefined
        },
        carrierEventResponse: {
            data: {},
            isLoading: false,
            errorMessage: undefined
        },
        fourKitesTracking: {
            data: {},
            isLoading: false,
            errorMessage: undefined
        },
        billingAccuracy: {
            data: {},
            isLoading: false,
            errorMessage: undefined
        },
        trailerPoolCompliance: {
            data: {},
            isLoading: false,
            errorMessage: undefined
        }
    },
}

const summarySlice = createSlice({
    name: "summary",
    initialState,
    reducers: {
        getSummary: (state, action) => {
            state.summary = {
                ...state.summary,
                [action.payload.type]: {
                    data: {},
                    isLoading: true,
                    errorMessage: undefined
                }
            }
        },
        setSummarySuccess: (state, action) => {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    [action.payload.type]: {
                        data: action.payload.data,
                        isLoading: false,
                        errorMessage: undefined
                    }
                }
            }
        },
        setSummaryfailure: (state, action) => {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    [action.payload.type]: {
                        data: {},
                        isLoading: false,
                        errorMessage: action.payload?.errorMessage
                    }
                }
            }
        },
        resetSummary: () => {
            return initialState            
        }
    }
});

export const {
    getSummary,
    setSummarySuccess,
    setSummaryfailure,
    resetSummary
} = summarySlice.actions;

export default summarySlice.reducer;
