import { createSlice } from "@reduxjs/toolkit";

const retriggerEventSlice = createSlice({
  name: "retriggerEvent",
  initialState: {
    retriggered: null,
    retriggerLoading: false,
    errMsg: undefined
  },
  reducers: {
    retrigger: (state) => {
      state.retriggerLoading = true;
      state.errMsg = null;
      state.retriggered = null;
    },
    retriggerSuccess: (state, action) => {
      state.retriggerLoading = false;
      state.retriggered = action.payload;
    },
    retriggerFailure: (state, action) => {
      state.retriggerLoading = false;
      state.errMsg = action.payload
    },
    resetRetrigger: (state) => {
      state.retriggerLoading = false;
      state.errMsg = null;
      state.retriggered = null
    }
  }
});

export const { retrigger, retriggerSuccess, retriggerFailure, resetRetrigger } = retriggerEventSlice.actions;

export default retriggerEventSlice.reducer;
