import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Popper from '@mui/material/Popper'
import IconButton from '@mui/material/Button'
import Box from "@mui/material/Box"
import ClickAwayListener from '@mui/material/ClickAwayListener'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import clockIcon from "../assets/clockIcon.svg"
import clockIconDark from "../assets/clockIconDark.svg"
import ApptHistory from './ApptHistory'
import CustomTextEdit from "../components/common/CustomTextEdit"
import './StopEvents.sass'

const StopEvent = ({ event, index, edit, id, setUpdated, eventdata, setEventData, shouldEdit, userGroup, errors, setErrors }) => {

    const { t } = useTranslation();

    const [openArrive, setOpenArrive] = useState(false);
    const [openDepart, setOpenDepart] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();
    // const [errors, setErrors] = useState({});

    const isEditable = React.useMemo(() => {

        return (key) => {
            const { allAccess, allowEdit, fields } = shouldEdit || {};
            const { isArrivalEventLocked, isDepartureEventLocked } = event;
            if (!edit) return false;
            if (allAccess) return true;

            const arriveKeys = [
                "apptDateTime",
                "apptReasonCode",
                "actualArrivalTimestampCarrier",
                "actualArrivalReasonCodeCarrier"
            ];

            const departKeys = [
                "actualDepartureTimestampCarrier",
                "actualDepartureReasonCodeCarrier"
            ];

            if (!allAccess && allowEdit && fields.length > 0) {
                let updatedKeys = [...fields];
                if (isArrivalEventLocked) {
                    updatedKeys = updatedKeys.filter(d => !arriveKeys.includes(d))
                }
                if (isDepartureEventLocked) {
                    updatedKeys = updatedKeys.filter(d => !departKeys.includes(d))
                }
                return updatedKeys.includes(key);
            }
            if (!allAccess && allowEdit && (isArrivalEventLocked || isDepartureEventLocked)) {
                return true;
            }
            return false
        }
    }, [edit])

    const updateEvent = (update) => {
        setEventData({
            ...eventdata,
            [event.stopNum]: {
                ...(eventdata[event.stopNum]),
                ...update
            }
        });
        setUpdated({
            ...eventdata,
            [event.stopNum]: {
                ...(eventdata[event.stopNum]),
                ...update
            }
        });
    }

    const handleClick = (newPlacement, stopType) => (event) => {
        setAnchorEl(event.currentTarget);
        if (stopType === 'arrive') setOpenArrive(previous => !previous);
        if (stopType === 'depart') setOpenDepart(previous => !previous)
        setPlacement(newPlacement);
    }

    return (
        <>
            <div key={index} className='event' data-testid="stop-event">
                <div className={event.isArrivalEventLocked ? 'stepComplete' : event.carrierUpdateLifecycleHealthArrival === 'RED' ? 'stepHighAlert' : event.carrierUpdateLifecycleHealthArrival === 'YELLOW' ? 'stepCritical' : 'notThereYet'} >
                    <Box className='arriveBox'>
                        <Typography variant='h4' pb={2} pr={2} className="stopTitle">
                            {event.stopType === 'pickup' ? `${t("arrive_at_pick_stop")} ${event.stopNum}` : `${t("arrive_at_drop_stop")} ${event.stopNum - 1}`}
                            {
                                (event.isArrivalEventLocked) && <Box className='lockBox'>
                                    <LockOutlinedIcon fontSize="15px" />
                                </Box>
                            }
                        </Typography>
                        <div className='dataBox'>
                            <Typography variant='body2' pr={event.stopType === 'pickup' ? 21.2 : 21.7} className='textColor'>{t("location")}</Typography>
                            <Typography variant='subtitle1' className='textColor'>{event.location.id}</Typography>
                        </div>
                        <div className='dataBox'>
                            <Typography variant='body2' className='textColor' pr={event.stopType === 'pickup' ? 16.2 : 13.7}>{event.stopType === 'pickup' ? t("pickup_location") : t("drop_location")}</Typography>
                            <Typography variant='subtitle1' className='textColor'>{`${event.location.city}, ${event.location.state}`}</Typography>
                        </div>
                        <div className='dataBox'>
                            <Typography variant='body2' className='textColor' pr={5}>{event.stopType === 'pickup' ? t("planned_pickup_appt_date_time") : t("planned_delivery_appt_date_time")}</Typography>
                            <div className='pickUpDateTime'>
                                {
                                    !isEditable("apptDateTime") ?
                                        <>
                                            <Typography variant='subtitle1' className={event.apptDateTimeFormatted !== '-'
                                                ? 'textColor'
                                                : event.carrierUpdateLifecycleHealthArrival === 'RED'
                                                    ? 'textColorRed'
                                                    : event.carrierUpdateLifecycleHealthArrival === 'YELLOW'
                                                        ? 'textColorYellow'
                                                        : 'textColor'
                                            }
                                            >
                                                {
                                                    event.apptDateTimeFormatted !== '-' || (!event.isArrivalEventLocked && event.carrierUpdateLifecycleHealthArrival === 'GREEN')
                                                        ? event.apptDateTimeFormatted
                                                        : event.carrierUpdateLifecycleHealthArrival === '-'
                                                            ? '-'
                                                            : `${t('data_missing')}`
                                                }
                                            </Typography>
                                            {
                                                event?.apptHistory?.length > 0 && <>
                                                    <IconButton
                                                        // aria-describedby={id}
                                                        onClick={(e) => handleClick('right-start', 'arrive')(e)}
                                                        data-testid='apptHistory-arrive'
                                                        className='historyButton'
                                                    >
                                                        <img src={openArrive ? clockIconDark : clockIcon} alt="historyOpen" />
                                                    </IconButton>
                                                    {openArrive && <ClickAwayListener onClickAway={() => setOpenArrive(false)}>
                                                        <Popper
                                                            // id={id}
                                                            open={openArrive}
                                                            anchorEl={anchorEl}
                                                            placement={placement}
                                                            className="appt-popper"
                                                        >
                                                            <Box className='boxStyle'>
                                                                <ApptHistory latestApptHistory={event?.latestApptHistory} oldApptHistory={event?.oldApptHistory} />
                                                                <Typography variant='body2' className='noteApptHistory'>{event.stopType === 'pickup'? t('note_appt_history_pickup') : t('note_appt_history_delivery')}</Typography>
                                                            </Box>
                                                        </Popper>
                                                    </ClickAwayListener>}

                                                </>
                                            }
                                        </> :
                                        <CustomTextEdit
                                            value={event.apptDateTime}
                                            eventdata={eventdata}
                                            elementKey={"apptDateTime"}
                                            type="date"
                                            changeEvent={updateEvent}
                                            event={event}
                                            edit={edit}
                                            userGroup={userGroup}
                                            errors={errors}
                                            setErrors={setErrors}
                                            errorKey={event.stopType === 'pickup' ? "pickup_appt_reason_code" : "planned_appt_reason_code"}
                                        />
                                }
                            </div>
                        </div>
                        <div className='dataBox'>
                            <Typography variant='body2' className='textColor' pr={3.5}>{event.stopType === 'pickup' ? t("pickup_appt_reason_code") : t("planned_appt_reason_code")}</Typography>
                            {
                                !isEditable("apptReasonCode") ?
                                    <Typography variant='subtitle1' className={event.apptReasonCodeDesc !== '-'
                                        ? 'textColor'
                                        : event.carrierUpdateLifecycleHealthArrival === 'RED' || event.isArrivalEventLocked
                                            ? 'textColorRed'
                                            : event.carrierUpdateLifecycleHealthArrival === 'YELLOW'
                                                ? 'textColorYellow'
                                                : 'textColor'
                                    }>
                                        {
                                            event.apptReasonCodeDesc !== '-' || (!event.isArrivalEventLocked && event.carrierUpdateLifecycleHealthArrival === 'GREEN')
                                                ? event.apptReasonCodeDesc
                                                : event.carrierUpdateLifecycleHealthArrival === '-'
                                                    ? '-'
                                                    : `${t('data_missing')}`
                                        }
                                    </Typography> :
                                    <CustomTextEdit
                                        value={event.apptReasonCode}
                                        elementKey={"apptReasonCode"}
                                        eventdata={eventdata}
                                        type="select"
                                        changeEvent={updateEvent}
                                        event={event}
                                        edit={edit}
                                        userGroup={userGroup}
                                        eventName={event.stopType === 'pickup' ? "pickup_appt_reason_code" : "planned_appt_reason_code"}
                                        error={errors[event.stopType === 'pickup' ? "pickup_appt_reason_code" : "planned_appt_reason_code"]}
                                        errors={errors}
                                        setErrors={setErrors}
                                        errorKey={event.stopType === 'pickup' ? "pickup_appt_reason_code" : "planned_appt_reason_code"}
                                    />
                            }
                        </div>
                        <div className='dataBox'>
                            <Typography variant='body2' className='textColor' pr={event.stopType === 'pickup' ? 4.8 : 4.5}>{event.stopType === 'pickup' ? t("carrier_actual_pickup_data_time") : t("carrier_actual_delivery_data_time")}</Typography>
                            {
                                !isEditable("actualArrivalTimestampCarrier") ?
                                    <Typography variant='subtitle1' className={event.actualArrivalTimestampCarrierFormatted !== '-'
                                        ? 'textColor'
                                        : event.carrierUpdateLifecycleHealthArrival === 'RED' || event.isArrivalEventLocked
                                            ? 'textColorRed'
                                            : event.carrierUpdateLifecycleHealthArrival === 'YELLOW'
                                                ? 'textColorYellow'
                                                : 'textColor'
                                    }>
                                        {
                                            event.actualArrivalTimestampCarrierFormatted !== '-' || (!event.isArrivalEventLocked && event.carrierUpdateLifecycleHealthArrival === 'GREEN')
                                                ? event.actualArrivalTimestampCarrierFormatted
                                                : event.carrierUpdateLifecycleHealthArrival === '-'
                                                    ? '-'
                                                    : `${t('data_missing')}`
                                        }
                                    </Typography> :
                                    <CustomTextEdit
                                        value={event.actualArrivalTimestampCarrier}
                                        elementKey={"actualArrivalTimestampCarrier"}
                                        type="date"
                                        changeEvent={updateEvent}
                                        eventdata={eventdata}
                                        event={event}
                                        edit={edit}
                                        userGroup={userGroup}
                                        errors={errors}
                                        setErrors={setErrors}
                                        // errorKey={event.stopType === 'pickup' ? "pickup_appt_reason_code" : "planned_appt_reason_code"}
                                    />
                            }
                        </div>
                        <div className='dataBox'>
                            <Typography variant='body2' className='textColor' pr={event.stopType === 'pickup' ? 3.4 : 3}>{event.stopType === 'pickup' ? t("carrier_actual_pickup_reason_code") : t("carrier_actual_delivery_reason_code")}</Typography>
                            {
                                !isEditable("actualArrivalReasonCodeCarrier") ?
                                    <Typography variant='subtitle1' className={event.actualArrivalReasonCodeDescCarrier !== '-'
                                        ? 'textColor'
                                        : event.carrierUpdateLifecycleHealthArrival === 'RED' || event.isArrivalEventLocked
                                            ? 'textColorRed'
                                            : event.carrierUpdateLifecycleHealthArrival === 'YELLOW'
                                                ? 'textColorYellow'
                                                : 'textColor'
                                    }>
                                        {
                                            event.actualArrivalReasonCodeDescCarrier !== '-' || (!event.isArrivalEventLocked && event.carrierUpdateLifecycleHealthArrival === 'GREEN')
                                                ? event.actualArrivalReasonCodeDescCarrier
                                                : event.carrierUpdateLifecycleHealthArrival === '-'
                                                    ? '-'
                                                    : `${t('data_missing')}`
                                        }
                                    </Typography> :
                                    <CustomTextEdit
                                        value={event.actualArrivalReasonCodeCarrier}
                                        elementKey={"actualArrivalReasonCodeCarrier"}
                                        type="select"
                                        changeEvent={updateEvent}
                                        eventdata={eventdata}
                                        event={event}
                                        edit={edit}
                                        userGroup={userGroup}
                                        eventName={event.stopType === 'pickup' ? "carrier_actual_pickup_reason_code" : "carrier_actual_delivery_reason_code"}
                                        setErrors={setErrors}
                                        errors={errors}
                                    />
                            }
                        </div>
                        <div className='dataBox'>
                            <Typography variant='body2' className='textColor' pr={event.stopType === 'pickup' ? 3.4 : 3.2}>{event.stopType === 'pickup' ? t("kc_actual_date_time") : t("kc_delivery_actual_date_time")}</Typography>
                            {
                                !isEditable("actualArrivalTimestampKC") ?
                                    <Typography variant='subtitle1' className='textColor'>{event.actualArrivalTimestampKCFormatted}</Typography> :
                                    <CustomTextEdit
                                        value={event.actualArrivalTimestampKC}
                                        elementKey={"actualArrivalTimestampKC"}
                                        type="date"
                                        changeEvent={updateEvent}
                                        eventdata={eventdata}
                                        event={event}
                                        edit={edit}
                                        userGroup={userGroup}
                                        setErrors={setErrors}
                                        errors={errors}
                                        arrivalLocked={event.isArrivalEventLocked}
                                    />
                            }
                        </div>
                        <div className='dataBox'>
                            <Typography variant='body2' className='textColor' pr={event.stopType === 'pickup' ? 9.7 : 9.4}>{event.stopType === 'pickup' ? t("final_pickup_reason_code") : t("final_delivery_reason_code")}</Typography>
                            <Typography variant='subtitle1' className='textColor'>{event.actualArrivalReasonCodeDescFinal}</Typography>
                        </div>
                        <div className='dataBox'>
                            <Typography variant='body2' className='textColor' pr={event.stopType === 'pickup' ? 11.6 : 11.3}>{event.stopType === 'pickup' ? t("carrier_pickup_on_time") : t("carrier_delivery_on_time")}</Typography>
                            <Typography variant='subtitle1' className='textColor'>{event.arrivalOnTime}</Typography>
                        </div>
                    </Box>
                </div>
                <div className={event.isDepartureEventLocked ? 'stepComplete' : event.carrierUpdateLifecycleHealthDeparture === 'RED' ? 'stepHighAlert' : event.carrierUpdateLifecycleHealthDeparture === 'YELLOW' ? 'stepCritical' : 'notThereYet'}>
                    <Box className='departBox'>
                        <Typography variant='h4' pb={2} pr={2} className="stopTitle">
                            {event.stopType === 'pickup' ? `${t("depart_from_pick_stop")} ${event.stopNum}` : `${t("depart_from_drop_stop")} ${event.stopNum - 1}`}
                            {
                                (event.isDepartureEventLocked) && <Box className='lockBox'>
                                    <LockOutlinedIcon fontSize="15px" />
                                </Box>
                            }
                        </Typography>
                        <div className='dataBox'>
                            <Typography variant='body2' className='textColor' pr={event.stopType === 'pickup' ? 20.2 : 20.5}>{t("location")}</Typography>
                            <Typography variant='subtitle1' className='textColor'>{event.location.id}</Typography>
                        </div>
                        <div className='dataBox'>
                            <Typography variant='body2' className='textColor' pr={event.stopType === 'pickup' ? 15.2 : 12.2}>{event.stopType === 'pickup' ? t("pickup_location") : t("drop_location")}</Typography>
                            <Typography variant='subtitle1' className='textColor'>{`${event.location.city}, ${event.location.state}`}</Typography>
                        </div>
                        <div className='dataBox'>
                            <Typography variant='body2' className='textColor' pr={event.stopType === 'pickup' ? 9.9 : 3.5}>{event.stopType === 'pickup' ? t("pickup_appt_date_time") : t("planned_delivery_appt_date_time")}</Typography>
                            <Typography variant='subtitle1' className={event.apptDateTimeFormatted !== '-'
                                ? 'textColor'
                                : event.carrierUpdateLifecycleHealthArrival === 'RED'
                                    ? 'textColorRed'
                                    : event.carrierUpdateLifecycleHealthArrival === 'YELLOW'
                                        ? 'textColorYellow'
                                        : 'textColor'
                            }
                            >
                                {
                                    event.apptDateTimeFormatted !== '-' || (!event.isDepartureEventLocked && event.carrierUpdateLifecycleHealthDeparture === 'GREEN')
                                        ? event.apptDateTimeFormatted
                                        : event.carrierUpdateLifecycleHealthDeparture === '-'
                                            ? '-'
                                            : `${t('data_missing')}`
                                }
                            </Typography>
                            {event?.apptHistory?.length > 0 && <>
                                <IconButton
                                    // aria-describedby={id}
                                    onClick={(e) => handleClick('right-start', 'depart')(e)}
                                    data-testid='apptHistory-depart'
                                    className='historyButton'
                                >
                                    <img src={openDepart ? clockIconDark : clockIcon} alt="historyOpen" />
                                </IconButton>
                                {openDepart && <ClickAwayListener onClickAway={() => setOpenDepart(false)}>
                                    <Popper
                                        // id={id}
                                        open={openDepart}
                                        anchorEl={anchorEl}
                                        placement={placement}
                                        className="appt-popper"
                                    >
                                        <Box className='boxStyle'>
                                            <ApptHistory latestApptHistory={event.latestApptHistory} oldApptHistory={event.oldApptHistory} />
                                            <Typography variant='body2' className='noteApptHistory'>{event.stopType === 'pickup'? t('note_appt_history_pickup') : t('note_appt_history_delivery')}</Typography>
                                        </Box>
                                    </Popper>
                                </ClickAwayListener>}

                            </>
                            }
                        </div>
                        <div className='dataBox'>
                            <Typography variant='body2' className='textColor' pr={event.stopType === 'pickup' ? 3.2 : 3.9}>{t("carrier_actual_depart_data_time")}</Typography>
                            {
                                !isEditable("actualDepartureTimestampCarrier") ?
                                    <Typography variant='subtitle1' className={event.actualDepartureTimestampCarrierFormatted !== '-'
                                        ? 'textColor'
                                        : event.carrierUpdateLifecycleHealthDeparture === 'RED' || event.isDepartureEventLocked
                                            ? 'textColorRed'
                                            : event.carrierUpdateLifecycleHealthDeparture === 'YELLOW'
                                                ? 'textColorYellow'
                                                : 'textColor'
                                    }>
                                        {
                                            event.actualDepartureTimestampCarrierFormatted !== '-' || (!event.isDepartureEventLocked && event.carrierUpdateLifecycleHealthDeparture === 'GREEN')
                                                ? event.actualDepartureTimestampCarrierFormatted
                                                : event.carrierUpdateLifecycleHealthDeparture === '-'
                                                    ? '-'
                                                    : `${t('data_missing')}`
                                        }
                                    </Typography> :
                                    <CustomTextEdit
                                        value={event.actualDepartureTimestampCarrier}
                                        elementKey={"actualDepartureTimestampCarrier"}
                                        type="date"
                                        changeEvent={updateEvent}
                                        eventdata={eventdata}
                                        event={event}
                                        edit={edit}
                                        userGroup={userGroup}
                                        setErrors={setErrors}
                                        errors={errors}
                                    />
                            }
                        </div>
                        <div className='dataBox'>
                            <Typography variant='body2' className='textColor' pr={event.stopType === 'pickup' ? 1.8 : 2.4}>{event.stopType === 'pickup' ? t("carrier_actual_pickup_depart_reason_code") : t("carrier_actual_delivery_depart_reason_code")}</Typography>
                            {
                                !isEditable("actualDepartureReasonCodeCarrier") ?
                                    <Typography variant='subtitle1' className={event.actualDepartureReasonCodeDescCarrier !== '-'
                                        ? 'textColor'
                                        : event.carrierUpdateLifecycleHealthDeparture === 'RED' || event.isDepartureEventLocked
                                            ? 'textColorRed'
                                            : event.carrierUpdateLifecycleHealthDeparture === 'YELLOW'
                                                ? 'textColorYellow'
                                                : 'textColor'
                                    }>
                                        {
                                            event.actualDepartureReasonCodeDescCarrier !== '-' || (!event.isDepartureEventLocked && event.carrierUpdateLifecycleHealthDeparture === 'GREEN')
                                                ? event.actualDepartureReasonCodeDescCarrier
                                                : event.carrierUpdateLifecycleHealthDeparture === '-'
                                                    ? '-'
                                                    : `${t('data_missing')}`
                                        }
                                    </Typography> :
                                    <CustomTextEdit
                                        value={event.actualDepartureReasonCodeCarrier}
                                        elementKey={"actualDepartureReasonCodeCarrier"}
                                        type="select"
                                        changeEvent={updateEvent}
                                        eventdata={eventdata}
                                        event={event}
                                        edit={edit}
                                        userGroup={userGroup}
                                        eventName={event.stopType === 'pickup' ? "carrier_actual_pickup_depart_reason_code" : "carrier_actual_delivery_depart_reason_code"}
                                        setErrors={setErrors}
                                        errors={errors}
                                    />
                            }
                        </div>
                        <div className='dataBox'>
                            <Typography variant='body2' className='textColor' pr={event.stopType === 'pickup' ? 1.8 : 2.6}>{t("kc_actual_depart_date_time")}</Typography>
                            {
                                !isEditable("actualDepartureTimestampKC") ?
                                    <Typography variant='subtitle1' className='textColor'>{event.actualDepartureTimestampKCFormatted}</Typography> :
                                    <CustomTextEdit
                                        value={event.actualDepartureTimestampKC}
                                        elementKey={"actualDepartureTimestampKC"}
                                        type="date"
                                        changeEvent={updateEvent}
                                        event={event}
                                        edit={edit}
                                        userGroup={userGroup}
                                        eventdata={eventdata}
                                        setErrors={setErrors}
                                        errors={errors}
                                        departureLocked={event.isDepartureEventLocked}
                                    />
                            }
                        </div>
                    </Box>
                </div>
            </div>
        </>
    )
}

export default StopEvent;