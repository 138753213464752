import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import ProgressChart from '../../charts/ProgressChart';
import CardNoteComponent from './CardNoteComponent';
import './Card.sass';

const FourKitesTrackingConsistencyCard = ({ carrierLoading }) => {

    const { t } = useTranslation();
    const pageName = 'fourKitesTracking';
    const [loading, setLoading] = useState(() => carrierLoading);
    const { summary: { fourKitesTracking: { data: { summaryResponse, total }, isLoading } } } = useSelector(state => state.chartSummary);

    useEffect(() => {
        setLoading(carrierLoading || isLoading);
    }, [carrierLoading, isLoading]);

    return (
        <div className='card3' data-testid='fourKitesTrackingConsistencyCard'>
            <div className='titleBox'>
                <Typography variant='h2' className='titleStyle'>{t('fourKites_tracking_consistency')}</Typography>
            </div>
            <div className='subTitle'>
                <Typography variant='h4'>{loading ? "-": `${total} ${t('total_loads')}`}</Typography>
            </div>
            <div className='styleFKYCBar'>
                <ProgressChart
                    loading={loading}
                    value1={loading ? '-' : summaryResponse?.tracked?.greenCount}
                    value2={loading ? '-' : summaryResponse?.tracked?.redCount}
                    value1Title={<Typography variant='h3' className='subTitleStyle'>{`${t('tracked')} %`}</Typography>}
                    value2Title={<>
                        <div className='ratio'>
                            <Typography variant='h1' className={summaryResponse?.tracked?.trackedPercentage > summaryResponse?.tracked?.targetPercentage ? 'good' : 'bad'}>{!summaryResponse?.tracked?.trackedPercentage ? '-' : `${summaryResponse?.tracked?.trackedPercentage}%`}</Typography>
                            &nbsp; | &nbsp;
                            <Typography variant='h1' className='target'>{!summaryResponse?.tracked?.targetPercentage ? '-' : `${summaryResponse?.tracked?.targetPercentage}% ${t('target')}`}</Typography>
                        </div>
                    </>}
                    color={summaryResponse?.tracked?.trackedPercentage > summaryResponse?.tracked?.targetPercentage ? "primary" : "secondary"}
                    noRail={true}
                    gotoPath={pageName}
                />
            </div>
            <div className='styleFKYCBar'>
                <ProgressChart
                    loading={loading}
                    value1={loading ? '-' : summaryResponse?.superTracked?.greenCount}
                    value2={loading ? '-' : summaryResponse?.superTracked?.redCount}
                    value1Title={<Typography variant='h3' className='subTitleStyle'>{`${t('tracking_quality')} %`}</Typography>}
                    value2Title={<>
                        <div className='ratio'>
                            <Typography variant='h1' className={summaryResponse?.superTracked?.superTrackedPercentage > summaryResponse?.superTracked?.targetPercentage ? 'good' : 'bad'}>{!summaryResponse?.superTracked?.superTrackedPercentage ? '-' : `${summaryResponse?.superTracked?.superTrackedPercentage}%`}</Typography>
                            &nbsp; | &nbsp;
                            <Typography variant='h1' className='target'>{!summaryResponse?.superTracked?.targetPercentage ? '-' : `${summaryResponse?.superTracked?.targetPercentage}% ${t('target')}`}</Typography>
                        </div>
                    </>}
                    color={summaryResponse?.superTracked?.superTrackedPercentage > summaryResponse?.superTracked?.targetPercentage ? "primary" : "secondary"}
                    noRail={true}
                    gotoPath={pageName}
                />
            </div>
            <CardNoteComponent note={t('kpi_note_fourkites_completion_date')} />
        </div>
    )
}

export default FourKitesTrackingConsistencyCard;