import { createSlice } from "@reduxjs/toolkit";

const addCommentSlice = createSlice({
    name: "addNewComment",
    initialState: {
        newComment: {},
        addCommentLoading: false,
        addCommentErrMsg: undefined
    },
    reducers: {
        addComment: (state) => {
            state.addCommentLoading = true;
        },
        setAddCommentSuccess: (state, action) => {
            state.addCommentLoading = false;
            state.newComment = action.payload;
            state.addCommentErrMsg = 'success';
        },
        setAddCommentFailure: (state, action) => {
            state.addCommentLoading = false;
            state.newComment = {};
            state.addCommentErrMsg = action.payload
        },
        resetAddComment: (state) => {
            state.addCommentLoading = false;
            state.newComment = {};
            state.addCommentErrMsg = null
        }
    }
});

export const { addComment, setAddCommentSuccess, setAddCommentFailure, resetAddComment } = addCommentSlice.actions;

export default addCommentSlice.reducer;