import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from "@mui/material/Grid";
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/Button';
import filterIcon from "../assets/filterIcon.svg";
import excelIcon from "../assets/excelIcon.png";
import filterIconLight from "../assets/filterIconLight.svg";
import FiltersMenu from './FiltersMenu';
import TextFieldWrapperComponent from './FilterWrappers/TextFieldWrapperComponent';
import FilterChips from './FilterChips';
import { getCSVData, resetCSVData } from '../redux/reducers/csvDataSlice';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomSnackbar from './common/CustomSnackbar';
import { browserTimezone, csvColumnMapping } from '../helpers';
import './TrackNTraceHeader.sass';

const TrackNTraceHeader = ({ refresh, refreshBtn, tableName, reqPayload, filtersMenu=true }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const csvLinkRef = useRef();
    const csvLabels = csvColumnMapping[tableName];

    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [csvHeaderArray, setCSVHeaderArray] = useState([]);
    const [keys, setKeys] = useState([]);
    const [csvDataArray, setCSVDataArray] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
    const [openSnackbarNoRec, setOpenSnackbarNoRec] = useState(false);
    const [openSnackbarExportInProgress, setOpenSnackbarExportInProgress] = useState(false);

    const { tableData, isLoading } = useSelector(state => state?.loadDetails);
    // const { [tableName]: { requestPayload } } = useSelector(state => state?.filterSelected);
    const { csvData, isLoadingCSV } = useSelector(state => state?.csvDetails);

    const handleFiltersMenu = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(previous => !previous);
        setPlacement(newPlacement);
    }

    const csvDataFormatter = (data) => {
        let dataArr = [];
        let x = data?.shipments?.forEach(item => {
            let obj = {};
            keys.forEach(el => {
                let ptask = [];
                if (el === 'shipmentAttributesParsed' && item[el] !== '-') {
                    ptask = item[el]?.map(d => t(d));
                }
                obj = {
                    ...obj,
                    [el]: el === 'shipmentAttributesParsed' && item[el] !== '-' ? ptask : item[el]
                }
            })
            dataArr.push(obj);
        })
        setCSVDataArray(dataArr);
    }

    const handleCSVDownload = () => {

        if (isLoadingCSV) {
            setOpenSnackbarExportInProgress(true);
        } else {
            if (tableData?.totalRecords) {
                setOpenSnackbar(true);
                dispatch(getCSVData({
                    reqBody: {
                        ...reqPayload,
                        commentsRequired: tableName === 'trackNtrace' ? true : false
                    },
                    totalPages: Math.ceil((tableData?.totalRecords || 0) / 1000),
                    tableName
                }));
            } else {
                setOpenSnackbarNoRec(true);
            }
        }
    }

    const handleRefresh = () => {
        refresh();
    }

    useEffect(() => {
        
        if (csvData.shipments.length) {
            csvDataFormatter(csvData);
            setTimeout(() => {
                csvLinkRef.current.link.click();
                setOpenSnackbarSuccess(true);
                dispatch(resetCSVData());
            })
        }

    }, [csvData.shipments.length])

    useEffect(() => {
        let columnArr = [];
        let keysArray = [];

        csvLabels.forEach(itm => {
            keysArray.push(itm.key);
        });
        csvLabels.forEach(item => {
            columnArr.push({
                label: t(item.label),
                key: item.key
            })
        });
        setKeys(keysArray);
        setCSVHeaderArray(columnArr);
    }, [localStorage.getItem('i18nextLng')]);

    return (
        <>
            <Grid
                className='containerBoxTNTHeader'
                data-testid="trackNTraceHeader"
            >
                <Grid item xs={10.5} className='filterChips'>
                    {filtersMenu && <FilterChips tableName={tableName}/>}
                </Grid>
                <Grid item xs={2.5} className='operationsSection'>
                    {filtersMenu && <div className='searchField'>
                        <TextFieldWrapperComponent
                            type="text"
                            title="load_id"
                            fullWidth={false}
                            filterKey="shipmentNumList"
                            multiSearch={true}
                            tableName={tableName}
                        />
                    </div>}
                    {filtersMenu && <IconButton
                        disableRipple
                        onClick={handleFiltersMenu('bottom-end')}
                        data-testid={'filter-button'}
                        className={open ? 'activeFilterButton' : 'filterButtonDefault'}
                    >
                        <img src={open ? filterIconLight : filterIcon} alt="filter" />
                    </IconButton>}
                    {open && <ClickAwayListener onClickAway={() => setOpen(false)}>
                        <Popper
                            open={open}
                            anchorEl={anchorEl}
                            placement={placement}
                            className='popperStyles'
                        >
                            <FiltersMenu tableName={tableName} />
                        </Popper>

                    </ClickAwayListener>}
                    {['laneAwardCompliance', 'trailerPoolCompliance'].includes(tableName) && <div className='exportText'>
                        <Typography variant='h5'>{t('export')}</Typography>
                    </div>}
                    <IconButton
                        disableRipple
                        className='csvButtonStyles'
                        onClick={() => handleCSVDownload()}
                        data-testid={'csv-button'}
                    >
                        <img src={excelIcon} alt="csv" />
                    </IconButton>
                    <CSVLink
                        data={csvDataArray}
                        headers={csvHeaderArray}
                        filename={`${t("load_details")}.csv`}
                        ref={csvLinkRef}
                    ></CSVLink>
                    {refreshBtn && <IconButton
                        disableRipple
                        className='refreshButtonStyles'
                        onClick={() => handleRefresh()}
                        data-testid={'refresh-button'}
                        disabled={isLoading}
                    >
                        <RefreshIcon fontSize='small' />
                    </IconButton>}
                </Grid>
            </Grid>
            {
                tableName === 'trackNtrace' && <Typography className='lastUpdate' variant='body1'>{`${t('last_updated_from_TMS')} ${tableData?.tmsAdfLastSuccessfulPull
                    ? moment(tableData.tmsAdfLastSuccessfulPull).tz(browserTimezone)?.format("MM/DD/YYYY")
                    : '-'} ${t('at')} ${tableData?.tmsAdfLastSuccessfulPull
                        ? moment(tableData.tmsAdfLastSuccessfulPull).tz(browserTimezone).format("h:mm a z")
                        : '-'}`}
                </Typography>
            }
            <CustomSnackbar
                open={openSnackbar}
                severity="info"
                autoHideDuration={6000}
                datatestId="download_started"
                message={t('download_started')}
                handleClose={() => setOpenSnackbar(false)}
            />
            <CustomSnackbar
                open={openSnackbarSuccess}
                severity="success"
                autoHideDuration={3000}
                datatestId="download_completed"
                message={t('download_completed')}
                handleClose={() => setOpenSnackbarSuccess(false)}
            />
            <CustomSnackbar
                open={openSnackbarNoRec}
                severity='warning'
                autoHideDuration={6000}
                datatestId={"no_rec_to_download"}
                message={t('no_rec_to_download')}
                handleClose={() => setOpenSnackbarNoRec(false)}
            />
            <CustomSnackbar
                open={openSnackbarExportInProgress}
                severity='warning'
                autoHideDuration={6000}
                datatestId={"export_in_progress"}
                message={t('export_in_progress')}
                handleClose={() => setOpenSnackbarExportInProgress(false)}
            />
        </>

    )
}

export default TrackNTraceHeader