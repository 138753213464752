import axios from 'axios';
import { version } from '../lib/version';

const baseUrl = process.env.REACT_APP_API_BASE_PATH
const axiosInstance = axios.create({
    baseURL: `${baseUrl}`,
    headers: {
        'Content-Type': 'application/json',
        'Accept-Language': ['en'].includes(localStorage.getItem('i18nextLng')) ? localStorage.getItem('i18nextLng') : 'en',
        'X-Source': "carrierPortal",
        'X-Tab-Id': sessionStorage.getItem("Tab-Id"),
        'X-Device-Type': sessionStorage.getItem("Device-Type"),
        'X-App-Version': `v${version}`,
        // request.headers['accessKey'] = 'abcd'        
    }
});

export default axiosInstance;