import { createSlice } from "@reduxjs/toolkit";

const commentsSlice = createSlice({
    name: "comments",
    initialState: {
        comments: {},
        commentsLoading: false,
        commentsErrMsg: undefined
    },
    reducers: {
        getComments: (state) => {
            state.commentsLoading = true;
        },
        setCommentsSuccess: (state, action) => {
            state.commentsLoading = false;
            state.comments = action.payload;
            state.commentsErrMsg = 'success';
        },
        setCommentsFailure: (state, action) => {
            state.commentsLoading = false;
            state.comments = {};
            state.commentsErrMsg = action.payload
        },
        resetComments: (state) => {
            state.commentsLoading = false;
            state.comments = {};
            state.commentsErrMsg = null
        }
    }
});

export const { getComments, setCommentsSuccess, setCommentsFailure, resetComments } = commentsSlice.actions;

export default commentsSlice.reducer;