export const optionalFilterPayloadTPC = {
    "trailerPoolCompliant": "N"
}

export const defaultFilterPayloadTPC = {
    "searchType": "CARRIER_PORTAL_ARCHIVE_PERFORMANCE_TRAILER_POOL_COMPLIANCE", // send always
    "originRegion": "NA", // send always
    // "orderBy": [
    //     {
    //     "key": "awardedAndContractAcceptedDiff",
    //     "ordering": "DESC"
    //     },

    //     {
    //     "key": "awardComplianceLane",
    //     "ordering": "ASC"
    //     },

    //     {
    //     "key": "awardComplianceStartDate",
    //     "ordering": "ASC"
    //     }
    // ] // send always in archive api 
}