import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import ProgressBarWrapperComponent from './ProgressBarWrapperComponent';
import PieChartWrapperComponent from './PieChartWrapperComponent';
import CustomCardHeader from './CustomCardHeader';
import CardNoteComponent from './CardNoteComponent';
import './Card.sass';

const OnTimePickupCard = ({ carrierLoading }) => {

    const { t } = useTranslation();
    const [pickupProgressBarCardData, setPickupProgressBarCardData] = useState({});
    const [loading, setLoading] = React.useState(() => carrierLoading);

    const pageName = "onTimePickup";
    const mockLoading = [{}, {}, {}];
    const { summary: { onTimePickup: { data: { targetPercentage, summaryResponse }, isLoading } } } = useSelector(state => state.chartSummary);

    useEffect(() => {
        if (summaryResponse) {

            let total = summaryResponse.greenTotalCount + summaryResponse.redTotalCount
            let ontimePercentage = isNaN(parseFloat((summaryResponse.greenTotalCount / total) * 100).toFixed(1))
                ? 0
                : parseFloat((summaryResponse.greenTotalCount / total) * 100).toFixed(1);

            setPickupProgressBarCardData({
                title: `${total} ${t('picked_stop')}`,
                subtitle1: `${ontimePercentage}% ${t('on_time')}`,
                subtitle2: `${targetPercentage}% ${t('target')}`,
                value1: summaryResponse.greenTotalCount,
                value2: summaryResponse.redTotalCount,
                value1Title: `${summaryResponse.greenTotalCount} ${t('on_time_picked_stops')}`,
                value2Title: `${summaryResponse.redTotalCount} ${t('late_picked_stops')}`,
                subtitleValue1: ontimePercentage,
                subtitleValue2: targetPercentage,
                color: "primary"
            });
        }
    }, [targetPercentage]);

    useEffect(() => {
        setLoading(carrierLoading || isLoading);
    }, [carrierLoading, isLoading]);

    return (
        <div className='card1'>
            <CustomCardHeader
                title={t('on_time_pickup')}
                pageName={pageName}
            />
            <ProgressBarWrapperComponent
                progressBarData={pickupProgressBarCardData}
                card={true}
                gotoPath={pageName}
                loading={loading}
            />
            <Divider />
            <div className='pieChartGroup'>
                {
                    (loading ? mockLoading : summaryResponse?.chartData)?.map(item => {
                        return <PieChartWrapperComponent
                            loading={loading}
                            data={item.data || []}
                            gotoPath={pageName}
                            centerTitle={loading ? t('loading') : t(item.centerTitle)}
                            centerSubTitle={`${item.centerSubTitle} ${t('stops')}`}
                            title={`${item.title}% ${t('on_time')}`}
                        />
                    })
                }
            </div>
            <CardNoteComponent note={t('kpi_note_delivery_date')} />
        </div>
    )
}

export default OnTimePickupCard