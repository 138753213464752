const palette = {
  primaryBase: '#FFFFFF',
  secondaryBase: '#EDF0F2',

  grey: '#F0F1F2',
  gray10: '#E0E3E5',
  gray60: '#5C6366',
  gray95: '#F2F2F2',
  darkGrey80: '#242E33',
  lightGrey: '#8A9499',
  spaceGrey: '#A1A4AC',
  slateGrey: '#030303',
  darkSlateGrey: '#646282',
  grey20: '#C8CACC',
  grey85: '#D9D9D9',
  darkBlue: '#1B2134',
  lightSpaceGey: '#F0F1F4',

  black: '#000000',
  ebony: '#2B2D33',
  lightSteelBlue: '#6f8fa5',
  indigo: '#050050',
  sapphire: '#1864AB',
  red: '#D93232',
  tomato: '#DF1300',
  tangerine: '#FF8514',
  pinkishRed: '#E56363',
  linkBlue: '#2663FF',
  marigold: '#F1C40F',
  mediumDarkcyanBlue: '#194575',
  skyBlue: '#0091EA',
  peachPink: '#E47676',
  lightgreen: '#55DC8B',
  greyishWhite: '#F4F5F7',
  sunshineYellow: '#FFBB28',
  redOrange: '#FF8042',
  moodyBlue: '#8884d8',
  springGreen: "#00FFA3",
  purple: '#574485',
  faintGrey: '#EDEEF0',
  blue: '#b7e2fa',
  calBlue: "#3652FF",
  white: '#fefefe',
  whiteGrey:'#F3F3F3'
};

// const ColorPalette = {
//   background: {
//     main: palette.primaryBase,
//     secondary: palette.secondaryBase,
//     primaryFocus: palette.grey
//   },
//   header: {
//     title: palette.indigo,
//     primaryText: palette.black,
//     secondaryText: palette.darkGrey60
//   },
//   tab: {
//     primaryText: palette.darkGrey80,
//     active: palette.purple
//   }
// };

export { palette };